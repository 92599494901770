import React from 'react'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import Dashboard from '../views/Dashboard/container'
import Login from '../views/Login/container'
import { isEmpty } from 'lodash'
import Requests from '../views/Requests/container'
import Users from '../views/Users/container'
import RequestsDetails from '../views/RequestDetails/container'
import TrackingDetails from '../views/TrackingDetails/container'
import Services from '../views/Services/container'
import modifyServices from '../views/modifyServices/container'
import updateprofile from '../views/UpdateProfile/container'
import Affiliate from '../views/Affiliate/container'
import addAffiliate from '../views/AddAffiliate/container'
import editAffiliate from '../views/EditAffiliate/container'
import Centers from '../views/Centers/container'
import AddPromocode from '../views/AddPromocode/container'
import promocode from '../views/promocode/container'

import EditContentPages from '../views/EditContentPages/container'
import AddContentPages from '../views/AddContentPages/container'

import editPromocode from '../views/EditPromocode/container'
import calendar from '../views/Calendar/container'
import Analysis from '../views/Analysis/container'
import Setting from '../views/Setting/container'
import 'antd/dist/antd.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.scss'
import io from 'socket.io-client';
const ENDPOINT = "https://dbcapi.webvilleedemo.xyz";

const PrivateRoute = ({ component, ...rest }) => {
  const user = window.localStorage.getItem('Authorization');
  // console.log(user, 'jklklj')
  const isAuthed = user ? true : false
  return (
    <Route {...rest} exact
      render = {props => (
        isAuthed ? (<div>
          {
            React.createElement(component, props)
          }
        </div>)
        :
        (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      )}
    />
  )
}


const PublicRoute = ({ component, ...rest }) => {
  const user = window.localStorage.getItem('Authorization')
  const isAuthed = user ? true : false
  // loggedIn = isAuthed
  return (
    <Route {...rest} exact
      render={props => (
        isAuthed ? (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: props.location }
            }}
          />
        )
          : (<div>
            {
              React.createElement(component, props)
            }
          </div>)
      )}
    />
  )
}

class  AppComponent extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      user: {},
      socket: null
    }
  }

  componentDidMount() {
    const admin_id = window.localStorage.getItem('user_id')
    const socket = io.connect(ENDPOINT);
    if(admin_id){
      this.setState({ socket })
      socket.emit('join_chat', admin_id);
      socket.on("join_chat_ack",function(data){
      })
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    if(isEmpty(props.user) !== isEmpty(state.user)){
      return { isLoading: false, user: props.user }
    }

    // Return null to indicate no change to state.
    return null;
  }

  render() {

    return (
      <>
        {/* <Loader isLoading={isLoading} /> */}
        <div className="application-container">
          <Switch>
            <PrivateRoute exact path="/" component={Dashboard} {...this.props} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} {...this.props} />
            <PrivateRoute exact path="/analysis" component={Analysis} {...this.props} />
            <PrivateRoute exact path="/users" component={Users} {...this.props} />
            <PrivateRoute exact path="/requests" component={Requests} {...this.props} />
            {/* <PrivateRoute exact path="/request-details/:id" component={RequestsDetails} {...this.props} /> */}
            {this.state.socket && <PrivateRoute exact path="/request-details/:id" component={(props) => <RequestsDetails socket={this.state.socket} {...props} />} />}
            <PrivateRoute exact path="/tracking-details/:id" component={TrackingDetails} {...this.props} />
            <PrivateRoute exact path="/services" component={Services} {...this.props} />
            <PrivateRoute exact path="/modifyServices" component={modifyServices} {...this.props} />
            <PrivateRoute exact path="/updateprofile" component={updateprofile} {...this.props} />
            <PrivateRoute exact path="/affiliatemanagment" component={Affiliate} {...this.props} />
            <PrivateRoute exact path="/add-affiliate" component={addAffiliate} {...this.props} />
            <PrivateRoute exact path="/edit-affiliate/:id" component={editAffiliate} {...this.props} />
            <PrivateRoute exact path="/all-centers" component={Centers} {...this.props} />
            <PrivateRoute exact path="/add-promocode" component={AddPromocode} {...this.props} />
            <PrivateRoute exact path="/promocodemanagment" component={promocode} {...this.props} />
            <PrivateRoute exact path="/edit-promocode/:id" component={editPromocode} {...this.props} />
            <PrivateRoute exact path="/calendar-pickups" component={calendar} {...this.props} />
            <PrivateRoute exact path="/settings" component={Setting} {...this.props} />
            <PrivateRoute exact path="/add-contentPages" component={AddContentPages} {...this.props} />
            <PrivateRoute exact path="/edit-contentPages/:id"  component={props => <EditContentPages {...props} />} />
            <PublicRoute exact path="/" component={Login} {...this.props} />
            <Route exact path="/login" component={Login} {...this.props} />
          </Switch>
        </div>
      </>
    )
  }
}

export default withRouter(withCookies(AppComponent))
