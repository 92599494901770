import React, { PureComponent } from 'react'
import TopNavigation from './../TopNavigation/component'
import SidebarNavigation from './../SidebarNavigation/component'
import { Form, DatePicker, message, Spin, Select } from 'antd';
import "./styles.scss";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
export default class UpdateProfileComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      user_name: '',
      password: '',
      user_contact: '',
      user_contact: '',
      err: {},
      message: '',
      disable: true,
      messageerr: '',
      promo_id: '',
      disabled : false,
      loading:false
    }
    this.handleChange = this.handleChange.bind(this)
    this.UpdateProfile = this.UpdateProfile.bind(this)

  }

  handleChange(e) {
    let disable = false
    const self = this
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (self.state.user_name === '' || self.state.user_name === undefined) {
        disable = true
      }
      if (self.state.password === '' || self.state.password === undefined) {
        disable = true
      }
      if (self.state.user_contact === '' || self.state.user_contact === undefined) {
        disable = true
      }
      self.setState({ disable })
    })
  }


  componentDidMount() {
    document.title = 'Update Profile | DBC'
    this.getUserAllDetails();
  }

  async getUserAllDetails() {
    this.setState({loading:true})
    const getData = await this.props.getUserDetails();
    if (getData.value.status === 200) {
      // console.log(getData.value.data, 'ppp');
      const data = getData.value.data
      this.setState({ user_name: data.user_name, user_contact: data.user_contact, loading:false })
    }else if (getData.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/login');
    }
  }



  UpdateProfile = async () => {


    const { user_name, password, user_contact } = this.state
    const err = {}
    /* eslint-disable */
    if (user_name === '' || user_name === null || user_name.trim() === '') {
      err.user_name = 'Name is required.'
    }


    // if (password === '' || password === null || password.trim() === '') {
    //   err.password = 'Password is required.'
    // }

    if (user_contact === '' || user_contact === null) {
      err.user_contact = 'Mobile number is required.'
    }


    this.setState({ err })
    if (!Object.keys(err).length) {
      if (user_name && user_contact) {
        this.setState({disabled: true});
        const UpdateProfileData = {
          user_name: this.state.user_name,
          password: this.state.password,
          user_contact: this.state.user_contact,
        }


        const resData = await this.props.updateUserDetails(UpdateProfileData);

        if (resData.value.status === 200) {
          //updateed successfully
          toast.success("Updated successfully", {
            position: toast.POSITION.TOP_CENTER
          });

          setTimeout(() => {
            // console.log('Hello, World!')
          }, 3000);
        } else if (resData.value.status === 401) {
          localStorage.clear();
          this.props.history.push('/login');
        }else {
          // some error in server
          toast.error("Some error occured please try again", {
            position: toast.POSITION.TOP_CENTER
          });
          setTimeout(() => {
            this.setState({disabled: false});
          }, 3000);
        }

      } else {
        message.error('Please insert valid input')
      }
    }
  }



  render() {
    const { loading } = this.state;
    // console.log(this.props.user)
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content affiliates_wrapper">
        <Spin spinning={loading}>
          <div>
            <span>UpdateProfile</span>
          </div>

          <div className="clearBoth"></div>

          {this.state.messageerr ?
            <span className="invalid-feedback">
              {this.state.messageerr}
            </span>
            : ''
          }

          <div className="affMainData">
            <div className="content">
            <div className="form-group">
                  <input type="text" placeholder="Name" className="form-control" name="user_name"
                    value={this.state.user_name}
                    onChange={this.handleChange.bind(this)}
                    required />
                  {this.state.err.user_name ?
                    <span className="invalid-feedback">
                      {this.state.err.user_name}
                    </span>
                    : ''
                  }
                </div>
                <div className="form-group">
                  <input type="password" placeholder="Password" className="form-control" name="password"
                    onChange={this.handleChange.bind(this)}
                    required />
                </div>
                <div className="form-group">
                  <input type="number" placeholder="Mobile Number" className="form-control" name="user_contact"
                    value={this.state.user_contact}
                    onChange={this.handleChange.bind(this)}
                    required maxLength="10" />
                  {this.state.err.user_contact ?
                    <span className="invalid-feedback">
                      {this.state.err.user_contact}
                    </span>
                    : ''
                  }
                </div>
            </div>
          </div>

          <div>
            <input type="submit" disabled={this.state.disabled} onClick={this.UpdateProfile} value="update" className="marginleft ant-btn btnn ant-btn-primary floatLeft" />
          </div></Spin>
        </main>
        <ToastContainer />
      </div>
    );
  }
}

