import React, { PureComponent } from 'react'
import TopNavigation from './../TopNavigation/component'
import SidebarNavigation from './../SidebarNavigation/component'
import { message, Spin } from 'antd'
import "./styles.scss";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class EditAffiliateComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      user_id: '',
      user_name: '',
      password: '',
      user_email: '',
      user_contact: '',
      premium_request: '',
      err: {},
      message: '',
      disable: true,
      messageerr: '',
      loading: false,
      disabled: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.updateAffiliate = this.updateAffiliate.bind(this)
  }

  handleChange(e) {
    let disable = false
    const self = this
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (self.state.user_name === '' || self.state.user_name === undefined) {
        disable = true
      }
      if (self.state.password === '' || self.state.password === undefined) {
        disable = true
      }
      if (self.state.user_contact === '' || self.state.user_contact === undefined) {
        disable = true
      }
      if (self.state.premium_request === '' || self.state.premium_request === undefined) {
        disable = true
      }
      self.setState({ disable })
    })
  }

  componentDidMount() {
    document.title = 'Edit Affiliate | DBC'

    const uid = this.props.match.params.id;

    this.getAffiliateDetails(uid);
  }

  getAffiliateDetails = async (id) => {

    this.setState({ loading: true })

    const postData = {
      user_id: id,
    }

    const resData = await this.props.getAffiliateDetails(postData)
    this.setState({ loading: false })
    if (resData.value.status === 200) {
      this.setState({ user_name: resData.value.data.user_name })
      this.setState({ user_email: resData.value.data.user_email })
      this.setState({ password: '' })
      this.setState({ user_contact: resData.value.data.user_contact })
      this.setState({ premium_request: resData.value.data.premium_request })
      this.setState({ user_id: resData.value.data._id })
    } else if (resData.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/login');
    } else {
      this.setState({ messageerr: resData.value.msg })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.updateAffiliatePhase === 'success') {
      if (this.props.user) {
        if (this.props.user.status === 200) {

          this.props.history.push('/affiliatemanagment')
        } else if (this.props.user.status === 401) {
          localStorage.clear();
          this.props.history.push('/login');
        } else {
          this.setState({ messageerr: this.props.user.msg })
        }
      }
    }
  }


  updateAffiliate = async () => {

    const { user_name, user_email, password, user_contact, premium_request } = this.state

    /* eslint-disable */
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const err = {}

    if (user_name === '' || user_name === null || user_name.trim() === '') {
      err.user_name = 'Name is required.'
    }

    /* if (password === '' || password === null || password.trim() === '') {
       err.password = 'Password is required.'
     } */

    if (user_contact === '' || user_contact === null || user_contact.toString().trim() === '') {
      err.user_contact = 'Mobile number is required.'
    }


    if (user_contact && user_contact !== '') {
      const contactRegex = /^[0-9\b]+$/

      const testR1 = contactRegex.test(this.state.user_contact)
      if (!testR1) {
        err.user_contact = 'Mobile number must be integer.'
      }
    }

    if (user_contact.length < 10) {
      err.user_contact = 'Mobile number must be 10 number.'
    }

    if (premium_request === '' || premium_request === null || premium_request.toString().trim() === '') {
      err.premium_request = 'Premium request is required.'
    }


    this.setState({ err })
    if (!Object.keys(err).length) {
      this.setState({ disabled: true });
      if (user_name && user_contact && premium_request) {
        const updateAffiliateData = {
          user_name: this.state.user_name,
          user_email: "",//this.state.user_email,
          password: "",//this.state.password,
          user_contact: this.state.user_contact,
          premium_request: parseInt(this.state.premium_request),
          user_id: this.state.user_id
        }

        // this.props.updateAffiliate(updateAffiliateData)

        const resData = await this.props.updateAffiliate(updateAffiliateData);
        if (resData.value.status === 200) {
          //added successfully

          toast.success("updated successfully", {
            position: toast.POSITION.TOP_CENTER
          });

          setTimeout(() => {
            this.props.history.push("/affiliatemanagment");
          }, 3000);
        } else if (resData.value.status === 401) {
          localStorage.clear();
          this.props.history.push('/login');
        } else if (resData.value.status === 201) {
          //email already exist 
          toast.error("affiliate not found try again", {
            position: toast.POSITION.TOP_CENTER
          });
          setTimeout(() => {
            this.setState({ disabled: false });
          }, 3000);
        } else {
          // some error in server
          toast.error("Some error occured please try again", {
            position: toast.POSITION.TOP_CENTER
          });
          setTimeout(() => {
            this.setState({ disabled: false });
          }, 3000);
        }

      } else {
        message.error('Please insert valid input')
      }
    }
  }

  render() {
    const { loading } = this.state
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation />
        <TopNavigation {...this.props} />
        <Spin spinning={loading}>
          <main className="dashboard-layout-content affiliates_wrapper">
            <div>
              <span><Link to="/affiliatemanagment">Affiliates</Link>/Update Affiliate</span>
            </div>

            <div className="clearBoth"></div>

            {this.state.messageerr ?
              <span className="invalid-feedback">
                {this.state.messageerr}
              </span>
              : ''
            }

            <div className="affMainData">
              <div className="content">

                <div className="form-group">
                  <input type="email" placeholder="Email" className="form-control" name="user_email"
                    value={this.state.user_email} disabled={(this.state.disabled) ? "disabled" : ""} readOnly />

                </div>
                <div className="form-group">
                  <input type="text" placeholder="Name" className="form-control" name="user_name"
                    value={this.state.user_name}
                    onChange={this.handleChange.bind(this)}
                    required />
                  {this.state.err.user_name ?
                    <span className="invalid-feedback">
                      {this.state.err.user_name}
                    </span>
                    : ''
                  }
                </div>

                <div className="form-group">
                  <input type="text" placeholder="Mobile Number" className="form-control" name="user_contact"
                    value={this.state.user_contact}
                    onChange={this.handleChange.bind(this)}
                    required maxLength="10" />
                  {this.state.err.user_contact ?
                    <span className="invalid-feedback">
                      {this.state.err.user_contact}
                    </span>
                    : ''
                  }
                </div>
                <div className="form-group">
                  <input type="number" min="0" placeholder="Add premium per request" className="form-control" name="premium_request"
                    value={this.state.premium_request}
                    onChange={this.handleChange.bind(this)}
                    required maxLength="5" />
                  {this.state.err.premium_request ?
                    <span className="invalid-feedback">
                      {this.state.err.premium_request}
                    </span>
                    : ''
                  }
                </div>
              </div>
            </div>

            <div>
              <button type="button" disabled={this.state.disabled} onClick={this.updateAffiliate} onKeyDown={this.updateAffiliate} className="marginleft ant-btn btnn ant-btn-primary  floatLeft">{this.state.disabled ? 'Updating...' : 'Update'}</button>
            </div>
          </main></Spin>
        <ToastContainer />
      </div>
    );
  }
}

