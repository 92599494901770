import React, { PureComponent } from "react";
import { Tabs, Form, Row, Col, Checkbox, Button, Input, Select, message, Spin } from "antd";
// import { optionalCallExpression } from "@babel/types";
import { CloseCircleOutlined } from '@ant-design/icons';
import { Redirect } from 'react-router-dom'
import Icon from '@ant-design/icons';
const { Option } = Select;
const { TabPane } = Tabs;
class ModifyTabs extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
      err: {},
      message: "",
      checktypesResult: [],
      tabPosition: "left",
      selectedItems: [],
      checkfield: "",
      newFieldArray: [],
      renewalFieldArray: [],
      modifyFieldArray: [],
      cancelFieldArray: [],
      newChecked: false,
      renewChecked: false,
      modifyChecked: false,
      cancelChecked: false,
      issetupChecked: false,
      newCheckedDocument: false,
      newCheckedDocumentArray: [],
      renewCheckedDocument: false,
      renewCheckedDocumentArray: [],
      modifyCheckedDocument: false,
      modifyCheckedDocumentArray: [],
      cancelCheckedDocument: false,
      cancelCheckedDocumentArray: [],
      newFeeChargeArray: [],
      newFeeAddBaseCharge: [],
      newFeeAddAffiliateCharge: [],
      renewalFeeChargeArray: [],
      renewalFeeAddAffiliateCharge: [],
      modifyFeeChargeArray: [],
      modifyFeeAddAffiliateCharge: [],
      cancelFeeChargeArray: [],
      cancelFeeAddAffiliateCharge: [],
      service_name: "",
      service_icon: "",
      renewalFeeAddBaseCharge: [],
      modifyFeeAddBaseCharge: [],
      cancelFeeAddBaseCharge: [],
      service_id: '',
      centersItem: [],
      disabled : false,
      loading: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const dataobj = {
      service_id: this.props.service_id
    };
    const data = await this.props.fetchServiceById(dataobj);
    if (data.value.status === 200) {
      const servicedata = data.value.data;
      const fee_new = JSON.parse(servicedata.feestructure_new);
      const fee_renewal = JSON.parse(servicedata.feestructure_renewal)
      const fee_modify = JSON.parse(servicedata.feestructure_modify)
      const fee_cancel = JSON.parse(servicedata.feestructure_cancellation)

      this.setState({
        selectedItems: servicedata.service_centers, newChecked: servicedata.is_new, renewChecked: servicedata.is_renewal, modifyChecked: servicedata.is_modify, cancelChecked: servicedata.is_cancellation, newFieldArray: servicedata.formfields_new, renewalFieldArray: servicedata.formfields_renewal, modifyFieldArray: servicedata.formfields_modify, cancelFieldArray: servicedata.formfields_cancellation, newCheckedDocumentArray: servicedata.doclist_new, renewCheckedDocumentArray: servicedata.doclist_renewal, modifyCheckedDocumentArray: servicedata.doclist_modify, cancelCheckedDocumentArray: servicedata.doclist_cancellation,

        newFeeChargeArray: fee_new,
        renewalFeeChargeArray: fee_renewal,
        modifyFeeChargeArray: fee_modify,
        cancelFeeChargeArray: fee_cancel,

        service_icon: servicedata.service_icon, service_id: servicedata._id, service_name: servicedata.service_name
      })
      this.setState({ loading: false });
    } else if (data.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/login');
    }

    const datacenters = await this.props.fetchAllCenters();
    if (datacenters.value.status === 200) {
      this.setState({ centersItem: datacenters.value.data })
    } else if (datacenters.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/login');
    }
  }

  addClick(field) {
    const array = this.state[field];
    this.setState({ [field]: [...array, { form_type: "text", label: "", options: [] }] });
  }

  addFeeClick(array1, array2, array3) {
    const a1 = this.state[array1];
    const a2 = this.state[array2];
    const a3 = this.state[array3];
    this.setState({
      [array1]: [...a1, { label: "", price: "", base: true, affiliate: true }],
      [array2]: [...a2, { label: "", price: "", base: true, affiliate: true }],
      [array3]: [...a3, { label: "", price: "", base: true, affiliate: true }]
    });
  }

  onChangeNewFieldArray(e, i, array) {
    const arrayField = this.state[array];
    const array1 = [...arrayField];
    array1[i].label = e.target.value;
    this.setState({ [array]: array1 });
  }

  removeClick(i, field) {
    const arrayField = this.state[field];
    const array1 = [...arrayField];
    array1.splice(i, 1);
    this.setState({ [field]: array1 });
  }

  removeFeeClick(i, array1, array2, array3) {
    const a1 = this.state[array1];
    const a2 = this.state[array2];
    const a3 = this.state[array3];
    const r1 = [...a1];
    const r2 = [...a2];
    const r3 = [...a3];
    r1.splice(i, 1);
    r2.splice(i, 1);
    r3.splice(i, 1);
    this.setState({ [array1]: r1, [array2]: r2, [array3]: r3 });
  }

  onChange = (e, field, array) => {
    if (e.target.checked && "newChecked" === field) {
      this.setState({
        newChecked: true,
        newFieldArray: [{ form_type: "text", label: "", options: [] }],
        newCheckedDocumentArray: [{ form_type: "file", label: "", doc_type: "Original Document Required" }],
        newFeeChargeArray: [{ label: "", price: "", base: true, affiliate: true }],
        newFeeAddBaseCharge: [{ label: "", price: "" }],
        newFeeAddAffiliateCharge: [{ label: "", price: "" }]
      });
    }
    if (e.target.checked && "renewChecked" === field) {
      this.setState({
        renewChecked: true,
        renewalFieldArray: [{ form_type: "text", label: "", options: [] }],
        renewCheckedDocumentArray: [{ form_type: "file", label: "", doc_type: "Original Document Required" }],
        renewalFeeChargeArray: [{ label: "", price: "", base: true, affiliate: true }],
        renewalFeeAddBaseCharge: [{ label: "", price: "" }],
        renewalFeeAddAffiliateCharge: [{ label: "", price: "" }]
      });
    }
    if (e.target.checked && "modifyChecked" === field) {
      this.setState({
        modifyChecked: true,
        modifyFieldArray: [{ form_type: "text", label: "", options: [] }],
        modifyCheckedDocumentArray: [{ form_type: "file", label: "", doc_type: "Original Document Required" }],
        modifyFeeChargeArray: [{ label: "", price: "", base: true, affiliate: true }],
        modifyFeeAddBaseCharge: [{ label: "", price: "" }],
        modifyFeeAddAffiliateCharge: [{ label: "", price: "" }]
      });
    }
    if (e.target.checked && "cancelChecked" === field) {
      this.setState({
        cancelChecked: true,
        cancelFieldArray: [{ form_type: "text", label: "", options: [] }],
        cancelCheckedDocumentArray: [{ form_type: "file", label: "", doc_type: "Original Document Required" }],
        cancelFeeChargeArray: [{ label: "", price: "", base: true, affiliate: true }],
        cancelFeeAddBaseCharge: [{ label: "", price: "" }],
        cancelFeeAddAffiliateCharge: [{ label: "", price: "" }]
      });
    }
    if (!e.target.checked && "newChecked" === field) {
      this.setState({
        newChecked: false,
        newFieldArray: [],
        newCheckedDocumentArray: [],
        newFeeChargeArray: [],
        newFeeAddBaseCharge: [],
        newFeeAddAffiliateCharge: []
      });
    }
    if (!e.target.checked && "renewChecked" === field) {
      this.setState({
        renewChecked: false,
        renewalFieldArray: [],
        renewCheckedDocumentArray: [],
        renewalFeeChargeArray: [],
        renewalFeeAddAffiliateCharge: [],
        renewalFeeAddBaseCharge: []
      });
    }
    if (!e.target.checked && "modifyChecked" === field) {
      this.setState({
        modifyChecked: false,
        modifyFieldArray: [],
        modifyCheckedDocumentArray: [],
        modifyFeeChargeArray: [],
        modifyFeeAddAffiliateCharge: [],
        modifyFeeAddBaseCharge: []
      });
    }
    if (!e.target.checked && "cancelChecked" === field) {
      this.setState({
        cancelChecked: false,
        cancelFieldArray: [],
        cancelCheckedDocumentArray: [],
        cancelFeeChargeArray: [],
        cancelFeeAddBaseCharge: [],
        cancelFeeAddAffiliateCharge: []
      });
    }
  };

  addMoreOptions(field, i1, i2) {
    const arrayField = this.state[field];
    const array1 = [...arrayField];
    array1[i1].options.push({ label: "", value: "" });
    this.setState({ [field]: array1 });
  }

  onChangeFields = value => {
    this.setState({ checkfield: value });
  };

  onChangeSelectDropdown(e, i, array) {
    const arrayField = this.state[array];
    const array1 = [...arrayField];
    array1[i].form_type = e.target.value;
    if (e.target.value === "dropdown" || e.target.value === "checkbox") {
      array1[i].options = [{ label: "", value: "" }];
    }else{
      array1[i].options = [];
    }
    this.setState({ [array]: array1 });
  }

  onChangeSelectDropdownDoc(e, i, array) {
    const arrayField = this.state[array];
    const array1 = [...arrayField];
    array1[i].doc_type = e.target.value;
    this.setState({ [array]: array1 });
  }

  onChangeNewFieldArrayprice(e, i, array) {
    const arrayField = this.state[array];
    const array1 = [...arrayField];
    array1[i].price = e.target.value;
    this.setState({ [array]: array1 });
  }

  onChangeNewFieldArrayOption(e, i1, i2, array) {
    const arrayField = this.state[array];
    const array1 = [...arrayField];
    array1[i1].options[i2].label = e.target.value;
    this.setState({ [array]: array1 });
  }

  onChangeNewFieldArrayOptionVal(e, i1, i2, array) {
    const arrayField = this.state[array];
    const array1 = [...arrayField];
    array1[i1].options[i2].value = e.target.value;
    this.setState({ [array]: array1 });
  }

  removeClickOptions(array, i1, i2) {
    const arrayField = this.state[array];
    const array1 = [...arrayField];
    array1[i1].options.splice(i2, 1);
    this.setState({ [array]: array1 });
  }

  handleChanged = selectedItems => {
    this.setState({ selectedItems });
  };

  onChangeHandler = async e => {
    const input = e.target;
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = async function (r) {
        const formdata = new FormData();
        formdata.append("file", input.files[0]);
        // formdata.append("fileName", input.files[0].name);
        const data = await this.props.uploadImage(formdata);
        if (data.value.status === 200) {
          this.setState({ service_icon: data.value.data.filepath });
        } else {
          message.error("Please try again")
        }
      }.bind(this);
      reader.readAsDataURL(input.files[0]);
    }
  };

  handleSubmit = async e => {
    e.preventDefault();
    var is_submit = true;
    const {
      service_icon,
      service_name,
      selectedItems,
      newChecked,
      renewChecked,
      modifyChecked,
      cancelChecked,
      issetupChecked,
      newFieldArray,
      renewalFieldArray,
      modifyFieldArray,
      cancelFieldArray,
      newCheckedDocumentArray,
      renewCheckedDocumentArray,
      modifyCheckedDocumentArray,
      cancelCheckedDocumentArray,
      newFeeChargeArray,
      newFeeAddAffiliateCharge,
      renewalFeeChargeArray,
      renewalFeeAddAffiliateCharge,
      modifyFeeChargeArray,
      modifyFeeAddAffiliateCharge,
      cancelFeeChargeArray,
      cancelFeeAddAffiliateCharge,
      service_id,
    } = this.state;

    // console.log(service_name,, "ghgfhghgf", selectedItems);
    // if (service_name === '' || service_name.trim() === '') {
    //   err.service_name = 'Service Name is required.'
    // }

    // console.log(this.state)
    if (newChecked === false && renewChecked === false && modifyChecked === false && cancelChecked === false) {
      message.error("Please insert all Fields");
      is_submit = false
    }

    if (newChecked === true) {
      newCheckedDocumentArray.map(d => {
        if (d.label === "") {
          // message.error('Please insert all Fields new doc');
          is_submit = false
        }
      });
      // console.log({newFeeChargeArray})
      newFeeChargeArray.map(d => {
        if (d.label === "" || d.price === "") {
          // message.error('Please insert all Fields new fee info');
          is_submit = false
        }
      });
    }

    if (renewChecked === true) {
      renewCheckedDocumentArray.map(d => {
        if (d.label === "") {
          // message.error('Please insert all Fields Renewal doc');
          is_submit = false
        }
      });

      renewalFeeChargeArray.map(d => {
        if (d.label === "" || d.price === "") {
          // message.error('Please insert all Fields Renewal fee info');
          is_submit = false
        }
      });

    }

    if (modifyChecked === true) {
      modifyCheckedDocumentArray.map(d => {
        if (d.label === "") {
          // message.error('Please insert all Fields modify doc');
          is_submit = false;
        }
      });

      modifyFeeChargeArray.map(d => {
        if (d.label === "" || d.price === "") {
          // message.error('Please insert all Fields modify fee info');
          is_submit = false;
        }
      });
    }

    if (cancelChecked === true) {
      cancelCheckedDocumentArray.map(d => {
        if (d.label === "") {
          // message.error('Please insert all Fields cancel doc');
          is_submit = false;
        }
      });

      cancelFeeChargeArray.map(d => {
        if (d.label === "" || d.price === "") {
          //  message.error('Please insert all Fields cancel fee info');
          is_submit = false;
        }
      });
    }

    if (is_submit === true) {
      this.setState({disabled: true});
      const serviceData = {
        service_name: service_name,
        service_id: service_id,

        is_new: newChecked,
        is_renewal: renewChecked,
        is_cancellation: cancelChecked,
        is_modify: modifyChecked,

        formfields_new: newFieldArray,
        formfields_renewal: renewalFieldArray,
        formfields_cancellation: cancelFieldArray,
        formfields_modify: modifyFieldArray,

        doclist_new: newCheckedDocumentArray,
        doclist_renewal: renewCheckedDocumentArray,
        doclist_cancellation: cancelCheckedDocumentArray,
        doclist_modify: modifyCheckedDocumentArray,

        feestructure_new: newFeeChargeArray,
        feestructure_renewal: renewalFeeChargeArray,
        feestructure_cancellation: cancelFeeChargeArray,
        feestructure_modify: modifyFeeChargeArray,

        service_centers: selectedItems,
        service_icon: service_icon
      };
      // console.log(serviceData)
      const datasuccess = await this.props.updateService(serviceData);
      if (datasuccess.value.status === 200) {
        message.success(datasuccess.value.msg);
        this.props.getAllServices();
      } else {
        message.error(datasuccess.value.msg);
        setTimeout(() => {
          this.setState({disabled: false});
        }, 3000);
      }
    } else {
      message.error("Please insert all Fields Of All Tabs Required");
    }
  };

  handleChange(e) {
    let disable = false;
    const self = this;
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (self.state.service_name === "" || self.state.service_name === undefined) {
        disable = true;
      }
      // if (self.state.uniqueId === '' || self.state.uniqueId === undefined) {
      //   disable = true
      // }
      self.setState({ disable });
    });
  }

  onChangeNewFeeAddBaseCharge(e, i, a, array, sArray, key) {
    const arrayFielda = this.state[a];
    const array1a = [...arrayFielda];
    array1a[i][key] = e.target.value;
    this.setState({ [a]: array1a });
    // if (array) {
    //   console.log(array,'arrayyyy')
    //   const arrayField = this.state[array];
    //   console.log(arrayField,'arrayyyy')
    //   const array1 = [...arrayField];
    //   array1[i][key] = e.target.value;
    //   this.setState({ [array]: array1 });
    // }
    // if (sArray) {
    //   const arrayField1 = this.state[sArray];
    //   const array2 = [...arrayField1];
    //   array2[i][key] = e.target.value;
    //   this.setState({ [sArray]: array2 });
    // }
  }

  onChangeFeeCheckBox(e, i, checkboxName, array) {
    const arrayField = this.state[array];
    const array1 = [...arrayField];
    array1[i][checkboxName] = e.target.checked;
    this.setState({ [array]: array1 });
  }

  render() {
    const { centersItem, newCheckedDocumentArray, service_icon, newChecked, renewChecked, modifyChecked, cancelChecked, selectedItems } = this.state;
    const imgUrl = "https://dbcapi.webvilleedemo.xyz/" + service_icon;
    // console.log('111333', newFeeChargeArray, newFeeAddBaseCharge)
    let fieldArrayBool = false;
    let fieldArrayBoolDoc = false;
    if (newChecked === false && renewChecked === false && modifyChecked === false && cancelChecked === false) {
      fieldArrayBool = true;
      fieldArrayBoolDoc = true;
    }
    this.state.newFieldArray.map(d => {
      if (d.label === "") {
        fieldArrayBool = true;
        fieldArrayBoolDoc = true;
      }
    });
    this.state.renewalFieldArray.map(d => {
      if (d.label === "") {
        fieldArrayBool = true;
        fieldArrayBoolDoc = true
      }
    });
    this.state.modifyFieldArray.map(d => {
      if (d.label === "") {
        fieldArrayBool = true;
        fieldArrayBoolDoc = true;
      }
    });
    this.state.cancelFieldArray.map(d => {
      if (d.label === "") {
        fieldArrayBool = true;
        fieldArrayBoolDoc = true;
      }
    });
    newCheckedDocumentArray.map(d => {
      if (d.label === "") {
        fieldArrayBool = true;
      }
    });
    this.state.renewCheckedDocumentArray.map(d => {
      if (d.label === "") {
        fieldArrayBool = true;
      }
    });
    this.state.modifyCheckedDocumentArray.map(d => {
      if (d.label === "") {
        fieldArrayBool = true;
      }
    });
    this.state.cancelCheckedDocumentArray.map(d => {
      if (d.label === "") {
        fieldArrayBool = true;
      }
    });

    if (this.state.selectedItems.length === 0) {
      fieldArrayBool = true;
      fieldArrayBoolDoc = true;
    }
    if (this.state.service_name === "") {
      fieldArrayBool = true;
      fieldArrayBoolDoc = true;
    }
    if (this.state.service_icon === "") {
      fieldArrayBool = true;
      fieldArrayBoolDoc = true;
    }
    return (
      <div className="create__driver-wrapper">
        <div className="formCard">
          <Form>
            <Spin spinning={this.state.loading}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Service Details" key="1">
                  <Row gutter={16}>
                    <Col span={12}>
                      <div className="form-group">
                        <label>Name</label>
                        {/* <Form.Item name={["user", "name"]} placeholder="user"> */}
                        <Input
                          placeholder="Service Name"
                          required
                          name="service_name"
                          value={this.state.service_name}
                          onChange={this.handleChange.bind(this)}
                        />
                        {this.state.err.service_name ? (
                          <span className="invalid-feedback">{this.state.err.service_name}</span>
                        ) : (
                            ""
                          )}
                      </div>
                      <div className="form-group">
                        <label>Unique ID</label>
                        <Input
                          readOnly
                          name="service_id"
                          value={this.state.service_id}
                        />
                      </div>
                    </Col>
                    <Col span={12}>
                      {/* </Form.Item> */}
                      <div className="form-group">
                        <label>Service Location</label>
                        <Select mode="tags" style={{ width: '100%' }} value={selectedItems} placeholder="Centers" onChange={this.handleChanged}>
                          {centersItem.map(item => {
                            return (<Option key={item._id} value={item._id}>
                              {item.center_name}
                            </Option>)
                          })}
                        </Select>
                      </div>
                    </Col>
                  </Row>
                  <div className="form-group">
                    <div className="customUpload">
                      <input type="file" name="file" accept="image/*" onChange={this.onChangeHandler} />
                      <div className="customUploadContent">Upload Icon</div>
                      {service_icon !== "" ? <img className="marginright" alt="example" style={{ width: '50px' }} src={imgUrl} /> : ''}
                      {/* <p>{service_icon === "" ? "select File for Service Icon" : 'Icon already selected'}</p> */}
                    </div>
                  </div>
                  <Row gutter={16}>
                    <Col span={12} className="">
                      <div className="form-group">
                        <Checkbox checked={newChecked} onChange={e => this.onChange(e, "newChecked", "newFieldArray")}>
                          New
                      </Checkbox>
                        <Checkbox
                          checked={renewChecked}
                          onChange={e => this.onChange(e, "renewChecked", "renewalFieldArray")}
                        >
                          Renew
                      </Checkbox>
                        <Checkbox
                          checked={modifyChecked}
                          onChange={e => this.onChange(e, "modifyChecked", "modifyFieldArray")}
                        >
                          Modify
                      </Checkbox>
                        <Checkbox
                          checked={cancelChecked}
                          onChange={e => this.onChange(e, "cancelChecked", "cancelFieldArray")}
                        >
                          Cancel
                      </Checkbox>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      {this.state.newFieldArray.length !== 0 && (
                        <div className="serviceFields">
                          <h6>Add Input Fields For New</h6>
                          {this.state.newFieldArray.map((item, i) => {
                            return (
                              <div className="serviceFieldsInner" key={i}>
                                <div className="serviceFieldsFlex">
                                  <div className="serviceField">
                                    <Input
                                      placeholder="Add label"
                                      type="text"
                                      value={item.label}
                                      name={`label${i}`}
                                      onChange={e => this.onChangeNewFieldArray(e, i, "newFieldArray")}
                                    />
                                  </div>
                                  <div className="serviceField">
                                    <select
                                      className="browser-default custom-select"
                                      value={item.form_type}
                                      name={`form_type${i}`}
                                      onChange={e => this.onChangeSelectDropdown(e, i, "newFieldArray")}
                                    >
                                      <option value="text">Text</option>
                                      <option value="checkbox">Checkbox</option>
                                      <option value="number">Number</option>
                                      <option value="textarea">Textarea</option>
                                      <option value="dropdown">Dropdown</option>
                                    </select>
                                  </div>

                                  {this.state.newFieldArray.length > 1 && (
                                    <div className="serviceFieldButton">
                                      <Button type="button" onClick={() => this.removeClick(i, "newFieldArray")}>
                                        <CloseCircleOutlined />
                                      </Button>
                                    </div>
                                  )}
                                </div>
                                {item.options.length !== 0 && (
                                  <div className="serviceExtraFieldsMain">
                                    {item.options.length !== 0 &&
                                      item.options.map((option, i2) => {
                                        return (
                                          <div className="serviceExtraFieldsFlex" key={i2}>
                                            <div className="serviceExtraField">
                                              <Input
                                                placeholder="Add label"
                                                type="text"
                                                required
                                                name={`label${i2}`}
                                                value={option.label}
                                                onChange={e =>
                                                  this.onChangeNewFieldArrayOption(e, i, i2, "newFieldArray")
                                                }
                                              />
                                            </div>
                                            <div className="serviceExtraField">
                                              <Input
                                                placeholder="Add value"
                                                type="text"
                                                required
                                                value={option.value}
                                                name={`value${i2}`}
                                                onChange={e =>
                                                  this.onChangeNewFieldArrayOptionVal(e, i, i2, "newFieldArray")
                                                }
                                              />
                                            </div>
                                            <div className="serviceExtraFieldButton">
                                              <Button
                                                type="button"
                                                onClick={() => this.removeClickOptions("newFieldArray", i, i2)}
                                              >
                                                <CloseCircleOutlined />
                                              </Button>
                                            </div>
                                            {/* <Input
                                    type="button"
                                    value="remove"
                                    onClick={() => this.removeClickOptions("newFieldArray", i, i2)}
                                  /> */}
                                            {/* <Input
                                    type="button"
                                    value="add options"
                                    onClick={() => this.addMoreOptions("newFieldArray", i, i2)}
                                  /> */}
                                          </div>
                                        );
                                      })}
                                    {item.options.length !== 0 && (
                                      <Button type="button" onClick={() => this.addMoreOptions("newFieldArray", i)}>
                                        Add More Option
                                      </Button>
                                    )}
                                  </div>
                                )}
                                {i === this.state.newFieldArray.length - 1 && (
                                  <Button onClick={() => this.addClick("newFieldArray")} type="button">
                                    Add More
                                  </Button>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}

                      {/*  */}
                      {this.state.modifyFieldArray.length !== 0 && (
                        <div className="serviceFields">
                          <h6>Add Input Fields For Modify</h6>
                          {this.state.modifyFieldArray.map((item, i) => {
                            return (
                              <div className="serviceFieldsInner" key={i}>
                                <div className="serviceFieldsFlex">
                                  <div className="serviceField">
                                    <Input
                                      placeholder="Add label"
                                      type="text"
                                      value={item.label}
                                      name={`label${i}`}
                                      onChange={e => this.onChangeNewFieldArray(e, i, "modifyFieldArray")}
                                      required
                                    />
                                  </div>
                                  <div className="serviceField">
                                    <select
                                      required
                                      className="browser-default custom-select"
                                      value={item.form_type}
                                      name={`form_type${i}`}
                                      onChange={e => this.onChangeSelectDropdown(e, i, "modifyFieldArray")}
                                    >
                                      <option value="text">Text</option>
                                      <option value="checkbox">Checkbox</option>
                                      <option value="number">Number</option>
                                      <option value="textarea">Textarea</option>
                                      <option value="dropdown">Dropdown</option>
                                    </select>
                                  </div>

                                  {this.state.modifyFieldArray.length > 1 && (
                                    <div className="serviceFieldButton">
                                      <Button type="button" onClick={() => this.removeClick(i, "modifyFieldArray")}>
                                        <CloseCircleOutlined />
                                      </Button>
                                    </div>
                                  )}
                                </div>
                                {item.options.length !== 0 && (
                                  <div className="serviceExtraFieldsMain">
                                    {item.options.length !== 0 &&
                                      item.options.map((option, i2) => {
                                        return (
                                          <div className="serviceExtraFieldsFlex" key={i2}>
                                            <div className="serviceExtraField">
                                              <Input
                                                placeholder="Add label"
                                                type="text"
                                                required
                                                value={option.label}
                                                name={`label${i2}`}
                                                onChange={e =>
                                                  this.onChangeNewFieldArrayOption(e, i, i2, "modifyFieldArray")
                                                }
                                              />
                                            </div>
                                            <div className="serviceExtraField">
                                              <Input
                                                placeholder="Add value"
                                                type="text"
                                                required
                                                value={option.value}
                                                name={`value${i2}`}
                                                onChange={e =>
                                                  this.onChangeNewFieldArrayOptionVal(e, i, i2, "modifyFieldArray")
                                                }
                                              />
                                            </div>
                                            <div className="serviceExtraFieldButton">
                                              <Button
                                                type="button"
                                                onClick={() => this.removeClickOptions("modifyFieldArray", i, i2)}
                                              >
                                                <CloseCircleOutlined />
                                              </Button>
                                            </div>
                                            {/* <Input
                                    type="button"
                                    value="remove"
                                    onClick={() => this.removeClickOptions("newFieldArray", i, i2)}
                                  /> */}
                                            {/* <Input
                                    type="button"
                                    value="add options"
                                    onClick={() => this.addMoreOptions("newFieldArray", i, i2)}
                                  /> */}
                                          </div>
                                        );
                                      })}
                                    {item.options.length !== 0 && (
                                      <Button type="button" onClick={() => this.addMoreOptions("modifyFieldArray", i)}>
                                        Add More option
                                      </Button>
                                    )}
                                  </div>
                                )}
                                {i === this.state.modifyFieldArray.length - 1 && (
                                  <Button onClick={() => this.addClick("modifyFieldArray")} type="button">
                                    Add More
                                  </Button>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}

                      {/*  */}
                      <div>
                        {/* {this.state.modifyFieldArray.length !== 0 && <h6>Add Input Fields For Modify</h6>}
                      {this.state.modifyFieldArray.map((item, i) => {
                        return (
                          <li key={i}>
                            <Input
                              required
                              style={{ width: "40%" }}
                              placeholder="Add label"
                              type="text"
                              value={item.label} name={`label${i}`}
                              onChange={e => this.onChangeNewFieldArray(e, i, "modifyFieldArray")}
                            />
                            <select
                              required
                              style={{ width: "40%" }}
                              className="browser-default custom-select"
                              value={item.form_type} name={`form_type${i}`}
                              onChange={e => this.onChangeSelectDropdown(e, i, "modifyFieldArray")}
                            >
                              <option value="">Select Type</option>
                              <option value="text">Text</option>
                              <option value="checkbox">Checkbox</option>
                              <option value="number">Number</option>
                              <option value="file">File</option>
                              <option value="dropdown">Dropdown</option>
                            </select>
                            {item.options.length !== 0 &&
                              item.options.map((option, i2) => {
                                return (
                                  <div key={i2}>
                                    <Input
                                      style={{ width: "30%" }}
                                      placeholder=" Add Options"
                                      type="text"
                                      required
                                      value={option.label}
                                      onChange={e => this.onChangeNewFieldArrayOption(e, i, i2, "modifyFieldArray")}
                                    />
                                    <Input
                                      style={{ width: "20%" }}
                                      type="button"
                                      value="remove"
                                      onClick={() => this.removeClickOptions("modifyFieldArray", i, i2)}
                                    />
                                   {i2 === item.options.length - 1 && (<Input
                                      style={{ width: "30%" }}
                                      type="button"
                                      value="add options"
                                      onClick={() => this.addMoreOptions("modifyFieldArray", i, i2)}
                                    />)}
                                  </div>
                                );
                              })}
                            {this.state.modifyFieldArray.length > 1 && (
                              <Button type="button" onClick={() => this.removeClick(i, "modifyFieldArray")}><CloseCircleOutlined /></Button>
                              // <Input
                              //   style={{ width: "20%" }}
                              //   type="button"
                              //   value="Remove"
                              //   onClick={() => this.removeClick(i, "modifyFieldArray")}
                              // />
                            )}
                            {i === this.state.modifyFieldArray.length - 1 && (
                              <Input
                                style={{ width: "20%" }}
                                type="button"
                                value="add more"
                                onClick={() => this.addClick("modifyFieldArray")}
                              />
                            )}
                          </li>
                        );
                      })} */}

                        {/*  */}

                        {this.state.renewalFieldArray.length !== 0 && (
                          <div className="serviceFields">
                            <h6>Add Input Fields For Renewal</h6>
                            {this.state.renewalFieldArray.map((item, i) => {
                              return (
                                <div className="serviceFieldsInner" key={i}>
                                  <div className="serviceFieldsFlex">
                                    <div className="serviceField">
                                      <Input
                                        placeholder="Add label"
                                        type="text"
                                        value={item.label}
                                        name={`label${i}`}
                                        onChange={e => this.onChangeNewFieldArray(e, i, "renewalFieldArray")}
                                        required
                                      />
                                    </div>
                                    <div className="serviceField">
                                      <select
                                        required
                                        className="browser-default custom-select"
                                        value={item.form_type}
                                        name={`form_type${i}`}
                                        onChange={e => this.onChangeSelectDropdown(e, i, "renewalFieldArray")}
                                      >
                                        <option value="text">Text</option>
                                        <option value="checkbox">Checkbox</option>
                                        <option value="number">Number</option>
                                        <option value="textarea">Textarea</option>
                                        <option value="dropdown">Dropdown</option>
                                      </select>
                                    </div>

                                    {this.state.renewalFieldArray.length > 1 && (
                                      <div className="serviceFieldButton">
                                        <Button type="button" onClick={() => this.removeClick(i, "renewalFieldArray")}>
                                          <CloseCircleOutlined />
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                  {item.options.length !== 0 && (
                                    <div className="serviceExtraFieldsMain">
                                      {item.options.length !== 0 &&
                                        item.options.map((option, i2) => {
                                          return (
                                            <div className="serviceExtraFieldsFlex" key={i2}>
                                              <div className="serviceExtraField">
                                                <Input
                                                  placeholder="Add label"
                                                  type="text"
                                                  required
                                                  value={option.label}
                                                  name={`label${i2}`}
                                                  onChange={e =>
                                                    this.onChangeNewFieldArrayOption(e, i, i2, "renewalFieldArray")
                                                  }
                                                />
                                              </div>
                                              <div className="serviceExtraField">
                                                <Input
                                                  placeholder="Add value"
                                                  type="text"
                                                  required
                                                  value={option.value}
                                                  name={`value${i2}`}
                                                  onChange={e =>
                                                    this.onChangeNewFieldArrayOptionVal(e, i, i2, "renewalFieldArray")
                                                  }
                                                />
                                              </div>
                                              <div className="serviceExtraFieldButton">
                                                <Button
                                                  type="button"
                                                  onClick={() => this.removeClickOptions("renewalFieldArray", i, i2)}
                                                >
                                                  <CloseCircleOutlined />
                                                </Button>
                                              </div>
                                              {/* <Input
                                    type="button"
                                    value="remove"
                                    onClick={() => this.removeClickOptions("newFieldArray", i, i2)}
                                  /> */}
                                              {/* <Input
                                    type="button"
                                    value="add options"
                                    onClick={() => this.addMoreOptions("newFieldArray", i, i2)}
                                  /> */}
                                            </div>
                                          );
                                        })}
                                      {item.options.length !== 0 && (
                                        <Button type="button" onClick={() => this.addMoreOptions("renewalFieldArray", i)}>
                                          Add More option
                                        </Button>
                                      )}
                                    </div>
                                  )}
                                  {i === this.state.renewalFieldArray.length - 1 && (
                                    <Button onClick={() => this.addClick("renewalFieldArray")} type="button">
                                      Add More
                                    </Button>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}

                        {/*  */}

                        {/* {this.state.renewalFieldArray.length !== 0 && <h6>Add Input Fields For Renewal</h6>}
                      {this.state.renewalFieldArray.map((item, i) => {
                        return (
                          <li key={i}>
                            <Input
                              placeholder="Add label"
                              style={{ width: "40%" }}
                              type="text"
                              required
                              value={item.label} name={`label${i}`}
                              onChange={e => this.onChangeNewFieldArray(e, i, "renewalFieldArray")}
                            />
                            <select
                              required
                              style={{ width: "40%" }}
                              className="browser-default custom-select"
                              value={item.form_type} name={`form_type${i}`}
                              onChange={e => this.onChangeSelectDropdown(e, i, "renewalFieldArray")}
                            >
                              <option value="">Select Type</option>
                              <option value="text">Text</option>
                              <option value="checkbox">Checkbox</option>
                              <option value="number">Number</option>
                              <option value="file">File</option>
                              <option value="dropdown">Dropdown</option>
                            </select>
                            {item.options.length !== 0 &&
                              item.options.map((option, i2) => {
                                return (
                                  <div key={i2}>
                                    <Input
                                      style={{ width: "30%" }}
                                      placeholder=" Add label"
                                      type="text"
                                      required
                                      value={option.label}
                                      onChange={e => this.onChangeNewFieldArrayOption(e, i, i2, "renewalFieldArray")}
                                    />
                                    <Input
                                      style={{ width: "20%" }}
                                      type="button"
                                      value="remove"
                                      onClick={() => this.removeClickOptions("renewalFieldArray", i, i2)}
                                    />
                                    {i2 === item.options.length - 1 && (<Input
                                      style={{ width: "30%" }}
                                      type="button"
                                      value="add options"
                                      onClick={() => this.addMoreOptions("renewalFieldArray", i, i2)}
                                    />)}
                                  </div>
                                );
                              })}
                            {this.state.renewalFieldArray.length > 1 && (
                              <Input
                                style={{ width: "20%" }}
                                type="button"
                                value="Remove"
                                onClick={() => this.removeClick(i, "renewalFieldArray")}
                              />
                            )}
                            {i === this.state.renewalFieldArray.length - 1 && (
                              <Input
                                style={{ width: "20%" }}
                                type="button"
                                value="add more"
                                onClick={() => this.addClick("renewalFieldArray")}
                              />
                            )}
                          </li>
                        );
                      })} */}

                        {/*  */}
                        {this.state.cancelFieldArray.length !== 0 && (
                          <div className="serviceFields">
                            <h6>Add Input Fields For Cancel</h6>
                            {this.state.cancelFieldArray.map((item, i) => {
                              return (
                                <div className="serviceFieldsInner" key={i}>
                                  <div className="serviceFieldsFlex">
                                    <div className="serviceField">
                                      <Input
                                        placeholder="Add label"
                                        type="text"
                                        value={item.label}
                                        name={`label${i}`}
                                        onChange={e => this.onChangeNewFieldArray(e, i, "cancelFieldArray")}
                                        required
                                      />
                                    </div>
                                    <div className="serviceField">
                                      <select
                                        required
                                        className="browser-default custom-select"
                                        value={item.form_type}
                                        name={`form_type${i}`}
                                        onChange={e => this.onChangeSelectDropdown(e, i, "cancelFieldArray")}
                                      >
                                        <option value="text">Text</option>
                                        <option value="checkbox">Checkbox</option>
                                        <option value="number">Number</option>
                                        <option value="textarea">Textarea</option>
                                        <option value="dropdown">Dropdown</option>
                                      </select>
                                    </div>

                                    {this.state.cancelFieldArray.length > 1 && (
                                      <div className="serviceFieldButton">
                                        <Button type="button" onClick={() => this.removeClick(i, "cancelFieldArray")}>
                                          <CloseCircleOutlined />
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                  {item.options.length !== 0 && (
                                    <div className="serviceExtraFieldsMain">
                                      {item.options.length !== 0 &&
                                        item.options.map((option, i2) => {
                                          return (
                                            <div className="serviceExtraFieldsFlex" key={i2}>
                                              <div className="serviceExtraField">
                                                <Input
                                                  placeholder="Add label"
                                                  type="text"
                                                  required
                                                  value={option.label}
                                                  name={`label${i2}`}
                                                  onChange={e =>
                                                    this.onChangeNewFieldArrayOption(e, i, i2, "cancelFieldArray")
                                                  }
                                                />
                                              </div>
                                              <div className="serviceExtraField">
                                                <Input
                                                  placeholder="Add value"
                                                  type="text"
                                                  required
                                                  value={option.value}
                                                  name={`value${i2}`}
                                                  onChange={e =>
                                                    this.onChangeNewFieldArrayOptionVal(e, i, i2, "cancelFieldArray")
                                                  }
                                                />
                                              </div>
                                              <div className="serviceExtraFieldButton">
                                                <Button
                                                  type="button"
                                                  onClick={() => this.removeClickOptions("cancelFieldArray", i, i2)}
                                                >
                                                  <CloseCircleOutlined />
                                                </Button>
                                              </div>
                                              {/* <Input
                                    type="button"
                                    value="remove"
                                    onClick={() => this.removeClickOptions("newFieldArray", i, i2)}
                                  /> */}
                                              {/* <Input
                                    type="button"
                                    value="add options"
                                    onClick={() => this.addMoreOptions("newFieldArray", i, i2)}
                                  /> */}
                                            </div>
                                          );
                                        })}
                                      {item.options.length !== 0 && (
                                        <Button type="button" onClick={() => this.addMoreOptions("cancelFieldArray", i)}>
                                          Add More option
                                        </Button>
                                      )}
                                    </div>
                                  )}
                                  {i === this.state.cancelFieldArray.length - 1 && (
                                    <Button onClick={() => this.addClick("cancelFieldArray")} type="button">
                                      Add More
                                    </Button>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {/*  */}
                        {/* {this.state.cancelFieldArray.length !== 0 && <h6>Add Input Fields For Cancel</h6>}
                      {this.state.cancelFieldArray.map((item, i) => {
                        return (
                          <li key={i}>
                            <Input
                              placeholder="Add label"
                              style={{ width: "40%" }}
                              type="text"
                              required
                              value={item.label} name={`label${i}`}
                              onChange={e => this.onChangeNewFieldArray(e, i, "cancelFieldArray")}
                            />
                            <select
                              required
                              style={{ width: "40%" }}
                              className="browser-default custom-select"
                              value={item.form_type} name={`form_type${i}`}
                              onChange={e => this.onChangeSelectDropdown(e, i, "cancelFieldArray")}
                            >
                              <option value="">Select Type</option>
                              <option value="text">Text</option>
                              <option value="checkbox">Checkbox</option>
                              <option value="number">Number</option>
                              <option value="file">File</option>
                              <option value="dropdown">Dropdown</option>
                            </select>
                            {item.options.length !== 0 &&
                              item.options.map((option, i2) => {
                                return (
                                  <div key={i2}>
                                    <Input
                                      style={{ width: "30%" }}
                                      placeholder=" Add label"
                                      type="text"
                                      required
                                      value={option.label}
                                      onChange={e => this.onChangeNewFieldArrayOption(e, i, i2, "cancelFieldArray")}
                                    />
                                    <Input
                                      style={{ width: "20%" }}
                                      type="button"
                                      value="remove"
                                      onClick={() => this.removeClickOptions("cancelFieldArray", i, i2)}
                                    />
                                    {i2 === item.options.length - 1 && (<Input
                                      style={{ width: "30%" }}
                                      type="button"
                                      value="add options"
                                      onClick={() => this.addMoreOptions("cancelFieldArray", i, i2)}
                                    />)}
                                  </div>
                                );
                              })}
                            {this.state.cancelFieldArray.length > 1 && (
                              <Input
                                style={{ width: "20%" }}
                                type="button"
                                value="Remove"
                                onClick={() => this.removeClick(i, "cancelFieldArray")}
                              />
                            )}
                            {i === this.state.cancelFieldArray.length - 1 && (
                              <Input
                                style={{ width: "20%" }}
                                type="button"
                                value="add more"
                                onClick={() => this.addClick("cancelFieldArray")}
                              />
                            )}
                          </li>
                        );
                      })} */}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Documents Required" key="2" disabled={fieldArrayBoolDoc}>
                  <Row gutter={16}>
                    <Col span={16} style={{ display: "none" }}>
                      <Checkbox checked={newChecked} onChange={e => this.onChange(e, "newChecked", "newFieldArray")}>
                        New
                    </Checkbox>
                      <Checkbox
                        checked={renewChecked}
                        onChange={e => this.onChange(e, "renewChecked", "renewalFieldArray")}
                      >
                        Renew
                    </Checkbox>
                      <Checkbox
                        checked={modifyChecked}
                        onChange={e => this.onChange(e, "modifyChecked", "modifyFieldArray")}
                      >
                        Modify
                    </Checkbox>
                      <Checkbox
                        checked={cancelChecked}
                        onChange={e => this.onChange(e, "cancelChecked", "cancelFieldArray")}
                      >
                        Cancel
                    </Checkbox>
                    fieldsValue
                  </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <div>
                        {this.state.newCheckedDocumentArray.length !== 0 && (
                          <div className="requiredDocumnetMain">
                            {this.state.newCheckedDocumentArray.length !== 0 && (
                              <h6>Add Input Fields For Documents New</h6>
                            )}
                            {this.state.newCheckedDocumentArray.map((item, i) => {
                              return (
                                <div className="requiredDocumnetInner" key={i}>
                                  <div className="requiredDocumnetFlex">
                                    <div className="requiredDocumnetField">
                                      <Input
                                        required
                                        placeholder="Document Name"
                                        type="text"
                                        value={item.label}
                                        name={`label${i}`}
                                        onChange={e => this.onChangeNewFieldArray(e, i, "newCheckedDocumentArray")}
                                      />
                                    </div>
                                    <div className="requiredDocumnetField">
                                      <select
                                        className="browser-default custom-select"
                                        value={item.doc_type}
                                        name={`doc_type${i}`}
                                        onChange={e => this.onChangeSelectDropdownDoc(e, i, "newCheckedDocumentArray")}
                                      >
                                        <option value="Original Document Required">Original Doc Required</option>
                                        <option value="Original Document Not Required">Original Doc Not Required</option>
                                      </select>
                                    </div>
                                    {this.state.newCheckedDocumentArray.length > 1 && (
                                      <div className="requiredDocumnetButton">
                                        <Button
                                          type="button"
                                          onClick={() => this.removeClick(i, "newCheckedDocumentArray")}
                                        >
                                          <CloseCircleOutlined />
                                        </Button>
                                        {/* <Input
                                style={{ width: "20%" }}
                                type="button"
                                value="Remove"
                                onClick={() => this.removeClick(i, "newCheckedDocumentArray")}
                              /> */}
                                      </div>
                                    )}
                                  </div>
                                  {i === this.state.newCheckedDocumentArray.length - 1 && (
                                    <Button type="button" onClick={() => this.addClick("newCheckedDocumentArray")}>
                                      Add More
                                    </Button>
                                    // <Input
                                    //   type="button"
                                    //   value="Add more"
                                    //   onClick={() => this.addClick("newCheckedDocumentArray")}
                                    // />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}

                        {this.state.renewCheckedDocumentArray.length !== 0 && (
                          <div className="requiredDocumnetMain">
                            {this.state.renewCheckedDocumentArray.length !== 0 && (
                              <h6>Add Input Fields For Documents Renew</h6>
                            )}
                            {this.state.renewCheckedDocumentArray.map((item, i) => {
                              return (
                                <div className="requiredDocumnetInner" key={i}>
                                  <div className="requiredDocumnetFlex">
                                    <div className="requiredDocumnetField">
                                      <Input
                                        required
                                        placeholder="Document Name"
                                        type="text"
                                        value={item.label}
                                        name={`label${i}`}
                                        onChange={e => this.onChangeNewFieldArray(e, i, "renewCheckedDocumentArray")}
                                      />
                                    </div>
                                    <div className="requiredDocumnetField">
                                      <select
                                        required
                                        className="browser-default custom-select"
                                        value={item.doc_type}
                                        name={`doc_type${i}`}
                                        onChange={e => this.onChangeSelectDropdownDoc(e, i, "renewCheckedDocumentArray")}
                                      >
                                        <option value="Original Document Required">Original Doc Required</option>
                                        <option value="Original Document Not Required">Original Doc Not Required</option>
                                      </select>
                                    </div>
                                    {this.state.renewCheckedDocumentArray.length > 1 && (
                                      <div className="requiredDocumnetButton">
                                        <Button
                                          type="button"
                                          onClick={() => this.removeClick(i, "renewCheckedDocumentArray")}
                                        >
                                          <CloseCircleOutlined />
                                        </Button>
                                        {/* <Input
                                style={{ width: "20%" }}
                                type="button"
                                value="Remove"
                                onClick={() => this.removeClick(i, "newCheckedDocumentArray")}
                              /> */}
                                      </div>
                                    )}
                                  </div>
                                  {i === this.state.renewCheckedDocumentArray.length - 1 && (
                                    <Button type="button" onClick={() => this.addClick("renewCheckedDocumentArray")}>
                                      Add More
                                    </Button>
                                    // <Input
                                    //   type="button"
                                    //   value="Add more"
                                    //   onClick={() => this.addClick("newCheckedDocumentArray")}
                                    // />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}

                        {/* {this.state.renewCheckedDocumentArray.length !== 0 && <h6>Add Input Fields For Documents Renew</h6>}
                      {this.state.renewCheckedDocumentArray.map((item, i) => {
                        return (
                          <li key={i}>
                            <Input required
                              placeholder="Document Name"
                              style={{ width: "40%" }}
                              type="text"
                              value={item.label} name={`label${i}`}
                              onChange={e => this.onChangeNewFieldArray(e, i, "renewCheckedDocumentArray")}
                            />
                            <select
                              required
                              style={{ width: "40%" }}
                              className="browser-default custom-select"
                              value={item.doc_type} name={`doc_type${i}`}
                              onChange={e => this.onChangeSelectDropdownDoc(e, i, "renewCheckedDocumentArray")}
                            >
                              <option value="">Documents Type</option>
                              <option value="Original Document Required">Original Doc Required</option>
                              <option value="Original Document Not Required">Original Doc Not Required</option>
                            </select>
                            {this.state.renewCheckedDocumentArray.length > 1 && (
                              <Input
                                style={{ width: "20%" }}
                                type="button"
                                value="Remove"
                                onClick={() => this.removeClick(i, "renewCheckedDocumentArray")}
                              />
                            )}
                            {i === this.state.renewCheckedDocumentArray.length - 1 && (
                              <Input
                                style={{ width: "30%" }}
                                type="button"
                                value="add more"
                                onClick={() => this.addClick("renewCheckedDocumentArray")}
                              />
                            )}
                          </li>
                        );
                      })} */}

                        {/*  */}
                        {this.state.modifyCheckedDocumentArray.length !== 0 && (
                          <div className="requiredDocumnetMain">
                            {this.state.modifyCheckedDocumentArray.length !== 0 && (
                              <h6>Add Input Fields For Documents Modify</h6>
                            )}
                            {this.state.modifyCheckedDocumentArray.map((item, i) => {
                              return (
                                <div className="requiredDocumnetInner" key={i}>
                                  <div className="requiredDocumnetFlex">
                                    <div className="requiredDocumnetField">
                                      <Input
                                        required
                                        placeholder="Document Name"
                                        type="text"
                                        value={item.label}
                                        name={`label${i}`}
                                        onChange={e => this.onChangeNewFieldArray(e, i, "modifyCheckedDocumentArray")}
                                      />
                                    </div>
                                    <div className="requiredDocumnetField">
                                      <select
                                        className="browser-default custom-select"
                                        value={item.doc_type}
                                        name={`doc_type${i}`}
                                        onChange={e => this.onChangeSelectDropdownDoc(e, i, "modifyCheckedDocumentArray")}
                                      >
                                        <option value="Original Document Required">Original Doc Required</option>
                                        <option value="Original Document Not Required">Original Doc Not Required</option>
                                      </select>
                                    </div>
                                    {this.state.modifyCheckedDocumentArray.length > 1 && (
                                      <div className="requiredDocumnetButton">
                                        <Button
                                          type="button"
                                          onClick={() => this.removeClick(i, "modifyCheckedDocumentArray")}
                                        >
                                          <CloseCircleOutlined />
                                        </Button>
                                        {/* <Input
                                style={{ width: "20%" }}
                                type="button"
                                value="Remove"
                                onClick={() => this.removeClick(i, "newCheckedDocumentArray")}
                              /> */}
                                      </div>
                                    )}
                                  </div>
                                  {i === this.state.modifyCheckedDocumentArray.length - 1 && (
                                    <Button type="button" onClick={() => this.addClick("modifyCheckedDocumentArray")}>
                                      Add More
                                    </Button>
                                    // <Input
                                    //   type="button"
                                    //   value="Add more"
                                    //   onClick={() => this.addClick("newCheckedDocumentArray")}
                                    // />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {/*  */}

                        {/* {this.state.modifyCheckedDocumentArray.length !== 0 && (
                        <h6>Add Input Fields For Documents Modify</h6>
                      )}
                      {this.state.modifyCheckedDocumentArray.map((item, i) => {
                        return (
                          <li key={i}>
                            <Input required
                              placeholder="Document Name"
                              style={{ width: "40%" }}
                              type="text"
                              value={item.label} name={`label${i}`}
                              onChange={e => this.onChangeNewFieldArray(e, i, "modifyCheckedDocumentArray")}
                            />
                            <select
                              required
                              style={{ width: "40%" }}
                              className="browser-default custom-select"
                              value={item.doc_type} name={`doc_type${i}`}
                              onChange={e => this.onChangeSelectDropdownDoc(e, i, "modifyCheckedDocumentArray")}
                            >
                              <option value="">Documents Type</option>
                              <option value="Original Document Required">Original Doc Required</option>
                              <option value="Original Document Not Required">Original Doc Not Required</option>
                            </select>
                            {this.state.modifyCheckedDocumentArray.length > 1 && (
                              <Input
                                style={{ width: "20%" }}
                                type="button"
                                value="Remove"
                                onClick={() => this.removeClick(i, "modifyCheckedDocumentArray")}
                              />
                            )}
                            {i === this.state.modifyCheckedDocumentArray.length - 1 && (
                              <Input
                                style={{ width: "30%" }}
                                type="button"
                                value="add more"
                                onClick={() => this.addClick("modifyCheckedDocumentArray")}
                              />
                            )}
                          </li>
                        );
                      })} */}

                        {/*  */}
                        {this.state.cancelCheckedDocumentArray.length !== 0 && (
                          <div className="requiredDocumnetMain">
                            {this.state.cancelCheckedDocumentArray.length !== 0 && (
                              <h6>Add Input Fields For Documents Cancel</h6>
                            )}
                            {this.state.cancelCheckedDocumentArray.map((item, i) => {
                              return (
                                <div className="requiredDocumnetInner" key={i}>
                                  <div className="requiredDocumnetFlex">
                                    <div className="requiredDocumnetField">
                                      <Input
                                        required
                                        placeholder="Document Name"
                                        type="text"
                                        value={item.label}
                                        name={`label${i}`}
                                        onChange={e => this.onChangeNewFieldArray(e, i, "cancelCheckedDocumentArray")}
                                      />
                                    </div>
                                    <div className="requiredDocumnetField">
                                      <select
                                        className="browser-default custom-select"
                                        value={item.doc_type}
                                        name={`doc_type${i}`}
                                        onChange={e => this.onChangeSelectDropdownDoc(e, i, "cancelCheckedDocumentArray")}
                                      >
                                        <option value="Original Document Required">Original Doc Required</option>
                                        <option value="Original Document Not Required">Original Doc Not Required</option>
                                      </select>
                                    </div>
                                    {this.state.cancelCheckedDocumentArray.length > 1 && (
                                      <div className="requiredDocumnetButton">
                                        <Button
                                          type="button"
                                          onClick={() => this.removeClick(i, "cancelCheckedDocumentArray")}
                                        >
                                          <CloseCircleOutlined />
                                        </Button>
                                        {/* <Input
                                style={{ width: "20%" }}
                                type="button"
                                value="Remove"
                                onClick={() => this.removeClick(i, "newCheckedDocumentArray")}
                              /> */}
                                      </div>
                                    )}
                                  </div>
                                  {i === this.state.cancelCheckedDocumentArray.length - 1 && (
                                    <Button type="button" onClick={() => this.addClick("cancelCheckedDocumentArray")}>
                                      Add More
                                    </Button>
                                    // <Input
                                    //   type="button"
                                    //   value="Add more"
                                    //   onClick={() => this.addClick("newCheckedDocumentArray")}
                                    // />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {/*  */}

                        {/* {this.state.cancelCheckedDocumentArray.length !== 0 && (
                        <h6>Add Input Fields For Documents Cancel</h6>
                      )}
                      {this.state.cancelCheckedDocumentArray.map((item, i) => {
                        return (
                          <li key={i}>
                            <Input required
                              placeholder="Document Name"
                              style={{ width: "40%" }}
                              type="text"
                              value={item.label} name={`label${i}`}
                              onChange={e => this.onChangeNewFieldArray(e, i, "cancelCheckedDocumentArray")}
                            />
                            <select
                              required
                              style={{ width: "40%" }}
                              className="browser-default custom-select"
                              value={item.doc_type} name={`doc_type${i}`}
                              onChange={e => this.onChangeSelectDropdownDoc(e, i, "cancelCheckedDocumentArray")}
                            >
                              <option value="">Documents Type</option>
                              <option value="Original Document Required">Original Doc Required</option>
                              <option value="Original Document Not Required">Original Doc Not Required</option>
                            </select>
                            {this.state.cancelCheckedDocumentArray.length > 1 && (
                              <Input
                                style={{ width: "20%" }}
                                type="button"
                                value="Remove"
                                onClick={() => this.removeClick(i, "cancelCheckedDocumentArray")}
                              />
                            )}
                            {i === this.state.cancelCheckedDocumentArray.length - 1 && (
                              <Input
                                style={{ width: "30%" }}
                                type="button"
                                value="add more"
                                onClick={() => this.addClick("cancelCheckedDocumentArray")}
                              />
                            )}
                          </li>
                        );
                      })} */}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Fee Structure" key="3" disabled={fieldArrayBool}>
                  <Row gutter={16}>
                    <Col span={24} style={{ display: "none" }}>
                      <Checkbox checked={newChecked} onChange={e => this.onChange(e, "newChecked", "newFieldArray")}>
                        New
                    </Checkbox>
                      <Checkbox
                        checked={renewChecked}
                        onChange={e => this.onChange(e, "renewChecked", "renewalFieldArray")}
                      >
                        Renew
                    </Checkbox>
                      <Checkbox
                        checked={modifyChecked}
                        onChange={e => this.onChange(e, "modifyChecked", "modifyFieldArray")}
                      >
                        Modify
                    </Checkbox>
                      <Checkbox
                        checked={cancelChecked}
                        onChange={e => this.onChange(e, "cancelChecked", "cancelFieldArray")}
                      >
                        Cancel
                    </Checkbox>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <div>
                        {this.state.newFeeChargeArray.length !== 0 && (
                          <div className="requiredDocumnetMain feeStructureMain">
                            {this.state.newFeeChargeArray.length !== 0 && (
                              <h6>Add Fields For New Type Add Fee Charge</h6>
                            )}
                            {this.state.newFeeChargeArray.map((item, i) => {
                              return (
                                <div className="requiredDocumnetInner" key={i}>
                                  <div className="requiredDocumnetFlex">
                                    <div className="requiredDocumnetField">
                                      <Input
                                        placeholder="Fee Name"
                                        type="text"
                                        required
                                        value={item.label}
                                        name={`label${i}`}
                                        onChange={e =>
                                          this.onChangeNewFeeAddBaseCharge(
                                            e,
                                            i,
                                            "newFeeChargeArray",
                                            item.base && "newFeeAddBaseCharge",
                                            item.affiliate && "newFeeAddAffiliateCharge",
                                            "label"
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="requiredDocumnetField">
                                      <Input
                                        placeholder="Fee Price"
                                        type="number"
                                        required
                                        value={item.price}
                                        name={`price${i}`}
                                        onChange={e =>
                                          this.onChangeNewFeeAddBaseCharge(
                                            e,
                                            i,
                                            "newFeeChargeArray",
                                            item.base && "newFeeAddBaseCharge",
                                            item.affiliate && "newFeeAddAffiliateCharge",
                                            "price"
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="requiredDocumnetButton">
                                      <Checkbox

                                        checked={item.base}
                                        onChange={e => this.onChangeFeeCheckBox(e, i, "base", "newFeeChargeArray")}
                                      >
                                        Base
                            </Checkbox>
                                    </div>
                                    <div className="requiredDocumnetButton">
                                      <Checkbox

                                        checked={item.affiliate}
                                        onChange={e => this.onChangeFeeCheckBox(e, i, "affiliate", "newFeeChargeArray")}
                                      >
                                        Affiliate
                            </Checkbox>
                                    </div>
                                    {this.state.newFeeChargeArray.length > 1 && (
                                      <div className="requiredDocumnetButton">
                                        <Button

                                          onClick={() =>
                                            this.removeFeeClick(
                                              i,
                                              "newFeeChargeArray",
                                              "newFeeAddBaseCharge",
                                              "newFeeAddAffiliateCharge"
                                            )
                                          }
                                        >
                                          <CloseCircleOutlined />
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                  {i === this.state.newFeeChargeArray.length - 1 && (
                                    <Button
                                      onClick={() =>
                                        this.addFeeClick(
                                          "newFeeChargeArray",
                                          "newFeeAddBaseCharge",
                                          "newFeeAddAffiliateCharge"
                                        )
                                      }
                                    >
                                      Add More
                                    </Button>
                                  )}
                                </div>
                              );
                            })}

                          </div>
                        )}




                        {this.state.renewalFeeChargeArray.length !== 0 && (
                          <div className="requiredDocumnetMain feeStructureMain">
                            {this.state.renewalFeeChargeArray.length !== 0 && (
                              <h6>Add Fields For Renew Type Add Fee Charge</h6>
                            )}
                            {this.state.renewalFeeChargeArray.map((item, i) => {
                              return (
                                <div className="requiredDocumnetInner" key={i}>
                                  <div className="requiredDocumnetFlex">
                                    <div className="requiredDocumnetField">
                                      <Input
                                        placeholder="Fee Name"
                                        type="text"
                                        required
                                        value={item.label}
                                        name={`label${i}`}
                                        onChange={e =>
                                          this.onChangeNewFeeAddBaseCharge(
                                            e,
                                            i,
                                            "renewalFeeChargeArray",
                                            item.base && "renewalFeeAddBaseCharge",
                                            item.affiliate && "renewalFeeAddAffiliateCharge",
                                            "label"
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="requiredDocumnetField">
                                      <Input
                                        placeholder="Fee Price"
                                        type="number"
                                        required
                                        value={item.price}
                                        name={`price${i}`}
                                        onChange={e =>
                                          this.onChangeNewFeeAddBaseCharge(
                                            e,
                                            i,
                                            "renewalFeeChargeArray",
                                            item.base && "renewalFeeAddBaseCharge",
                                            item.affiliate && "renewalFeeAddAffiliateCharge",
                                            "price"
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="requiredDocumnetButton">
                                      <Checkbox

                                        checked={item.base}
                                        onChange={e => this.onChangeFeeCheckBox(e, i, "base", "renewalFeeChargeArray")}
                                      >
                                        Base
                            </Checkbox>
                                    </div>
                                    <div className="requiredDocumnetButton">
                                      <Checkbox
                                        checked={item.affiliate}
                                        onChange={e => this.onChangeFeeCheckBox(e, i, "affiliate", "renewalFeeChargeArray")}
                                      >
                                        Affiliate
                            </Checkbox>
                                    </div>
                                    {this.state.renewalFeeChargeArray.length > 1 && (
                                      <div className="requiredDocumnetButton">
                                        <Button
                                          onClick={() =>
                                            this.removeFeeClick(
                                              i,
                                              "renewalFeeChargeArray",
                                              "renewalFeeAddBaseCharge",
                                              "renewalFeeAddAffiliateCharge"
                                            )
                                          }
                                        >
                                          <CloseCircleOutlined />
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                  {i === this.state.renewalFeeChargeArray.length - 1 && (
                                    <Button
                                      onClick={() =>
                                        this.addFeeClick(
                                          "renewalFeeChargeArray",
                                          "renewalFeeAddBaseCharge",
                                          "renewalFeeAddAffiliateCharge"
                                        )
                                      }
                                    >
                                      Add More
                                    </Button>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}


                        {this.state.modifyFeeChargeArray.length !== 0 && (
                          <div className="requiredDocumnetMain feeStructureMain">
                            {this.state.modifyFeeChargeArray.length !== 0 && (
                              <h6>Add Fields For Modify Type Add Fee Charge</h6>
                            )}
                            {this.state.modifyFeeChargeArray.map((item, i) => {
                              return (
                                <div className="requiredDocumnetInner" key={i}>
                                  <div className="requiredDocumnetFlex">
                                    <div className="requiredDocumnetField">
                                      <Input
                                        placeholder="Fee Name"
                                        type="text"
                                        required
                                        value={item.label}
                                        name={`label${i}`}
                                        onChange={e =>
                                          this.onChangeNewFeeAddBaseCharge(
                                            e,
                                            i,
                                            "modifyFeeChargeArray",
                                            item.base && "modifyFeeAddBaseCharge",
                                            item.affiliate && "modifyFeeAddAffiliateCharge",
                                            "label"
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="requiredDocumnetField">
                                      <Input
                                        placeholder="Fee Price"
                                        type="number"
                                        required
                                        value={item.price}
                                        name={`price${i}`}
                                        onChange={e =>
                                          this.onChangeNewFeeAddBaseCharge(
                                            e,
                                            i,
                                            "modifyFeeChargeArray",
                                            item.base && "modifyFeeAddBaseCharge",
                                            item.affiliate && "modifyFeeAddAffiliateCharge",
                                            "price"
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="requiredDocumnetButton">
                                      <Checkbox
                                        checked={item.base}
                                        onChange={e => this.onChangeFeeCheckBox(e, i, "base", "modifyFeeChargeArray")}
                                      >
                                        Base
                            </Checkbox>
                                    </div>
                                    <div className="requiredDocumnetButton">
                                      <Checkbox
                                        checked={item.affiliate}
                                        onChange={e => this.onChangeFeeCheckBox(e, i, "affiliate", "modifyFeeChargeArray")}
                                      >
                                        Affiliate
                            </Checkbox>
                                    </div>
                                    {this.state.modifyFeeChargeArray.length > 1 && (
                                      <div className="requiredDocumnetButton">
                                        <Button
                                          onClick={() =>
                                            this.removeFeeClick(
                                              i,
                                              "modifyFeeChargeArray",
                                              "modifyFeeAddBaseCharge",
                                              "modifyFeeAddAffiliateCharge"
                                            )
                                          }
                                        >
                                          <CloseCircleOutlined />
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                  {i === this.state.modifyFeeChargeArray.length - 1 && (
                                    <Button
                                      onClick={() =>
                                        this.addFeeClick(
                                          "modifyFeeChargeArray",
                                          "modifyFeeAddBaseCharge",
                                          "modifyFeeAddAffiliateCharge"
                                        )
                                      }
                                    >
                                      Add More
                                    </Button>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}

                        {this.state.cancelFeeChargeArray.length !== 0 && (
                          <div className="requiredDocumnetMain feeStructureMain">
                            {this.state.cancelFeeChargeArray.length !== 0 && (
                              <h6>Add Fields For Cancel Type Add Fee Charge</h6>
                            )}
                            {this.state.cancelFeeChargeArray.map((item, i) => {
                              return (
                                <div className="requiredDocumnetInner" key={i}>
                                  <div className="requiredDocumnetFlex">
                                    <div className="requiredDocumnetField">
                                      <Input
                                        placeholder="Fee Name"
                                        type="text"
                                        required
                                        value={item.label}
                                        name={`label${i}`}
                                        onChange={e =>
                                          this.onChangeNewFeeAddBaseCharge(
                                            e,
                                            i,
                                            "cancelFeeChargeArray",
                                            item.base && "cancelFeeAddBaseCharge",
                                            item.affiliate && "cancelFeeAddAffiliateCharge",
                                            "label"
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="requiredDocumnetField">
                                      <Input
                                        placeholder="Fee Price"
                                        type="number"
                                        required
                                        value={item.price}
                                        name={`price${i}`}
                                        onChange={e =>
                                          this.onChangeNewFeeAddBaseCharge(
                                            e,
                                            i,
                                            "cancelFeeChargeArray",
                                            item.base && "cancelFeeAddBaseCharge",
                                            item.affiliate && "cancelFeeAddAffiliateCharge",
                                            "price"
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="requiredDocumnetButton">
                                      <Checkbox
                                        checked={item.base}
                                        onChange={e => this.onChangeFeeCheckBox(e, i, "base", "cancelFeeChargeArray")}
                                      >
                                        Base
                            </Checkbox>
                                    </div>
                                    <div className="requiredDocumnetButton">
                                      <Checkbox
                                        checked={item.affiliate}
                                        onChange={e => this.onChangeFeeCheckBox(e, i, "affiliate", "cancelFeeChargeArray")}
                                      >
                                        Affiliate
                            </Checkbox>
                                    </div>
                                    {this.state.cancelFeeChargeArray.length > 1 && (
                                      <div className="requiredDocumnetButton">
                                        <Button
                                          onClick={() =>
                                            this.removeFeeClick(
                                              i,
                                              "cancelFeeChargeArray",
                                              "cancelFeeAddBaseCharge",
                                              "cancelFeeAddAffiliateCharge"
                                            )
                                          }
                                        >
                                          <CloseCircleOutlined />
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                  {i === this.state.cancelFeeChargeArray.length - 1 && (
                                    <Button
                                      onClick={() =>
                                        this.addFeeClick(
                                          "cancelFeeChargeArray",
                                          "cancelFeeAddBaseCharge",
                                          "cancelFeeAddAffiliateCharge"
                                        )
                                      }
                                    >
                                      Add More
                                    </Button>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Button onClick={this.handleSubmit} disabled={this.state.disabled} style={{ width: "20%" }} type="primary" htmlType="submit">
                    Submit 
              </Button>
                </TabPane>
              </Tabs>
            </Spin>
          </Form>
        </div>
      </div>
    );
  }
}
export default ModifyTabs;