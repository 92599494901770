import React, { PureComponent } from 'react'
import TopNavigation from './../TopNavigation/component'
import SidebarNavigation from './../SidebarNavigation/component'
import { Form, DatePicker, message, Button, Select } from 'antd';
import "./styles.scss";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default class EditContentPagesComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      page_name: '',
      heading: '',
      description: '',
      page_type: '',
      err: {},
      message: '',
      disable: true,
      messageerr: '',
      disabled : false,
      cms_id: ''
    }
    this.handleChange = this.handleChange.bind(this)
    this.EditContentPages = this.EditContentPages.bind(this)

  }

  handleChange(e) {
    let disable = false
    const self = this
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (self.state.page_name === '' || self.state.page_name === undefined) {
        disable = true
      }
      if (self.state.heading === '' || self.state.heading === undefined) {
        disable = true
      }
      if (self.state.description === '' || self.state.description === undefined) {
        disable = true
      }
      if (self.state.page_type === '' || self.state.page_type === undefined) {
        disable = true
      }
      self.setState({ disable })
    })
  }

  componentDidMount() {
    document.title = 'Content Page | DBC'
    const id = this.props.match.params.id;
    // console.log(id, 'hgffgh')
    this.getCmsContent(id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.setState({ user: null });
      this.getCmsContent(this.props.match.params.id)
    }
  }

  async getCmsContent(uid) {
    const Data = await this.props.getCmsContent({
      page_type: uid
    });
    if (Data.value.status === 200) {
      // console.log(Data.value.data)
      this.setState({
        page_name: Data.value.data.page_name,
        heading: Data.value.data.heading,
        description: Data.value.data.description,
        page_type: Data.value.data.page_type,
        cms_id: Data.value.data._id
      })
    }
  }


  EditContentPages = async () => {


    const { cms_id, page_name, heading, description, page_type } = this.state

    /* eslint-disable */
    const err = {}


    if (heading === '' || heading === null || heading.trim() === '') {
      err.heading = 'Page header is required.'
    }

    if (description === '' || description === null || description.trim() === '') {
      err.description = 'Description is required.'
    }

    // if (page_type === '' || page_type === null || page_type.trim() === '') {
    //   err.page_type = 'Promo Type is required.'
    // }


    this.setState({ err })
    if (!Object.keys(err).length) {
      this.setState({disabled: true});
      if (heading && description) {
        const EditContentPagesData = {
          heading: heading,
          description: description,
          page_type: page_type,
          page_name: page_name,
          cms_id: cms_id,
        }


        const resData = await this.props.UpdateCmsContent(EditContentPagesData);

        if (resData.value.status === 200) {
          //added successfully
          toast.success("Updated successfully", {
            position: toast.POSITION.TOP_CENTER
          });
          setTimeout(() => {
            // console.log('Hello, World!')
            this.getCmsContent(page_type);
          }, 3000);
        } else if (resData.value.status === 401) {
          localStorage.clear();
          this.props.history.push('/login');
        } else {
          // some error in server
          toast.error("Some error occured please try again", {
            position: toast.POSITION.TOP_CENTER
          });
          setTimeout(() => {
            this.setState({disabled: false});
          }, 3000);
        }

      } else {
        message.error('Please fill all the required fields')
      }
    }
  }


  render() {
    const { heading, description } = this.state
    const { Option } = Select;
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content affiliates_wrapper">
          <div>
            <span><Link to="#">Content Pages/</Link>Update Content Page</span>
          </div>

          <div className="clearBoth"></div>

          {this.state.messageerr ?
            <span className="invalid-feedback">
              {this.state.messageerr}
            </span>
            : ''
          }

          <div className="affMainData">
            <div className="content">
              <div className="form-group">
                <input type="text" placeholder="Page Heading" className="form-control" name="heading"
                  value={heading}
                  onChange={this.handleChange.bind(this)}
                  required />
                {this.state.err.heading ?
                  <span className="invalid-feedback">
                    {this.state.err.heading}
                  </span>
                  : ''
                }
              </div>
              <CKEditor
                editor={ClassicEditor}
                data={description}
                onInit={editor => {
                  // You can store the "editor" and use when it is needed.
                  // console.log('Editor is ready to use!', editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  this.setState({ description: data });
                  // console.log({ data });
                }}
                onBlur={(event, editor) => {
                  // console.log('Blur.', editor);
                }}
                onFocus={(event, editor) => {
                  // console.log('Focus.', editor);
                }}
              />
              {this.state.err.description ?
                <span className="invalid-feedback">
                  {this.state.err.description}
                </span>
                : ''
              }

              {/* <div className="form-group">
                <input type="textarea" placeholder="Page Description" className="form-control" name="description"
                    value={this.state.description}
                    onChange={this.handleChange.bind(this)} 
                    required/>
                     { this.state.err.description ?
                      <span className="invalid-feedback">
                        {this.state.err.description}
                      </span>
                      : ''
                    }
              </div> */}



            </div>
          </div>

          <div>
            <input type="submit" disabled={this.state.disabled} onClick={this.EditContentPages} value={this.state.disabled ? 'Updated...' : 'Update'} className="marginleft ant-btn btnn ant-btn-primary floatLeft" />
          </div>
        </main>
        <ToastContainer />

      </div>
    );
  }
}

