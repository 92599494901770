import React, { PureComponent } from 'react'
import TopNavigation from './../TopNavigation/component'
import SidebarNavigation from './../SidebarNavigation/component'
import { Link } from 'react-router-dom'
import Pagination from "react-js-pagination";
import moment from 'moment';
import { Spin, Modal, message } from "antd";
import './styles.scss'
import { DeleteOutlined } from '@ant-design/icons';


export default class PromocodeComponent extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      all_Promocodes: [],
      search_value: '',
      activePage: 1,
      itemsCountPerPage: 10,
      totalItemsCount: 0,
      pageRangeDisplayed: 5,
      link_path: 'https://dbcapi.webvilleedemo.xyz',
      alert: null,
      loading:false,
      visible: false,
      onCancel: false,
      delete_promo_id: ''
    }

    // this.search = this.search.bind(this)
    // this.handlePageChange = this.handlePageChange.bind(this)
  }

  handlePageChange=(pageNumber)=> {
    this.setState({activePage: pageNumber}, () => {
      this.getAllPromoCode()
    });
  }

  componentDidMount() {
    document.title = 'Promocodes | DBC'

    this.getAllPromoCode(); 
  }

  getAllPromoCode= async()=> {
    this.setState({loading:true});
    const postData = {
      search_value: this.state.search_value,
      page_no: this.state.activePage,
      //role: 2
    }

    const resData = await this.props.getAllPromoCode(postData)
    if(resData.value.status === 200){

      this.setState({all_Promocodes:resData.value.data.promo_data,totalItemsCount:resData.value.data.count})
      this.setState({loading:false})
    }else if (resData.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/login');
    }else{
      this.setState({loading:false})
    }
  }

  showDeleteModal = (promoId) => {

    this.setState({
      delete_promo_id: promoId,
    });

    this.setState({
      visible: true,
    });
  };

  handlePopupCancel = () => {
    this.setState({
      delete_promo_id: '',
    });
    this.setState({ visible: false });
  };

  onDelete = async() => {

    const postData = {
      promo_code_id: this.state.delete_promo_id,
    }

    const datasuccess = await this.props.deletePromocode(postData);
    if (datasuccess.value.status === 200) {
      message.success(datasuccess.value.msg);
      setTimeout(() => {
          this.getAllPromoCode(); 
          this.setState({ loading: false, visible: false });
      }, 1000);
    }else if (datasuccess.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/login');
    } else {
      this.setState({ loading: false});
      message.error(datasuccess.value.msg);
    }
  }


  // search=(event) => {
  //   this.setState({search_value:event.target.value, activePage: 1}, () => {
  //     this.getAllPromoCode()
  //   });
  // }

  render() {
    const { all_Promocodes, loading, visible } = this.state
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation />
        <TopNavigation {...this.props} />
        <Spin spinning={loading}>
        <main className="dashboard-layout-content affiliates_wrapper">
          <div>
            <span><Link to="/dashboard">Home</Link>/Promocodes</span>
            <span className="floatRight"><Link to="/add-promocode"><button type="button" className="ant-btn btnn ant-btn-primary">Add New Promocode</button></Link></span>
          </div>

          <div className="clearBoth"></div>

          <div className="affMainData">
            <div className="content">
              <div className="page-header d-flex justify-content-between align-items-center">
                <h6 className="heading-title">Promocodes</h6>
                <span className="md-form active-cyan-2 mb-3 searchInput">
                  {/* <input className="form-control" type="text" placeholder="Search" aria-label="Search" onKeyUp={this.search}/> */}
                </span>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">PROMOCODE</th>
                      <th scope="col">FROM DATE</th>
                      <th scope="col">TO DATE</th>
                      <th scope="col">PROMO TYPE</th>
                      <th scope="col">PROMOCODE TYPE</th>
                      <th scope="col">VIEW</th>
                      <th scope="col">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {all_Promocodes.length>0 && all_Promocodes.map((item, index) =>
                      <tr key={ index }>
                        <th scope="row">{item.promo_code}</th>
                        <td>{moment(item.from_date).format("YYYY-MM-DD")}</td>
                        <td>{moment(item.to_date).format("YYYY-MM-DD")}</td>
                        <td>{(item.promo_type===1)? "AED": (item.promo_type===2)? "%" :'' }</td>
                        <td>{item.amount}</td>
                        {/* <td><a target="_blank" href={`${this.state.link_path}/Promocode-login/${item._id}`}>{`${this.state.link_path}/Promocode-login/${item._id}`}</a></td>
                        <td>{item.status === 1 ? 'ACTIVE' : item.status === 2 ? 'DELETED' : 'PENDING'}</td> */}
                        <td><Link to={{ pathname: '/edit-promocode/'+item._id }}><button type="button" className="ant-btn ant-btn-primary">Edit</button></Link></td>
                        <td><DeleteOutlined onClick={() => this.showDeleteModal(item._id)} /></td>
                      </tr>
                    ).reverse()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          { this.state.totalItemsCount > 0 ?
            <div itemClass="page-item">
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.itemsCountPerPage}
                totalItemsCount={this.state.totalItemsCount}
                pageRangeDisplayed={this.state.pageRangeDisplayed}
                onChange={this.handlePageChange}
              />
            </div> : ''
          }



         
         <Modal
          visible={visible}
          title="Delete Promocode"
          okText="Delete"
          cancelText="Cancel"
          onCancel={this.handlePopupCancel}
          onOk={this.onDelete}
          >

          <div> <h5>Are you sure you want to delete this promocode?</h5> </div>
      
         </Modal> 

        </main></Spin>
      </div>
    )
  }
}
