import { fetch } from '../../utils'
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME ? process.env.REACT_APP_API_HOSTNAME : 'https://dbcapi.webvilleedemo.xyz'

export const showDashboard = () => {
  return fetch(`${HOSTNAME}/admin/show_dashbord_counters`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const barchartCounter = () => {
  return fetch(`${HOSTNAME}/admin/show_dashbord_bar_chrt_counter`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const serviceReqCounter = (data) => {
  return fetch(`${HOSTNAME}/admin/show_dashbord_service_req_counters`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const showAnalysisCounter = (data) => {
  return fetch(`${HOSTNAME}/admin/show_payment_analysis_counter`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}