import React, { PureComponent } from 'react'
import TopNavigation from './../TopNavigation/component'
import SidebarNavigation from './../SidebarNavigation/component'
import Createtabs from "./partials/create-tabs";
// import PropTypes from 'prop-types'
import './styles.scss'
import { Button, Modal, Form, Row, Col, Input, message } from 'antd';
const { TextArea } = Input;
export default class ServicesComponent extends PureComponent {

  static propTypes = {
    // PropTypes go here
  }
  state = {
    loading: false,
    visible: false,
    onCancel: false,
    center_name:'',
    err: {},
    disable:false,
    center_location: ''
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  onCreate = async() => {
    this.setState({ loading: true });
    const { center_name, center_location } = this.state
    const err = {};
    if (center_name === '' || center_name.trim() === '') {
      err.center_name = 'Center name is required.'
    }
    if (center_location === '' || center_location.trim() === '') {
      err.center_location = 'Center location is required.'
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
      if (center_name && center_location) {
        const centerData = {
          center_name: center_name,
          center_location_address: center_location,
          center_lat:'22.667089',
          center_lng: '75.825432'
        }
      const datasuccess = await this.props.createCenter(centerData);
      if (datasuccess.value.status === 200) {
        message.success(datasuccess.value.msg);
        setTimeout(() => {
            this.setState({ loading: false, visible: false });
        }, 1000);
      }else if (datasuccess.value.status === 401) {
        localStorage.clear();
        this.props.history.push('/login');
      }else {
        message.error(datasuccess.value.msg);
      }
    } else {
      message.error("Please insert all Fields");
    }
  }
    
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleChange (e) {
    let disable = false
    const self = this
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (self.state.center_name === '' || self.state.center_name === undefined) {
        disable = true
      }
      if (self.state.center_location === '' || self.state.center_location === undefined) {
        disable = true
      }
      self.setState({ disable })
    })
   }


  render() {
    const {visible, center_name, err,disable, center_location} = this.state;
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content reports__wrapper">
          <div className="page-header d-flex justify-content-between align-items-center">
            <h3 className="heading-title">Add Services</h3>
            <div className="form-group material-textfield">
              <Button className="btnn" type="primary" onClick={this.showModal} >Add Center</Button>
              {/* <Button className="btnn" type="primary" >Add Service</Button> */}
            </div>
          </div>

          <Createtabs
            // isSubmitting={isSubmitting}
            // showCustomers={showCustomersF}
            // values={values}
            // handleChange={handleChange}
            // customerName={customerName}
            {...this.props}
          />

<Modal
      visible={visible}
      title="Create a new Center"
      okText="Create"
      cancelText="Cancel"
      onCancel={this.handleCancel}
      onOk={this.onCreate}
    >
      <Form>
      <div className="form-group"> <Input
          placeholder="Center name"
          required
          name="center_name"
          type="text"
          value={center_name}
          onChange={this.handleChange.bind(this)}
          />
          { err.center_name ?
                    <span className="invalid-feedback">
                      {err.center_name}
                    </span>
                    : ''
                  }
</div> <div className="form-group">
         <TextArea
          placeholder="Center location"
          required
          name="center_location"
          type="text"
          value={center_location}
          onChange={this.handleChange.bind(this)}
          />
          { err.center_location ?
                    <span className="invalid-feedback">
                      {err.center_location}
                    </span>
                    : ''
                  }
      </div></Form>
    </Modal>


        </main>
      </div>
    )
  }
}
