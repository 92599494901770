import React, { PureComponent } from 'react'
import TopNavigation from './../TopNavigation/component'
import SidebarNavigation from './../SidebarNavigation/component'
import { Link } from 'react-router-dom'
import Pagination from "react-js-pagination";
import { Spin, Modal, message } from "antd";
import './styles.scss'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ToastContainer, toast } from 'react-toastify';

export default class AffiliateComponent extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      all_affiliates: [],
      search_value: '',
      activePage: 1,
      itemsCountPerPage: 10,
      totalItemsCount: 0,
      pageRangeDisplayed: 5,
      link_path: 'https://dbcapi.webvilleedemo.xyz',
      alert: null,
      loading:false,
      visible: false,
      onCancel: false,
      user_id:''
    }

    this.search = this.search.bind(this)
    // this.handlePageChange = this.handlePageChange.bind(this)
  }

  handlePageChange=(pageNumber)=> {
    this.setState({activePage: pageNumber}, () => {
      this.getAllAffiliates()
    });
  }

  componentDidMount() {
    document.title = 'Affiliates | DBC'

    this.getAllAffiliates(); 
  }

  getAllAffiliates= async()=> {
    this.setState({loading:true});
    const postData = {
      search_value: this.state.search_value,
      page_no: this.state.activePage,
      role: 2
    }

    const resData = await this.props.getAllAffiliates(postData)

    if(resData.value.status === 200){

      this.setState({all_affiliates:resData.value.data.User_data,totalItemsCount:resData.value.data.User_count})
      this.setState({loading:false})
    }else if (resData.value.status === 401) {
      this.setState({loading:false})
      localStorage.clear();
      this.props.history.push('/login');
    }else{
      this.setState({loading:false})
    }
  }

  search=(event) => {
    this.setState({search_value:event.target.value,  activePage: 1}, () => {
      this.getAllAffiliates()
    });
  }


  showDeleteModal = (userId) => {

    this.setState({
      user_id: userId,
    });

    this.setState({
      visible: true,
    });
  };

  handlePopupCancel = () => {
    this.setState({
      user_id: '',
    });
    this.setState({ visible: false });
  };

  onDelete = async() => {

    const postData = {
      user_id: this.state.user_id,
    }

    const datasuccess = await this.props.deleteAffiliate(postData);
    if (datasuccess.value.status === 200) {
      toast.success(datasuccess.value.msg, {
        position: toast.POSITION.TOP_CENTER
      });
      setTimeout(() => {
          this.getAllAffiliates(); 
          this.setState({ loading: false, visible: false });
      }, 1000);
    } else if (datasuccess.value.status === 401) {
     
      localStorage.clear();
      this.props.history.push('/login');
    }else {
      this.setState({ loading: false});
      toast.error(datasuccess.value.msg, {
        position: toast.POSITION.TOP_CENTER
      });
      // message.error(datasuccess.value.msg);
    }
  }


  render() {
    const { all_affiliates, loading, visible } = this.state
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation />
        <TopNavigation {...this.props} />
        <Spin spinning={loading}>
        <main className="dashboard-layout-content affiliates_wrapper">
          <div>
            <span><Link to="/dashboard">Home</Link>/Affiliates</span>
            <span className="floatRight"><Link to="/add-affiliate"><button type="button" className="ant-btn btnn ant-btn-primary">Add New Affiliate</button></Link></span>
          </div>

          <div className="clearBoth"></div>

          <div className="affMainData">
            <div className="content">
              <div className="page-header d-flex justify-content-between align-items-center">
                <h6 className="heading-title">Affiliates</h6>
                <span className="md-form active-cyan-2 mb-3 searchInput">
                  <input className="form-control" type="text" placeholder="Search" aria-label="Search" onKeyUp={this.search}/>
                </span>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">NAME</th>
                      <th scope="col">EMAIL</th>
                      <th scope="col">MOBILE NUMBER</th>
                      <th scope="col">LINK</th>
                      <th scope="col">STATUS</th>
                      <th scope="col">VIEW</th>
                      <th scope="col">DELETE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {all_affiliates.length>0 && all_affiliates.map((item, index) =>
                      <tr key={ index }>
                        <th scope="row">{item.user_name}</th>
                        <td>{item.user_email}</td>
                        <td>{item.user_contact}</td>
                        <td><a target="_blank" href={`${this.state.link_path}/affiliate-login/${item._id}`}>{`${this.state.link_path}/affiliate-login/${item._id}`}</a></td>
                        <td>{item.status === 1 ? 'ACTIVE' : item.status === 2 ? 'DELETED' : 'PENDING'}</td>
                        <td><Link to={{ pathname: '/edit-affiliate/'+item._id }}><EditOutlined /></Link></td>
                        <td><DeleteOutlined onClick={() => this.showDeleteModal(item._id)} /></td>
                      </tr>
                    ).reverse()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          { this.state.totalItemsCount > 0 ?
            <div itemClass="page-item">
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.itemsCountPerPage}
                totalItemsCount={this.state.totalItemsCount}
                pageRangeDisplayed={this.state.pageRangeDisplayed}
                onChange={this.handlePageChange}
              />
            </div> : ''
          }

         <Modal
          visible={visible}
          title="Delete Affiliate"
          okText="Delete"
          cancelText="Cancel"
          onCancel={this.handlePopupCancel}
          onOk={this.onDelete}
          >

          <div> <h5>Are you sure you want to delete this affiliate?</h5> </div>
      
         </Modal> 

        </main></Spin>
        <ToastContainer />
      </div>
    )
  }
}
