import { connect } from 'react-redux'
import AnalysisComponent from './component'
import { getMe, getAllUnreadMessage } from '../../store/user/duck'
import { showDashboard, barchartCounter, showAnalysisCounter } from '../../store/charts/duck'
const AnalysisContainer = connect(
  // Map state to props
  state => ({
  	user: state.user.user,
  	userPhase: state.user.userPhase
  }),
  // Map actions to dispatch and props
  {
  	getMe,
    getAllUnreadMessage,
    showDashboard,
    barchartCounter,
    showAnalysisCounter
  }
)(AnalysisComponent)

export default AnalysisContainer
