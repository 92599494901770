import { connect } from 'react-redux'
import AddAffiliateComponent from './component'
import { getMe, getAllUnreadMessage } from '../../store/user/duck'
import {  addAffiliate, getAffiliateDetails,updateAffiliate } from '../../store/affiliate/duck'

const AddAffiliateContainer = connect(
  // Map state to props
  state => ({
    message: state.user.message,
    user: state.user.user,
    addAffiliatePhase: state.user.addAffiliatePhase,
    updateAffiliatePhase: state.user.updateAffiliatePhase
  }),
  {
    addAffiliate,
    getAffiliateDetails,
    updateAffiliate,
    getMe,
    getAllUnreadMessage
  }
  // Map actions to dispatch and props
)(AddAffiliateComponent)

export default AddAffiliateContainer
