import { connect } from 'react-redux'
import AddPromoCodeComponent from './component'
import { getMe, getAllUnreadMessage } from '../../store/user/duck'
import {  addPromoCode } from '../../store/promocode/duck'

const addPromoCodeContainer = connect(
  // Map state to props
  state => ({
  }),
  {
    addPromoCode,
    getMe,
    getAllUnreadMessage
  }
  // Map actions to dispatch and props
)(AddPromoCodeComponent)

export default addPromoCodeContainer
