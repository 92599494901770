import { connect } from 'react-redux'
import UsersComponent from './component'
import { getMe, getAllUnreadMessage } from '../../store/user/duck'
import { getAllUsers, deleteCustomer } from '../../store/users/duck'


const UsersContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase,
  }),
  {
    getAllUsers,
    deleteCustomer,
    getMe,
    getAllUnreadMessage
  }
  // Map actions to dispatch and props
)(UsersComponent)

export default UsersContainer
