import React, { PureComponent } from 'react'
import TopNavigation from './../TopNavigation/component'
import SidebarNavigation from './../SidebarNavigation/component'
import { Link } from 'react-router-dom'
import Pagination from "react-js-pagination";
import { Button, Modal, Form, Row, Col, Input, message, Spin } from 'antd';
import './styles.scss'
import { DeleteOutlined } from '@ant-design/icons';


export default class CentersComponent extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      all_Centers: [],
      search_value: '',
      activePage: 1,
      itemsCountPerPage: 10,
      totalItemsCount: 0,
      pageRangeDisplayed: 5,
      link_path: 'https://dbcapi.webvilleedemo.xyz',
      alert: null,
      visible: false,
      onCancel: false,
      center_name:'',
      err: {},
      disable:false,
      center_location: '',
      delete_popup_visible: false,
      center_id:'',
      update_popup_visible: false,
      update_center_id:'',
      update_center_name:'',
      update_center_location:'',
      disabled : false,
    }

    this.search = this.search.bind(this)
    // this.handlePageChange = this.handlePageChange.bind(this)
  }

  handlePageChange=(pageNumber)=> {
    this.setState({activePage: pageNumber}, () => {
      this.getAllCenters()
    });
  }

  componentDidMount() {
    document.title = 'Centers | DBC'

    this.getAllCenters(); 
  }

  getAllCenters= async()=> {
    this.setState({loading:true});
    const postData = {
      search_value: this.state.search_value,
      page_no: this.state.activePage,
    }

    const resData = await this.props.fetchAllCenters(postData)

    if(resData.value.status === 200){
      this.setState({all_Centers:resData.value.data})
      this.setState({loading:false})
    }else if (resData.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/login');
    }else{
      this.setState({loading:false})
    }
  }

  search=(event) => {
    this.setState({search_value:event.target.value, activePage: 1}, () => {
      this.getAllCenters()
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  onCreate = async() => {
    this.setState({ loading: true });
    const { center_name, center_location } = this.state
    const err = {};
    if (center_name === '' || center_name.trim() === '') {
      err.center_name = 'Center name is required.'
    }
    if (center_location === '' || center_location.trim() === '') {
      err.center_location = 'Center location is required.'
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
      if (center_name && center_location) {
        const centerData = {
          center_name: center_name,
          center_location_address: center_location,
          center_lat:'22.667089',
          center_lng: '75.825432'
        }
      const datasuccess = await this.props.createCenter(centerData);
      if (datasuccess.value.status === 200) {
        message.success(datasuccess.value.msg);
        setTimeout(() => {
            this.getAllCenters(); 
            this.setState({ loading: false, visible: false });
        }, 1000);
      } else if (datasuccess.value.status === 401) {
        localStorage.clear();
        this.props.history.push('/login');
      }else {
        message.error(datasuccess.value.msg);
        this.setState({ loading: false });
      }
    } else {
      message.error("Please insert all Fields");
      this.setState({ loading: false });
    }
  }else{
    message.error("Please insert all Fields");
    this.setState({ loading: false });
  }
    
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleChanged (e) {
    let disable = false
    const self = this
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (self.state.center_name === '' || self.state.center_name === undefined) {
        disable = true
      }
      if (self.state.center_location === '' || self.state.center_location === undefined) {
        disable = true
      }
      self.setState({ disable })
    })
   }

   handleUpdateChanged (e) {
    let disable = false
    const self = this
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (self.state.update_center_name === '' || self.state.update_center_name === undefined) {
        disable = true
      }
      if (self.state.update_center_location === '' || self.state.update_center_location === undefined) {
        disable = true
      }
      self.setState({ disable })
    })
   }

  showDeleteModal = (centerId) => {

    this.setState({
      center_id: centerId,
    });

    this.setState({
      delete_popup_visible: true,
    });
  };

  handleDeletePopupCancel = () => {
    this.setState({
      center_id: '',
    });
    this.setState({ delete_popup_visible: false });
  };

  onDelete = async() => {

    const postData = {
      center_id: this.state.center_id,
    }

    const datasuccess = await this.props.deleteCenter(postData);
    if (datasuccess.value.status === 200) {
      message.success(datasuccess.value.msg);
      setTimeout(() => {
          this.getAllCenters(); 
          this.setState({ loading: false, delete_popup_visible: false });
      }, 1000);
    } else if (datasuccess.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/login');
    } else {
      this.setState({ loading: false});
      message.error(datasuccess.value.msg);
    }
  }

  showUpdateModal = (centerDetails) => {

    this.setState({
      update_center_id: centerDetails._id, update_center_name: centerDetails.center_name, update_center_location: centerDetails.center_location_address,
    }); 


    this.setState({
      update_popup_visible: true,
    });
  };

  handleUpdatePopupCancel = () => {

    this.setState({
      update_center_id: '', update_center_name: '', update_center_location: '',
    }); 

    this.setState({ update_popup_visible: false });
  };

  onUpdate = async() => {
    this.setState({ loading: true });
    const { update_center_id, update_center_name, update_center_location } = this.state
    const err = {};
    if (update_center_name === '' || update_center_name.trim() === '') {
      err.update_center_name = 'Center name is required.'
    }
    if (update_center_location === '' || update_center_location.trim() === '') {
      err.update_center_location = 'Center location is required.'
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
      if (update_center_id && update_center_name && update_center_location) {
        const centerData = {
          center_id: update_center_id,
          center_name: update_center_name,
          center_location_address: update_center_location,
          center_lat:'22.667089',
          center_lng: '75.825432'
        }
        const datasuccess = await this.props.updateCenter(centerData);
        if (datasuccess.value.status === 200) {
          message.success(datasuccess.value.msg);
          setTimeout(() => {
              this.getAllCenters(); 
              this.setState({ loading: false, update_popup_visible: false });
          }, 1000);
        }  else if (datasuccess.value.status === 401) {
          localStorage.clear();
          this.props.history.push('/login');
        }else {
          this.setState({ loading: false});
          message.error(datasuccess.value.msg);
        }
      } else {
        message.error("Please insert all Fields");
        this.setState({ loading: false});
      }
    } else {
      message.error("Please insert all Fields");
      this.setState({ loading: false});
    }
    
  };


  render() {
    const { all_Centers, loading, visible, center_name, err,disable, center_location, delete_popup_visible, update_popup_visible, update_center_name, update_center_location } = this.state
    const { TextArea } = Input
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation />
        <TopNavigation {...this.props} />
        <Spin spinning={loading}>
        <main className="dashboard-layout-content affiliates_wrapper">
          <div>
            <span><Link to="/dashboard">Home</Link>/Centers</span>
            <span className="floatRight"> <Button className="btnn" type="primary" onClick={this.showModal} >Add Center</Button></span>
          </div>

          <div className="clearBoth"></div>

          <div className="affMainData">
            <div className="content">
              <div className="page-header d-flex justify-content-between align-items-center">
                <h6 className="heading-title">Centers</h6>
                <span className="md-form active-cyan-2 mb-3 searchInput">
                  <input className="form-control" type="text" placeholder="Search" aria-label="Search" onKeyUp={this.search}/>
                </span>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">CENTER NAME</th>
                      <th scope="col">CENTER LOCATION</th>
                      <th scope="col">CENTER LATITUDE</th>
                      <th scope="col">CENTER LANGTITUDE</th>
                      <th scope="col">VIEW</th>
                      <th scope="col">DELETE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {all_Centers.length>0 && all_Centers.map((item, index) =>
                      <tr key={ index }>
                        <th scope="row">{item.center_name}</th>
                        <td>{item.center_location_address}</td>
                        <td>{item.center_lat}</td>
                        <td>{item.center_lng}</td>
                        <td><button type="button" className="ant-btn ant-btn-primary" onClick={() => this.showUpdateModal(item)}>Edit</button></td>
                        <td><DeleteOutlined onClick={() => this.showDeleteModal(item._id)} /></td>
                      </tr>
                    ).reverse()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          { this.state.totalItemsCount > 0 ?
            <div itemClass="page-item">
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.itemsCountPerPage}
                totalItemsCount={this.state.totalItemsCount}
                pageRangeDisplayed={this.state.pageRangeDisplayed}
                onChange={this.handlePageChange}
              />
            </div> : ''
          }

          <Modal
      visible={visible}
      title="Create a new Center"
      okText="Create"
      cancelText="Cancel"
      onCancel={this.handleCancel}
      onOk={this.onCreate}
    >
      <Form>
      <div className="form-group"> <Input
          placeholder="Center name"
          required
          name="center_name"
          type="text"
          value={center_name}
          onChange={this.handleChanged.bind(this)}
          />
          { err.center_name ?
                    <span className="invalid-feedback">
                      {err.center_name}
                    </span>
                    : ''
                  }
</div> <div className="form-group">
         <TextArea
          placeholder="Center location"
          required
          name="center_location"
          type="text"
          value={center_location}
          onChange={this.handleChanged.bind(this)}
          />
          { err.center_location ?
                    <span className="invalid-feedback">
                      {err.center_location}
                    </span>
                    : ''
                  }
      </div></Form>
    </Modal>

      <Modal
          visible={delete_popup_visible}
          title="Delete Center"
          okText="Delete"
          cancelText="Cancel"
          onCancel={this.handleDeletePopupCancel}
          onOk={this.onDelete}
          >

          <div> <h5>Are you sure you want to delete this center?</h5> </div>
      
         </Modal> 


          <Modal
            visible={update_popup_visible}
            title="Edit Center"
            okText="Update"
            cancelText="Cancel"
            onCancel={this.handleUpdatePopupCancel}
            onOk={this.onUpdate}
          >
            <Form>
            <div className="form-group"> <Input
                placeholder="Center name"
                required
                name="update_center_name"
                type="text"
                value={update_center_name}
                onChange={this.handleUpdateChanged.bind(this)}
                />
                { err.update_center_name ?
                          <span className="invalid-feedback">
                            {err.update_center_name}
                          </span>
                          : ''
                        }
      </div> <div className="form-group">
               <TextArea
                placeholder="Center location"
                required
                name="update_center_location"
                type="text"
                value={update_center_location}
                onChange={this.handleUpdateChanged.bind(this)}
                />
                { err.update_center_location ?
                          <span className="invalid-feedback">
                            {err.update_center_location}
                          </span>
                          : ''
                        }
            </div></Form>
          </Modal>

        </main></Spin>
      </div>
    )
  }
}
