import React, { PureComponent } from 'react'
import TopNavigation from './../TopNavigation/component'
import SidebarNavigation from './../SidebarNavigation/component'
import { Bar } from 'react-chartjs-2';
import Pagination from "react-js-pagination";
import { Row, Col, Card, Spin } from 'antd'
import './styles.scss'
export default class DashboardComponent extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      itemsCountPerPage: 10,
      totalItemsCount: 0,
      pageRangeDisplayed: 5,
      link_path: 'https://dbcapi.webvilleedemo.xyz',
      alert: null,
      loading: false,
      user_id: '',
      showTotal: {},
      barChartcounter: [],
      serviceReqCounter: [],
      activePage: 1,
    }
  }

  async componentDidMount() {
    document.title = 'Dashboard'
    this.setState({ loading: true });
    const dataReq = await this.props.showDashboard();
    if (dataReq.value.status === 200) {
      this.setState({ showTotal: dataReq.value.data, loading: false })
    } else if (dataReq.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/login');
    }

    const databarChart = await this.props.barchartCounter();
    if (databarChart.value.status === 200) {
      this.setState({ barChartcounter: databarChart.value.data, loading: false })
    } else if (databarChart.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/login');
    }

    this.getAllserviceReqCounter();
  }

  async getAllserviceReqCounter() {
    this.setState({ loading: true });
    const ReqCounter = await this.props.serviceReqCounter({ page_no: this.state.activePage });
    if (ReqCounter.value.status === 200) {
      this.setState({ ReqCounter: ReqCounter.value.data, totalItemsCount: ReqCounter.value.data.length, loading: false })
      // this.setState({all_affiliates:resData.value.data.User_data,totalItemsCount:resData.value.data.User_count})
    } else if (ReqCounter.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/login');
    }
  }

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, () => {
      this.getAllserviceReqCounter()
    });
  }


  render() {
    const { showTotal, barChartcounter, ReqCounter, loading } = this.state
    // console.log(ReqCounter, 'cvvcvx');
    // console.log(barChartcounter);
    const setDataPr = {
      labels: barChartcounter.map(({ month_year }) => month_year),
      datasets: [
        {
          label: `Premium`,
          backgroundColor: '#2b3ead',
          borderColor: 'rgba(0,0,0,1)',
          borderWidth: 2,
          data: barChartcounter.map(({ total_premium }) => total_premium),
        }
      ]
    }

    const setDataReq = {
      labels: barChartcounter.map(({ month_year }) => month_year),
      datasets: [
        {
          label: 'Requests',
          backgroundColor: '#2b3ead',
          borderColor: 'rgba(0,0,0,1)',
          borderWidth: 2,
          data: barChartcounter.map(({ total_service_count }) => total_service_count),
        }
      ]
    }
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          {/* <div className="page-header d-flex justify-content-between align-items-center"> */}
          <span><h2 className="heading-title">Welcome DBC Admin!</h2></span>

          <div className="clearBoth"></div>
          <Spin spinning={loading}>
            <Row>
              <Col span={12}><div className="barchart">
                <Bar
                  data={setDataPr}
                  options={{
                    title: {
                      display: true,
                      text: `${showTotal.total_premium} AED Premium `,
                      fontSize: 20
                    },
                    legend: {
                      display: true,
                      position: 'right'
                    }
                  }}
                />
              </div></Col>
              <Col span={12}><div className="barchart">
                <Bar
                  data={setDataReq}
                  options={{
                    title: {
                      display: true,
                      text: `${showTotal.total_service_request_count} Requests `,
                      fontSize: 20
                    },
                    legend: {
                      display: true,
                      position: 'right'
                    }
                  }}
                />
              </div></Col>
            </Row>

            <div className="site-card-border-less-wrapper dash-services" style={{ 'margin-top': 5 }}>
              <Row>
                {(ReqCounter) ? ReqCounter.length > 0 && ReqCounter.map((item, index) =>
                  <Col key={index} span={8}>
                    <Card key={index}>
                      <p>Service: {item.service_name} {item.total_primium}</p>
                      <p>Total: {item.total_service_req}</p>
                    </Card>
                  </Col>
                ) : ''}
              </Row>
            </div>
            {this.state.totalItemsCount > 0 ?
              <div itemClass="page-item">
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.itemsCountPerPage}
                  totalItemsCount={this.state.totalItemsCount}
                  pageRangeDisplayed={this.state.pageRangeDisplayed}
                  onChange={this.handlePageChange}
                />
              </div> : ''
            }
          </Spin>
        </main>
      </div>
    )
  }
}
