import React, { PureComponent } from 'react'
import TopNavigation from './../TopNavigation/component'
import SidebarNavigation from './../SidebarNavigation/component'
import { Form, DatePicker, message, Button, Select } from 'antd';
import "./styles.scss";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default class AddContentPagesComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      page_name: '',
      heading: '',
      description: '',
      page_type: '',
      amount: '',
      err: {},
      message: '',
      disable: true,
      messageerr: ''
    }
    this.handleChange = this.handleChange.bind(this)
    this.AddContentPages = this.AddContentPages.bind(this)

  }

  handleChange(e) {
    let disable = false
    const self = this
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (self.state.page_name === '' || self.state.page_name === undefined) {
        disable = true
      }
      if (self.state.heading === '' || self.state.heading === undefined) {
        disable = true
      }
      if (self.state.description === '' || self.state.description === undefined) {
        disable = true
      }
      if (self.state.page_type === '' || self.state.page_type === undefined) {
        disable = true
      }
      self.setState({ disable })
    })
  }

  componentDidMount() {
    document.title = 'Add Pages | DBC'
  }


  AddContentPages = async () => {


    const { page_name, heading, description } = this.state

    /* eslint-disable */
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const err = {}

    if (page_name === '' || page_name === null || page_name.trim() === '') {
      err.page_name = 'Page name is required.'
    }


    if (heading === '' || heading === null || heading.trim() === '') {
      err.heading = 'Page header is required.'
    }

    if (description === '' || description === null || description.trim() === '') {
      err.description = 'Description is required.'
    }

    // if (page_type === '' || page_type === null || page_type.trim() === '') {
    //   err.page_type = 'Promo Type is required.'
    // }


    this.setState({ err })
    if (!Object.keys(err).length) {

      if (page_name && heading && description) {
        const AddContentPagesData = {
          page_name: page_name,
          heading: heading,
          description: description,
          page_type: page_name,
        }


        const resData = await this.props.addCmsContent(AddContentPagesData);

        if (resData.value.status === 200) {
          //added successfully
          toast.success("Added successfully", {
            position: toast.POSITION.TOP_CENTER
          });

          setTimeout(() => {
            // console.log('Hello, World!')
            this.props.history.push(`/edit-contentPages/${page_name}`);
          }, 3000);
        } else if (resData.value.status === 401) {
          localStorage.clear();
          this.props.history.push('/login');
        } else {
          // some error in server
          toast.error("Some error occured please try again", {
            position: toast.POSITION.TOP_CENTER
          });
        }

      } else {
        message.error('Please insert valid input')
      }
    }
  }


  render() {
    const { Option } = Select;
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content affiliates_wrapper">
          <div>
            <span><Link to="#">Content Pages</Link>Add New Content Page</span>
          </div>

          <div className="clearBoth"></div>

          {this.state.messageerr ?
            <span className="invalid-feedback">
              {this.state.messageerr}
            </span>
            : ''
          }

          <div className="affMainData">
            <div className="content">
              <div className="form-group">
                <input type="text" placeholder="Page Name" className="form-control" name="page_name"
                  value={this.state.page_name}
                  onChange={this.handleChange.bind(this)}
                  required />
                {this.state.err.page_name ?
                  <span className="invalid-feedback">
                    {this.state.err.page_name}
                  </span>
                  : ''
                }
              </div>

              <div className="form-group">
                <input type="text" placeholder="Page Heading" className="form-control" name="heading"
                  value={this.state.heading}
                  onChange={this.handleChange.bind(this)}
                  required />
                {this.state.err.heading ?
                  <span className="invalid-feedback">
                    {this.state.err.heading}
                  </span>
                  : ''
                }
              </div>

              <CKEditor
                name="description"
                editor={ClassicEditor}
                data=""
                onInit={editor => {
                  // You can store the "editor" and use when it is needed.
                  console.log('Editor is ready to use!', editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  this.setState({ description: data })
                  console.log({ data });
                }}
                onBlur={(event, editor) => {
                  console.log('Blur.', editor);
                }}
                onFocus={(event, editor) => {
                  console.log('Focus.', editor);
                }}
              />

              {/* <input type="textarea" placeholder="Page Description" className="form-control" name="description"
                    value={this.state.description}
                    onChange={this.handleChange.bind(this)} 
                    required/> */}
              {this.state.err.description ?
                <span className="invalid-feedback">
                  {this.state.err.description}
                </span>
                : ''
              }



            </div>
          </div>

          <div>
            <input type="submit" onClick={this.AddContentPages} value="Add" className="marginleft ant-btn btnn ant-btn-primary floatLeft" />
          </div>
        </main>
        <ToastContainer />

      </div>
    );
  }
}

