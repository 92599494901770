import { connect } from 'react-redux'
import TrackingDetailsComponent from './component'
import { getMe, getAllUnreadMessage } from '../../store/user/duck'
import { getTrackingDetails, updateRequestStatus } from '../../store/request/duck'
import { fetchAllCenters } from '../../store/center/duck'
const TrackingDetailsContainer = connect(
  // Map state to props
  state => ({
    message: state.user.message,
    user: state.user.user,
   
  }),
  {
    getTrackingDetails,
    updateRequestStatus,
    getMe,
    fetchAllCenters,
    getAllUnreadMessage
  }
  // Map actions to dispatch and props
)(TrackingDetailsComponent)

export default TrackingDetailsContainer
