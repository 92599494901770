// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from 'immutable'
import { assign } from 'lodash'
// import Cookies from 'universal-cookie'
import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase'


import { fromPromise } from 'rxjs/observable/fromPromise'
import { of } from 'rxjs'
import { mergeMap, flatMap, catchError } from 'rxjs/operators'
import { ofType, combineEpics } from 'redux-observable'

import * as api from './api'

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

export const LOGIN_USER = 'dbc/user/LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'dbc/user/LOGIN_USER_SUCCESS'
export const LOGIN_USER_ERROR = 'dbc/user/LOGIN_USER_ERROR'

export const SEND_VERIFICATION_CODE = 'dbc/user/SEND_VERIFICATION_CODE'
export const SEND_VERIFICATION_CODE_SUCCESS = 'dbc/user/SEND_VERIFICATION_CODE_SUCCESS'
export const SEND_VERIFICATION_CODE_ERROR = 'dbc/user/SEND_VERIFICATION_CODE_ERROR'

export const GET_ME = 'dbc/user/GET_ME'
export const GET_ME_SUCCESS = 'dbc/user/GET_ME_SUCCESS'
export const GET_ME_ERROR = 'dbc/user/GET_ME_ERROR'

export const CLEAR_PHASE = 'dbc/user/CLEAR_PHASE'
export const CLEAR_VERIFICATION_CODE = 'dbc/user/CLEAR_VERIFICATION_CODE'

export const LOGOUT_USER = 'dbc/user/LOGOUT_USER'
export const LOGOUT_USER_SUCCESS = 'dbc/user/LOGOUT_USER_SUCCESS'

export const GET_ALL_UNREAD_MESSAGE = 'dbc/user/GET_ALL_UNREAD_MESSAGE'
export const USER_SETTING = 'dbc/user/USER_SETTING'
export const ADD_SETTING = 'dbc/user/ADD_SETTING'

export const ADD_CMS_CONTENT = 'dbc/user/ADD_CMS_CONTENT'
export const UPDATE_CMS_CONTENT = 'dbc/user/UPDATE_CMS_CONTENT'
export const GET_CMS_CONTENT = 'dbc/user/GET_CMS_CONTENT'
export const GET_USER_DETAILS = 'dbc/user/GET_USER_DETAILS'
export const UPDATE_USER_DETAILS = 'dbc/user/UPDATE_USER_DETAILS'
/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  token: null,
  phase: INIT,
  userPhase: INIT,
  user: null,
  error: null,
  message: null,
  loginPhase: INIT,
  sendVerificationCodePhase: INIT,
  sendVerificationCodeData: {}
}

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = '' // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues))
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line complexity, max-statements

export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    case SEND_VERIFICATION_CODE: {
      return state
        .set('sendVerificationCodePhase', LOADING)
        .set('error', null)
    }

    case SEND_VERIFICATION_CODE_SUCCESS: {
      const { payload } = action
      return state
        .set('sendVerificationCodePhase', SUCCESS)
        .set('sendVerificationCodeData', payload)
        .set('message', payload.message)
        .set('error', null)
    }

    case SEND_VERIFICATION_CODE_ERROR: {
      const { payload } = action
      return state
        .set('sendVerificationCodePhase', ERROR)
        .set('message', payload.error.message)
        .set('error', null)
    }

    case LOGIN_USER: {
      return state
        .set('loginPhase', LOADING)
        .set('loginError', null)
    }

    case LOGIN_USER_SUCCESS: {
      const { payload } = action
      localStorage.setItem('Authorization', payload.data.token)
      return state
        .set('loginPhase', SUCCESS)
        .set('user', payload)
        .set('loginError', null)
    }

    case LOGIN_USER_ERROR: {
      const { payload } = action
      return state
        .set('loginError', payload.error)
        .set('loginPhase', ERROR)
    }

    case GET_ME: {
      return state
        .set('userPhase', LOADING)
    }

    case GET_ME_SUCCESS: {
      const { payload } = action
      return state
        .set('userPhase', SUCCESS)
        .set('user', payload.user)
    }

    case GET_ME_ERROR: {
      return state
        .set('userPhase', ERROR)
    }

    case CLEAR_PHASE: {
      return state
        .set('sendVerificationCodePhase', INIT)
        .set('message', '')
    }

    case CLEAR_VERIFICATION_CODE: {
      return state
        .set('sendVerificationCodeData', INIT)
    }

    case LOGOUT_USER: {
      // cookies.remove('Authorization')
      localStorage.removeItem('Authorization') //remove only user
      localStorage.clear() // for remove Authorization token
      return state
        .set('phase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', true)
    }

    default: {
      return state
    }
  }
}


/***********************************
 * Action Creators
 ***********/

export const sendVerificationCode = credentials => {
  return {
    type: SEND_VERIFICATION_CODE,
    payload: credentials
  }
}

export const loginUser = credentials => {
  return {
    type: LOGIN_USER,
    payload: credentials
  }
}

export const getMe = credentials => {
  return {
    type: GET_ME,
    payload: credentials
  }
}

export const userClearPhase = credentials => {
  return {
    type: CLEAR_PHASE,
    payload: credentials
  }
}

export const codeClearPhase = credentials => {
  return {
    type: CLEAR_VERIFICATION_CODE,
    payload: credentials
  }
}

export const handleSignOut = () => ({
  type: LOGOUT_USER
})


export const getAllUnreadMessage = credentials => {
  return {
    type: GET_ALL_UNREAD_MESSAGE,
    payload: api.getAllUnreadMessage(credentials)
  }
}

export const getUserDetails = credentials => {
  return {
    type: GET_USER_DETAILS,
    payload: api.getUserDetails(credentials)
  }
}


export const updateUserDetails = credentials => {
  return {
    type: UPDATE_USER_DETAILS,
    payload: api.updateUserDetails(credentials)
  }
}

export const userSettings = credentials => {
  return {
    type: USER_SETTING,
    payload: api.userSettings(credentials)
  }
}

export const addSettings = payload => {
  return {
    type: ADD_SETTING,
    payload: api.addSettings(payload)
  }
}

export const addCmsContent = payload => {
  return {
    type: ADD_CMS_CONTENT,
    payload: api.addCmsContent(payload)
  }
}

export const UpdateCmsContent = payload => {
  return {
    type: UPDATE_CMS_CONTENT,
    payload: api.UpdateCmsContent(payload)
  }
}

export const getCmsContent = payload => {
  return {
    type: GET_CMS_CONTENT,
    payload: api.getCmsContent(payload)
  }
}


/***********************************
 * Epics
 ***********************************/

const sendVerificationCodeEpic = action$ =>
  action$.pipe(
    ofType(SEND_VERIFICATION_CODE),
    mergeMap(action => {
      return fromPromise(api.sendVerificationCode(action.payload)).pipe(
        flatMap(payload => [
          {
            type: SEND_VERIFICATION_CODE_SUCCESS,
            payload
          }
        ]),
        catchError(error =>
          of({
            type: SEND_VERIFICATION_CODE_ERROR,
            payload: { error }
          })
        )
      )
    })
  )

const loginUserEpic = action$ =>
  action$.pipe(
    ofType(LOGIN_USER),
    mergeMap(action => {
      return fromPromise(api.loginUser(action.payload)).pipe(
        flatMap(payload => [
          {
            type: LOGIN_USER_SUCCESS,
            payload
          }
        ]),
        catchError(error =>
          of({
            type: LOGIN_USER_ERROR,
            payload: { error }
          })
        )
      )
    })
  )

const meEpic = action$ =>
  action$.pipe(
    ofType(GET_ME),
    mergeMap(action => {
      return fromPromise(api.me(action.payload)).pipe(
        flatMap(payload => [
          {
            type: GET_ME_SUCCESS,
            payload
          }
        ]),
        catchError(error =>
          of({
            type: GET_ME_ERROR,
            payload: { error }
          })
        )
      )
    })
  )

export const userEpic = combineEpics(
  sendVerificationCodeEpic,
  loginUserEpic,
  meEpic
)