import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App/container';
import { CookiesProvider } from "react-cookie";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { Provider } from 'react-redux'
import store from './store'
const history = createBrowserHistory();


ReactDOM.render(
  <Provider store={store} >
    <CookiesProvider>
      <Router basename={'/'} history={history}>
        <App />
      </Router>
    </CookiesProvider>
  </Provider>,
  document.getElementById("root")
);
