import { connect } from 'react-redux'
import { getMe, getAllUnreadMessage, userSettings, addSettings } from '../../store/user/duck'
import SettingComponent from './component'

const SettingContainer = connect(
  // Map state to props
  state => ({
  	user: state.user.user,
  	userPhase: state.user.userPhase
  }),
  {
  	getMe,
    getAllUnreadMessage,
    userSettings,
    addSettings
  }
)(SettingComponent)

export default SettingContainer
