import { connect } from 'react-redux'
import AddContentPagesComponent from './component'
import { getMe, addCmsContent } from '../../store/user/duck'

const AddContentPagesContainer = connect(
  // Map state to props
  state => ({
  }),
  {
    getMe,
    addCmsContent
  }
  // Map actions to dispatch and props
)(AddContentPagesComponent)

export default AddContentPagesContainer
