import { connect } from 'react-redux'
import AddAffiliateComponent from './component'
import { getMe, getAllUnreadMessage } from '../../store/user/duck'
import {  addAffiliate } from '../../store/affiliate/duck'

const AddAffiliateContainer = connect(
  // Map state to props
  state => ({
    message: state.user.message,
    user: state.user.user,
    addAffiliatePhase: state.user.addAffiliatePhase
  }),
  {
    addAffiliate,
    getMe,
    getAllUnreadMessage
  }
  // Map actions to dispatch and props
)(AddAffiliateComponent)

export default AddAffiliateContainer
