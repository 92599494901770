import 'rxjs'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { reducer as formReducer } from 'redux-form'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import { createEpicMiddleware, combineEpics } from 'redux-observable'
import app from './app'
import user, { userEpic } from './user/duck'
import service, { serviceEpic } from './service/duck'
import center, { centerEpic } from './center/duck'
import charts, { chartsEpic } from './charts/duck'
import request, { requestEpic } from './request/duck'
import promocode, { promocodeEpic } from './promocode/duck'
// Bundling Epics
const rootEpic = combineEpics(userEpic, serviceEpic, centerEpic, chartsEpic, requestEpic, promocodeEpic)

// Creating Bundled Epic
const epicMiddleware = createEpicMiddleware()

// Define Middleware
const middleware = [thunk, promise, epicMiddleware]

// Define Reducers
const reducers = combineReducers({
  service,
  center,
  charts,
  request,
  user,
  promocode,
  app,
  form: formReducer
})
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// Create Store
// const store = createStore(
//   reducers,
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
//   applyMiddleware(...middleware)
// )
// const enhancer = compose(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),applyMiddleware(...middleware))
// Create Store
// const store = createStore(
//   reducers,
//   enhancer
// )
const store = createStore(reducers, composeEnhancer(applyMiddleware(...middleware)))
epicMiddleware.run(rootEpic)
export default store
