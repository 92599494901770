import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
// import PropTypes from 'prop-types'

// import Logo from '../../images/logo.png'
import './styles.scss'

import { 
  // ScaleIcon, 
  CompletedOrdersIcon, 
  // CustomersIcon, 
  // UserManagementIcon, 
  // ReportsIcon,
  // CashLogIcon,
  ResourcesIcon,
  ReportsIcon,
  RequestIcon,
  ServiceIcon,
  ReportIcon,
  CustomersIcon,
  UserManagment,
  TransactionIcon,
  DahsboardIcon
  
} from '../../components/icons';




export default class SidebarNavigationComponent extends PureComponent {

  static propTypes = {
    // PropTypes go here
  }

  render() {
    const path = window.location.pathname
    return (
      <aside className="sidebar-layout-sider">
        <div className="sidebar-layout-sider__children">
          <ul className="sidebar-layout__menu sidebar-layout__menu__dark">
            <li className={path === '/dashboard' ? 'sidebar-item sidebar-item-is-selected' : 'sidebar-item'}>
              <Link className="sidebar-item__link" to={'/dashboard'}><span className="sidebar-item__icon"><DahsboardIcon /> </span> Dashboard</Link>
            </li>
            <li className={path === '/services' ? 'sidebar-item sidebar-item-is-selected' : 'sidebar-item'}>
              <Link className="sidebar-item__link" to={'/services'}><span className="sidebar-item__icon"><ServiceIcon/> </span> Services</Link>
            </li>
            <li className={path === '/modifyServices' ? 'sidebar-item sidebar-item-is-selected' : 'sidebar-item'}>
              <Link className="sidebar-item__link" to={'/modifyServices'}><span className="sidebar-item__icon"><ServiceIcon/> </span> Modify Services</Link>
            </li>
            <li className={path === '/requests' ? 'sidebar-item sidebar-item-is-selected' : 'sidebar-item'}>
              <Link className="sidebar-item__link" to={'/requests'}><span className="sidebar-item__icon"><RequestIcon/> </span>Requests</Link>
            </li>
            <li className={path === '/all-centers' ? 'sidebar-item sidebar-item-is-selected' : 'sidebar-item'}>
              <Link className="sidebar-item__link" to={'/all-centers'}><span className="sidebar-item__icon"><TransactionIcon/> </span>All Centers</Link>
            </li>
            <li className={path === '/users' ? 'sidebar-item sidebar-item-is-selected' : 'sidebar-item'}>
              <Link className="sidebar-item__link" to={'/users'}><span className="sidebar-item__icon"><ResourcesIcon/> </span> Customer Management</Link>
            </li>
            <li className={path === '/affiliatemanagment' ? 'sidebar-item sidebar-item-is-selected' : 'sidebar-item'}>
              <Link className="sidebar-item__link" to={'/affiliatemanagment'}><span className="sidebar-item__icon"><UserManagment/> </span> Affiliate Management</Link>
            </li>
            <li className={path === '/promocodemanagment' ? 'sidebar-item sidebar-item-is-selected' : 'sidebar-item'}>
              <Link className="sidebar-item__link" to={'/promocodemanagment'}><span className="sidebar-item__icon"><ReportIcon/> </span>Promo Code Management</Link>
            </li>
            <li className={path === '/analysis' ? 'sidebar-item sidebar-item-is-selected' : 'sidebar-item'}>
              <Link className="sidebar-item__link" to={'/analysis'}><span className="sidebar-item__icon"><TransactionIcon/> </span> Analysis</Link>
            </li>
            <li className={path === '/calendar-pickups' ? 'sidebar-item sidebar-item-is-selected' : 'sidebar-item'}>
              <Link className="sidebar-item__link" to={'/calendar-pickups'}><span className="sidebar-item__icon"><ReportsIcon /> </span>Calendar Pickups</Link>
            </li>
            <li className={path === '/edit-contentPages/privacy' ? 'sidebar-item sidebar-item-is-selected' : 'sidebar-item'}>
              <Link className="sidebar-item__link" to={'/edit-contentPages/privacy'}><span className="sidebar-item__icon"><ReportsIcon /> </span>Privacy Page setting</Link>
            </li>
            <li className={path === '/edit-contentPages/support' ? 'sidebar-item sidebar-item-is-selected' : 'sidebar-item'}>
              <Link className="sidebar-item__link" to={'/edit-contentPages/support'}><span className="sidebar-item__icon"><ReportsIcon /> </span>Support & Help Page setting</Link>
            </li>
            <li className={path === '/edit-contentPages/partner' ? 'sidebar-item sidebar-item-is-selected' : 'sidebar-item'}>
              <Link className="sidebar-item__link" to={'/edit-contentPages/partner'}><span className="sidebar-item__icon"><ReportsIcon /> </span>Partner with us Page setting</Link>
            </li>
            {/* <li className={path === '/outboundactivity' ? 'sidebar-item sidebar-item-is-selected' : 'sidebar-item'}>
              <Link className="sidebar-item__link" to={'#'}><span className="sidebar-item__icon"><OutboundActivityIcon/> </span> Tracking Activity</Link>
            </li> */}
          </ul>
        </div>
      </aside>
    )
  }
}
