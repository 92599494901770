// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from 'immutable'
import { assign } from 'lodash'
// import Cookies from 'universal-cookie'
import { INIT } from '../../constants/phase'

import { combineEpics } from 'redux-observable'

import * as api from './api'

/*********************************** 
 * Action Types
 ***********/
// const cookies = new Cookies()
export const UPLOAD_IMAGE = 'dbc/service/UPLOAD_IMAGE'

// export const CREATE_SERVICE_SUCCESS = 'dbc/user/CREATE_SERVICE_SUCCESS'
// export const CREATE_SERVICE_ERROR = 'dbc/user/CREATE_SERVICE_ERROR'
export const CREATE_SERVICE = 'dbc/service/CREATE_SERVICE'
export const UPDATE_SERVICE = 'dbc/service/UPDATE_SERVICE'
export const FETCH_SERVICES = 'dbc/service/FETCH_SERVICES'
export const FETCH_SERVICE_USERS = 'dbc/service/FETCH_SERVICE_USERS'
export const FETCH_ALL_SERVICES = 'dbc/service/FETCH_ALL_SERVICES'
export const FETCH_SERVICE_BY_ID = 'dbc/service/FETCH_SERVICE_BY_ID'
export const DEFAULT_SERVICES = 'dbc/service/DEFAULT_SERVICES'
/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  token: null,
  phase: INIT,
  userPhase: INIT,
  user: null,
  error: null,
  message: null,
  loginPhase: INIT,
  servicePhase: INIT,
  sendVerificationCodePhase: INIT,
  sendVerificationCodeData: {}
}

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = '' // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues))
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line complexity, max-statements

export default function (state = new InitialState(), action = {}) {
  switch (action.type) {

    // case CREATE_SERVICE: {
    //   return state
    //     .set('servicePhase', LOADING)
    //     .set('serviceError', null)
    // }

    // case CREATE_SERVICE_SUCCESS: {
    //   const { payload } = action
    //   return state
    //     .set('servicePhase', SUCCESS)
    //     .set('serviceData', payload.data)
    //     .set('serviceError', null)
    // }

    // case CREATE_SERVICE_ERROR: {
    //   const { payload } = action
    //   return state
    //     .set('serviceError', payload.error)
    //     .set('servicePhase', ERROR)
    // }
    
    default: {
      return state
    }
  }
}


/***********************************
 * Action Creators
 ***********/

export const uploadImage = payload => {
  return {
    type: UPLOAD_IMAGE,
    payload: api.uploadImage(payload)
  }
}

export const createService = payload => {
  return {
    type: CREATE_SERVICE,
    payload: api.createService(payload)
  }
}

export const updateService = payload => {
  return {
    type: UPDATE_SERVICE,
    payload: api.updateService(payload)
  }
}

export const fetchServiceUsers = payload => {
  return {
    type: FETCH_SERVICE_USERS,
    payload: api.fetchServiceUsers(payload)
  }
}

export const fetchServices = payload => {
  return {
    type: FETCH_SERVICES,
    payload: api.fetchServices(payload)
  }
}


export const fetchAllServices = payload => {
  return {
    type: FETCH_ALL_SERVICES,
    payload: api.fetchAllServices(payload)
  }
}

export const fetchServiceById = payload => {
  return {
    type: FETCH_SERVICE_BY_ID,
    payload: api.fetchServiceById(payload)
  }
}

export const adddefaultService = payload => {
  return {
    type: DEFAULT_SERVICES,
    payload: api.adddefaultService(payload)
  }
}

/***********************************
 * Epics
 ***********************************/
// const createServiceEpic = action$ =>
//   action$.pipe(
//     ofType(CREATE_SERVICE),
//     mergeMap(action => {
//       return fromPromise(api.createService(action.payload)).pipe(
//         flatMap(payload => [
//           {
//             type: CREATE_SERVICE_SUCCESS,
//             payload
//           }
//         ]),
//         catchError(error =>
//           of({
//             type: CREATE_SERVICE_ERROR,
//             payload: { error }
//           })
//         )
//       )
//     })
//   )

export const serviceEpic = combineEpics()