import { fetch } from '../../utils'
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME ? process.env.REACT_APP_API_HOSTNAME : 'https://dbcapi.webvilleedemo.xyz'

export const getAllPromoCode = data => {
  return fetch(`${HOSTNAME}/admin/show_promo_code`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}
 
export const addPromoCode = data => {
  return fetch(`${HOSTNAME}/admin/add_promo_code`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const updatePromoCode = data => {
  return fetch(`${HOSTNAME}/admin/update_promo_code`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}



export const getPromoCodeByid = data => {
  return fetch(`${HOSTNAME}/admin/get_promo_code_by_id`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const deletePromocode = data => {
  return fetch(`${HOSTNAME}/admin/delete_promo_code`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}