import React, { PureComponent } from 'react'
import TopNavigation from './../TopNavigation/component'
import SidebarNavigation from './../SidebarNavigation/component'
import { Doughnut } from 'react-chartjs-2';
import { Statistic, Row, Col } from 'antd'
import './styles.scss'
export default class AnalysisComponent extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      itemsCountPerPage: 10,
      totalItemsCount: 0,
      pageRangeDisplayed: 5,
      link_path: 'https://dbcapi.webvilleedemo.xyz',
      alert: null,
      loading: false,
      user_id: '',
      showTotalAnalysis:[],
      barChartcounter: [],
      serviceReqCounter: [],
      page_no: 0,
    }
  }

  async componentDidMount() {
    document.title = 'Analysis';

    const dataReq = await this.props.showAnalysisCounter();
    if (dataReq.value.status === 200) {
      this.setState({ showTotalAnalysis: dataReq.value.data })
    }
  }

  render() {
    const { showTotalAnalysis } = this.state
    console.log(showTotalAnalysis);
    const setDataPr = {
      labels: ['January', 'February', 'March',
        'April', 'May'],
      datasets: [
        {
          label: 'Rainfall',
          backgroundColor: [
            '#2b3ead',
            '#C9DE00',
            '#2FDE00',
            '#00A6B4',
            '#6800B4'
          ],
          hoverBackgroundColor: [
            '#2b3ead',
            '#4B5000',
            '#175000',
            '#003350',
            '#35014F'
          ],
          data: [65, 59, 80, 81, 56]
        }
      ]
    }

    // const setDataReq = {
    //   labels: allData.map(({ month_year }) => month_year),
    //   datasets: [
    //     {
    //       label: 'Requests',
    //       backgroundColor: '#2b3ead',
    //       borderColor: 'rgba(0,0,0,1)',
    //       borderWidth: 2,
    //       data: allData.map(({ total_service_count }) => total_service_count),
    //     }
    //   ]
    // }
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          {/* <div className="page-header d-flex justify-content-between align-items-center"> */}
          <span><h2 className="heading-title">Welcome DBC Admin!</h2></span>

          <div className="clearBoth"></div>
          <div className="site-statistic-demo-card">
          <Row gutter={16}>
            <Col span={12}>
              <Statistic title="Online Payments" value={showTotalAnalysis.total_number_card_payment} />
            </Col>
            <Col span={12}>
              <Statistic title="Cash" value={showTotalAnalysis.total_number_cash_payment} />
            </Col>
          </Row>
          </div>

          {/* <Row>
            <Col span={12}><div className="barchart">
              <Doughnut
                data={setDataPr}
                options={{
                  title: {
                    display: true,
                    text: 'Average Rainfall per month',
                    fontSize: 20
                  },
                  legend: {
                    display: true,
                    position: 'right'
                  }
                }}
              />
            </div></Col>
            <Col span={12}><div className="barchart">
              <Doughnut
                data={setDataPr}
                options={{
                  title: {
                    display: true,
                    text: 'Average Rainfall per month',
                    fontSize: 20
                  },
                  legend: {
                    display: true,
                    position: 'right'
                  }
                }}
              />
            </div></Col>
          </Row> */}


          {/* <div className="form-group material-textfield">
              <select className="form-control custom-select w-150 material-textfield-input pr-22" required>
                <option value="0">June 1, 2020 - June 30,2020</option>                 
              </select>
              <label className="material-textfield-label">Date </label>
            </div> */}
          {/* </div> */}
        </main>
      </div>
    )
  }
}
