import { connect } from 'react-redux'
import RequestDetailsComponent from './component'
import { getMe, getAllUnreadMessage } from '../../store/user/duck'
import {  UpdateDocuments, UpdatetrackingId, getRequestDetails, getAllChatHistory, DocumentsZip } from '../../store/request/duck'
import { uploadImage } from '../../store/service/duck'

const RequestDetailsContainer = connect(
  // Map state to props
  state => ({
    message: state.user.message,
    user: state.user.user,
   
  }),
  {
    getRequestDetails,
    getMe,
    uploadImage,
    UpdateDocuments,
    getAllChatHistory,
    getAllUnreadMessage,
    DocumentsZip,
    UpdatetrackingId
  }
  // Map actions to dispatch and props
)(RequestDetailsComponent)

export default RequestDetailsContainer
