import { connect } from 'react-redux'
import AffiliateComponent from './component'
import { getMe, getAllUnreadMessage } from '../../store/user/duck'
import { getAllAffiliates, deleteAffiliate } from '../../store/affiliate/duck'


const AffiliateContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase,
  }),
  {
    getAllAffiliates,
    getMe,
    deleteAffiliate,
    getAllUnreadMessage
  }
  // Map actions to dispatch and props
)(AffiliateComponent)

export default AffiliateContainer
