import React, { PureComponent } from 'react'
import { message } from 'antd'
import BlackLogo from '../../images/logo.png'
import './styles.scss'


export default class LoginComponent extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      err: {},
      password: '',
      code: '',
      message: '',
      disable: true,
      signInButtonDisable: true,
      loginMessage: true,
      messageerr: ''
    }
    this.handleChange = this.handleChange.bind(this)
    this.loginUser = this.loginUser.bind(this)
  }

  handleChange (e) {
    let disable = false
    const self = this
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (self.state.email === '' || self.state.email === undefined) {
        disable = true
      }
      if (self.state.password === '' || self.state.password === undefined) {
        disable = true
      }
      self.setState({ disable })
    })
  }

  componentDidMount() {
    document.title = 'Login | DBC'
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.loginPhase === 'success') {
      if (this.props.user) {
        if (this.props.user.status === 200) {
          localStorage.setItem('user_id', this.props.user.data._id)
          this.props.history.push('/dashboard')
          this.setState({messageerr:''})
        }else if (this.props.user.status === 401) {
          localStorage.clear();
          this.props.history.push('/login');
        }else{
          localStorage.setItem('user_id', '')
          this.setState({messageerr:this.props.user.msg})
        }
      }
    }else{
      this.setState({messageerr:''})
    }
  }


  onKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.loginUser();
    }
  }


  loginUser () {
    const { email, password } = this.state
    /* eslint-disable */
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const err = {}

    if (email === '' || email.trim() === '') {
      err.email = 'Email is required.'
    }

    if (password === '' || password.trim() === '') {
      err.password = 'Password is required.'
    }

    if (email && email !== '') {
      const testR = emailRegex.test(String(this.state.email).toLowerCase())
      if (!testR) {
        err.email = 'Email is invalid.'
      }
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
      if (email && password) {
        const loginData = {
          user_email: this.state.email,
          password: this.state.password
        }
        this.props.loginUser(loginData)
      } else {
        message.error('Please insert valid code')
      }
    }
  }

  render() {
    return (
      <div>
        <main className="login-container">
         <div className="card login-container-inner">             
              <img className="img-fluid" src={BlackLogo} alt="dbc" />
              <p>Please enter your email address and password for login </p>
              {this.state.messageerr ?
                    <span className="invalid-feedback">
                      {this.state.messageerr}
                    </span>
                    : ''
                  }
              <div className="form-group material-textfield">
                <input 
                  type="text" 
                  name="email"
                  // onKeyPress={this.handleEnter.bind(this)}
                  value={this.state.email}
                  onChange={this.handleChange.bind(this)}
                  className="form-control material-textfield-input" 
                  required/>
                  { this.state.err.email ?
                    <span className="invalid-feedback">
                      {this.state.err.email}
                    </span>
                    : ''
                  }
                <label className="material-textfield-label">Email</label>
              </div>
              <div className="form-group material-textfield">
                <input 
                  type="password" 
                  name="password"
                  onKeyPress={this.onKeyPress}
                  value={this.state.password}
                  onChange={this.handleChange.bind(this)}
                  className="form-control material-textfield-input" 
                  required/>
                  { this.state.err.password ?
                    <span className="invalid-feedback">
                      {this.state.err.password}
                    </span>
                    : ''
                  }
                <label className="material-textfield-label">Password</label>
              </div>
              <button type="submit" className="btn-block btn-login" onClick={this.loginUser} onKeyDown={this.loginUser}>Login</button>
            </div>
        </main>
      </div>
    )
  }
}
