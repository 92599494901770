import React, { PureComponent } from 'react'
import TopNavigation from './../TopNavigation/component'
import SidebarNavigation from './../SidebarNavigation/component'
import { Form, DatePicker, message, Spin, Select } from 'antd';
import "./styles.scss";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
export default class UpdatePromoCodeComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      promo_code: '',
      from_date: '',
      to_date: '',
      promo_type: '',
      amount: '',
      err: {},
      message: '',
      disable: true,
      messageerr: '',
      promo_id: '',
      loading:false,
      disabled : false
    }
    this.handleChange = this.handleChange.bind(this)
    this.updatePromoCode = this.updatePromoCode.bind(this)

  }

  handleChange(e) {
    let disable = false
    const self = this
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (self.state.promo_code === '' || self.state.promo_code === undefined) {
        disable = true
      }
      if (self.state.amount === '' || self.state.amount === undefined) {
        disable = true
      }
      self.setState({ disable })
    })
  }

  componentDidMount() {
    document.title = 'Update Promocode | DBC'
    const uid = this.props.match.params.id;
    this.getPromocodeDetails(uid);
  }

  getPromocodeDetails = async (id) => {
    this.setState({ loading:true})
    const postData = {
      promo_code_id: id,
    }

    const resData = await this.props.getPromoCodeByid(postData)
    if (resData.value.status === 200) {
      var getData = resData.value.data[0]
      this.setState({ promo_code: getData.promo_code, from_date: getData.from_date, to_date: getData.to_date, promo_type: getData.promo_type, amount: getData.amount, promo_id: getData._id })
      this.setState({ loading:false})
    } else if (resData.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/login');
    }else {
      this.setState({ messageerr: resData.value.msg })
      this.setState({ loading:false})
    }
  }


  updatePromoCode = async () => {
    
    const { promo_code, from_date, to_date, promo_type, amount } = this.state

    /* eslint-disable */
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const err = {}

    if (promo_code === '' || promo_code === null || promo_code.trim() === '') {
      err.promo_code = 'Promocode is required.'
    }


    if (from_date === '' || from_date === null || from_date.trim() === '') {
      err.from_date = 'From Date is required.'
    }

    if (to_date === '' || to_date === null || to_date.trim() === '') {
      err.to_date = 'To Date is required.'
    }

    if (promo_type === '' || promo_type === null ) {
      err.promo_type = 'Promo Type is required.'
    }

    if (amount === '' || amount === null ) {
      err.amount = 'Amount is required.'
    }

    // if ((new Date(from_date) > new Date(to_date)) || (new Date(to_date) < new Date(from_date))) {
    // if (from_date <= to_date) {  
    //   err.to_date = 'Choose  to date greater that from date field is required.'
    // }else{
    //   err.to_date = ''
    // }

    if(Date.parse(from_date) > Date.parse(to_date)){
      // alert("Invalid Date Range");
      err.to_date = 'Choose to date greater that from date field is required..'
    }

    // if ((new Date(from_date) < new Date(to_date)) || (new Date(to_date) > new Date(from_date))) {
    //   err.to_date = 'Choose date greater that from date is required.'
    // }


    this.setState({ err })
    if (!Object.keys(err).length) {
      this.setState({disabled: true});
      if (promo_code && from_date && to_date && promo_type && amount) {
        const updatePromoCodeData = {
          promo_code_id: this.state.promo_id,
          promo_code: this.state.promo_code,
          from_date: this.state.from_date,
          to_date: this.state.to_date,
          promo_type: this.state.promo_type,
          amount: parseInt(this.state.amount)
        }


        const resData = await this.props.updatePromoCode(updatePromoCodeData);

        if (resData.value.status === 200) {
          //updateed successfully
          toast.success("Updated successfully", {
            position: toast.POSITION.TOP_CENTER
          });

          setTimeout(() => {
            // console.log('Hello, World!')
            this.props.history.push("/promocodemanagment");
          }, 3000);
        } else if (resData.value.status === 401) {
          localStorage.clear();
          this.props.history.push('/login');
        }else {
          // some error in server
          if (promo_code==='' || from_date==='' || from_date=='Invalid date' || to_date===''  || to_date=='Invalid date' || promo_type==='' || amount ==='') {
            toast.error('Please fill all the required fields', {
              position: toast.POSITION.TOP_CENTER
            });
            setTimeout(() => {
              this.setState({disabled: false});
            }, 3000);
           }else{
            toast.error(resData.value.msg, {
              position: toast.POSITION.TOP_CENTER
            });
            setTimeout(() => {
              this.setState({disabled: false});
            }, 3000);
           }
        }

      } else {
        message.error('Please fill all the required fields')
      }
    }
  }

  onPanelChange = (value, mode) => {
    // console.log(value.format('D MMMM  YYYY'), '00000')
    // this.setState({value: value});
    let datafromate = moment(value).format("D MMMM  YYYY");
    // console.log(moment(value).format("YYYY-MM-DD"))
    if (datafromate) {
      this.setState({ from_date: datafromate });
    }
  }
  onPanelChangedate = (value, mode) => {
    // console.log(value.format('D MMMM  YYYY'), '00000')
    // this.setState({value: value});
    let datafromate = moment(value).format("D MMMM  YYYY");
    // console.log(moment(value).format("YYYY-MM-DD"))
    if (datafromate) {
      this.setState({ to_date: datafromate });
    }
  }

  onpromoChange = value => {
    this.setState({ promo_type: value })
  };

  checkMonth(e, promo_type){
    // console.log(promo_type, 'uyutughj')
    if(promo_type === "2"){
      if(!(e.target.value > 0 && e.target.value <= 99 )){
        e.target.value = "";
        // console.log(e.target.value)
      }
    }
  }

  render() {
    const { promo_type, loading } = this.state;
    const from_date = moment(this.state.from_date).format("YYYY-MM-DD");
    const to_date = moment(this.state.to_date).format("YYYY-MM-DD");
    const { Option } = Select;
    const dateFormat = 'YYYY-MM-DD';
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content affiliates_wrapper">
        <Spin spinning={loading}>
          <div>
            <span><Link to="/promocodemanagment">Promocode</Link>/update Promocode</span>
          </div>

          <div className="clearBoth"></div>

          {this.state.messageerr ?
            <span className="invalid-feedback">
              {this.state.messageerr}
            </span>
            : ''
          }

          <div className="affMainData">
            <div className="content">
              <div className="form-group">
                <input type="text" placeholder="Promocode" className="form-control" name="promo_code"
                  value={this.state.promo_code}
                  onChange={this.handleChange}
                  required />
                {this.state.err.promo_code ?
                  <span className="invalid-feedback">
                    {this.state.err.promo_code}
                  </span>
                  : ''
                }
              </div>
              <div className="form-group">
                <DatePicker 
                value={(from_date) ? moment(from_date, 'YYYY-MM-DD') : ''} 
                placeholder="From Date" className="form-control" name="from_date"
                  onChange={this.onPanelChange}
                  required />
                {this.state.err.from_date ?
                  <span className="invalid-feedback">
                    {this.state.err.from_date}
                  </span>
                  : ''
                }
              </div>
              <div className="form-group">
                <DatePicker placeholder="To Date" className="form-control" name="to_date"
                value={(to_date) ? moment(to_date, 'YYYY-MM-DD') : ''} 
                  onChange={this.onPanelChangedate}
                  required />
                {this.state.err.to_date ?
                  <span className="invalid-feedback">
                    {this.state.err.to_date}
                  </span>
                  : ''
                }
              </div>
              <div className="form-group">{console.log({promo_type})}
                <Select
                  placeholder="Select a option Promo Type"
                  onChange={this.onpromoChange}
                  name="promo_type"
                  value={promo_type}>
                  <Option value={1}>AED</Option>
                  <Option value={2}>%</Option>
                </Select>
                {this.state.err.promo_type ?
                  <span className="invalid-feedback">
                    {this.state.err.promo_type}
                  </span>
                  : ''
                }
              </div>
              <div className="form-group">
                <input type="number" min="5" placeholder="Amount" className="form-control" name="amount" onKeyUp={(e)=>this.checkMonth(e, promo_type)}
                  value={this.state.amount}
                  onChange={this.handleChange}
                  required maxLength="3" />
                {this.state.err.amount ?
                  <span className="invalid-feedback">
                    {this.state.err.amount}
                  </span>
                  : ''
                }
              </div>
            </div>
          </div>

          <div>
            <input type="submit" onClick={this.updatePromoCode} value={this.state.disabled ? 'Updating...' : 'Update'} 
            disabled={this.state.disabled} className="marginleft ant-btn btnn ant-btn-primary floatLeft" />
          </div>
          </Spin>
        </main>
        <ToastContainer />
      </div>
    );
  }
}

