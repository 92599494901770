import { connect } from 'react-redux'
import { getMe, getAllUnreadMessage } from '../../store/user/duck'
import { createService, fetchAllServices, uploadImage, updateService, fetchServiceById  } from '../../store/service/duck'
import modifyServicesComponent from './component'
import { fetchAllCenters, createCenter } from '../../store/center/duck'
const modifyServicesContainer = connect(
  // Map state to props
  state => ({
  	user: state.user.user,
    userPhase: state.user.userPhase,
    
  }),
  {
    getMe,
    createService,
    fetchAllServices,
    uploadImage,
    updateService,
    fetchServiceById,
    fetchAllCenters,
    createCenter,
    getAllUnreadMessage
  }
)(modifyServicesComponent)

export default modifyServicesContainer
