import React, { PureComponent } from 'react'
// import PropTypes from 'prop-types'
import TopNavigation from './../TopNavigation/component'
import SidebarNavigation from './../SidebarNavigation/component'
import { Form, Row, Col, Button, Spin, message } from "antd";
import './styles.scss'

export default class SettingComponent extends PureComponent {

  static propTypes = {
    // PropTypes go here
  }

  state = {
    pickcharges: 0,
    message:'',
    disable:false,
    disabled : false,
    loading:false
  }

  componentDidMount() {
    // this.setState({ loading: true });
    this.getSettings();
  }

 async getSettings(){
    const datacharge = await this.props.userSettings();
    if (datacharge.value.status === 200) {
      this.setState({ pickcharges: datacharge.value.data.meta_value })
    }else if (datacharge.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/login');
    }
  }
  

  handleChange(e) {
    let disable = false
    const self = this
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (self.state.pickcharges === '' || self.state.pickcharges === undefined) {
        disable = true
      }
      self.setState({ disable })
    })
  }


  handleSubmit = async() => {
    const {pickcharges} =this.state
    const err = {}

    if (pickcharges === '' || pickcharges === null || pickcharges.trim() === '') {
      err.pickcharges = 'Pick up charges is required.'
    }

    this.setState({ err })
    if (!Object.keys(err).length) {
      this.setState({disabled: true});
      if (pickcharges) {
        const Data = {
          meta_id: 1,
          // meta_key:  'Pickup_charge',
          meta_value: pickcharges,
        }
        console.log(Data, 'data')
        const resData = await this.props.addSettings(Data);
        if (resData.value.status === 200) {
          message.success('successfully Updated');
          this.getSettings();
        }else if (resData.value.status === 401) {
          localStorage.clear();
          this.props.history.push('/login');
        } else {
          message.error(resData.value.msg);
          setTimeout(() => {
            this.setState({disabled: false});
          }, 3000);
        }
    }
  }
 }

  render() {
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation/>
        <TopNavigation {...this.props}/>
        <main className="dashboard-layout-content reports__wrapper">
        <div className="page-header d-flex justify-content-between align-items-center">
            <h3 className="heading-title">Setting</h3>
            <div className="form-group material-textfield">
              {/* <Button className="btnn" type="primary" >Add Service</Button> */}
            </div>
        </div>
        <Spin spinning={this.state.loading}>
        <Form>
                <Row gutter={16}>
                  <Col span={12}>
                  <h6>Change Pickup Charges</h6>
                    <div className="form-group">
                      <label>Pickup Charges</label>
                    <input type="number" placeholder="Pickup charges" className="form-control" name="pickcharges"
                    value={this.state.pickcharges}
                    onChange={this.handleChange.bind(this)}
                    required />
                  {/* {this.state.err.pickcharges ?
                    <span className="invalid-feedback">
                      {this.state.err.pickcharges}
                    </span>
                    : ''
                  } */}
                </div>
                    <Button disabled={this.state.disabled} onClick={this.handleSubmit} key="submit"  type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Col>
                </Row>
            </Form>
</Spin>
        </main>
      </div>
    )
  }
}
