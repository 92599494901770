import { connect } from 'react-redux'
import PromocodeComponent from './component'
import { getMe, getAllUnreadMessage } from '../../store/user/duck'
import { getAllPromoCode, deletePromocode } from '../../store/promocode/duck'


const PromocodeContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase,
  }),
  {
    getAllPromoCode,
    getMe,
    deletePromocode,
    getAllUnreadMessage
  }
  // Map actions to dispatch and props
)(PromocodeComponent)

export default PromocodeContainer
