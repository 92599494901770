import React, { PureComponent } from 'react'
import TopNavigation from '../TopNavigation/component'
import SidebarNavigation from '../SidebarNavigation/component'
import "./styles.scss";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { Input, message, Spin } from 'antd';

export class trackingDetailsComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      service_req_id: "",
      err: {},
      message: '',
      disable: true,
      messageerr: '',
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      request_detail_data: {},
      pickup_enbale: false,
      new_request_status: 0,
      loading: false,
      checkedValues: [],
      address: '',
      landmark: '',
      drop_off_center: '',
      centersItem: '',
      disabled : false,
      lng: '',
      lat: ''
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    let disable = false
    const self = this
    this.setState({ [e.target.name]: e.target.value }, () => {
      self.setState({ disable })
    })
  }

  handleChangeStatus = ({ target }) => {
    const checkedValues = [...this.state.checkedValues]
    const value = parseInt(target.value)
    this.setState({ new_request_status: target.value })
    const index = checkedValues.indexOf(value)
    if (index === -1) {
      checkedValues.push(value)
    } else {
      checkedValues.splice(index, 1)
    }
    this.setState({ checkedValues })
  }

  componentDidMount() {
    document.title = 'Tracking Details | DBC'

    const servicereq_id = this.props.match.params.id;

    this.getTrackingDetails(servicereq_id);
  }

  getTrackingDetails = async (id) => {
    this.setState({ loading: true })
    const datacenters = await this.props.fetchAllCenters();
    if (datacenters.value.status === 200) {
      // console.log(datacenters.value.data);
      this.setState({ centersItem: datacenters.value.data })
    } else if (datacenters.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/login');
    }

    const postData = {
      service_req_id: id,
    }
    const resData = await this.props.getTrackingDetails(postData)
    if (resData.value.status === 200) {
      this.setState({ request_detail_data: resData.value.data })
      var getData = resData.value.data
      if (getData.request_type === 'pickup') {
        this.setState({ pickup_enbale: true })
      }
      const size = resData.value.data.request_status
      const checkedValues = [...Array(size + 1).keys()]
      // console.log(getData, 'oooooooo')
      this.setState({ new_request_status: size, loading: false, checkedValues, address: getData.address, landmark: getData.landmark, drop_off_center: getData.drop_off_center, lat: getData.lat, lng: getData.lng })
    } else if (resData.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/login');
    } else {
      this.setState({ messageerr: resData.value.msg })
    }
  }

  updateRequestStatus = async () => {
    this.setState({disabled: true});
    const updateRequestData = {
      service_req_id: this.props.match.params.id,
      request_status: this.state.new_request_status
    }

    const resData = await this.props.updateRequestStatus(updateRequestData);
    if (resData.value.status === 200) {
      message.success(resData.value.msg);
      // console.log("Success===")
    } else if (resData.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/login');
    } else {
      this.setState({ messageerr: resData.value.msg })
      setTimeout(() => {
        this.setState({disabled: false});
      }, 3000);
    }
  }

  findObjectByKey(array, key, value) {
    for (var i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return array[i];
      }
    }
    return null;
  }

  render() {
    const { new_request_status, loading, checkedValues, address, landmark, drop_off_center, centersItem, lng, lat } = this.state
    var obj = this.findObjectByKey(centersItem, '_id', drop_off_center);

    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation />
        <TopNavigation {...this.props} />
        <Spin spinning={loading}>
          <main className="dashboard-layout-content affiliates_wrapper">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/requests">Requests</Link></li>
                <li className="breadcrumb-item"><a href="#">Tracking Details</a></li>
                <li className="breadcrumb-item active" aria-current="page">{this.props.match.params.id}</li>
              </ol>
            </nav>
            <div className="trackingScreen">
              <div className="row">
                <div className="col-md-6">
                  <div className="trackingProcess">
                    <div className="trackingProcessList">
                      <Input type="checkbox" value="0" checked name="tracking" onChange={this.handleChangeStatus} />
                      <div className="trackingProInner">
                        <h4>Request Created</h4>
                        <p>{(new Date(this.state.request_detail_data.create_on)).toLocaleDateString()} {(new Date(this.state.request_detail_data.create_on)).toLocaleTimeString()}</p>
                      </div>
                    </div>
                    <div className="trackingProcessList">
                      <Input type="checkbox" name="tracking" checked={checkedValues.indexOf(1) !== -1 ? true : false} value="1" name="tracking" onChange={this.handleChangeStatus} />
                      <div className="trackingProInner">
                        <div style={(this.state.pickup_enbale === true) ? { display: "block" } : { display: "none" }}>
                          <h4>Document Pickup</h4>
                          <p>{(address) ? address : ''}</p>
                        </div>
                        <div style={(this.state.pickup_enbale === false) ? { display: "block" } : { display: "none" }}>
                          <h4>Drop Off Center</h4>
                          <p>{(obj) ? obj.center_name : ''}</p>
                        </div>
                      </div>

                    </div>

                    <div className="trackingProcessList">
                      <Input type="checkbox" name="tracking" checked={checkedValues.indexOf(2) !== -1 ? true : false} value="2" name="tracking" onChange={this.handleChangeStatus} />
                      <div className="trackingProInner">
                        <h4>Processing</h4>
                      </div>
                    </div>
                    <div className="trackingProcessList">
                      <Input type="checkbox" name="tracking" checked={checkedValues.indexOf(3) !== -1 ? true : false} value="3" name="tracking" onChange={this.handleChangeStatus} />
                      <div className="trackingProInner">
                        <h4>Document Delivery</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="trackingMap">
                    <Map google={this.props.google} zoom={16}
                      center={{ lat: (lat) ? lat : 18.5204, lng: (lng) ? lng : 73.8567 }}
                    >
                      {/* <Marker  markerPosition={{ lat:(lat)? lat :  18.5204 , lng: (lng)? lng : 73.8567 }} onClick={this.onMarkerClick}
                        name={(address) ? address : ''} /> */}
                      <Marker
                        title={(address) ? address : ''}
                        name={(address) ? address : ''}
                        position={{ lat: (lat) ? lat : 18.5204, lng: (lng) ? lng : 73.8567 }} />
                      <InfoWindow visible="true" onClose={this.onInfoWindowClose}>
                        <div>
                          <h1>{address}</h1>
                        </div>
                      </InfoWindow>
                    </Map>
                    <div className="trackingAddress">
                      <div className="form-group">
                        <p className="formLabel">House/Flat No.</p>
                        <input type="text" value={(address) ? address : ''} placeholder="eg., Naruka Homes / B-53" className="form-control" />
                      </div>
                      <div className="form-group">
                        <p className="formLabel">Landmark</p>
                        <input type="text" value={(landmark) ? landmark : ''} placeholder="eg., Opposite to HDFC Bank" className="form-control" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button type="button" disabled={this.state.disabled} onClick={this.updateRequestStatus} onKeyDown={this.updateRequestStatus} className="ant-btn btnn ant-btn-primary">Update</button>
            </div>
          </main></Spin>
        <ToastContainer />
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyCFR9hzxNNeMLv8TY_kfKgHBEFiBrG5dXE')
})(trackingDetailsComponent)