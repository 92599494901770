import React, { PureComponent } from 'react'
import { ScheduleComponent, ViewsDirective, ViewDirective, Day, Week, Inject } from '@syncfusion/ej2-react-schedule';
import TopNavigation from './../TopNavigation/component'
import SidebarNavigation from './../SidebarNavigation/component'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import "./styles.scss";
import "./material.css";
import moment from 'moment';
import { enableRipple } from '@syncfusion/ej2-base';
enableRipple(true);
/**
 * Schedule Default sample
 */

export default class CalendarComponent extends PureComponent {

  constructor() {
    super(...arguments);
    this.state = {
      user_id: '',
      user_name: '',
      err: {},
      message: '',
      eventAllData: [],
      dateSet: new Date()
    }
  }

  async componentDidMount() {
    this.getAllEvents(new Date())
  }

  getAllEvents = async(fromDate) => {
    const eventData = await this.props.ShowEvents({ from_date:fromDate });
    //console.log(eventData, 'ghfghfgh')
    if (eventData.value.status === 200) {
      this.setState({ eventAllData: eventData.value.data })
    }else if (eventData.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/login');
    }
  }

  change(args) {
    this.scheduleObj.selectedDate = args.value;
    this.scheduleObj.dataBind();
    this.getAllEvents(moment(args.value).format("YYYY-MM-DD"))
 }

  render() {
    // console.log(this.state.eventAllData, 'kKKKK');
    var scheduleData = this.state.eventAllData.map(function (item) {
      return {
        Id: item._id,
        Subject: item.service_name,
        Location: item.address,
        StartTime: new Date(item.pickup_date_timestamp).toISOString(),
        EndTime: new Date(item.pickup_date_timestamp).toISOString()
      };
    });

    return (<div className="layout-has-sidebar">
      <SidebarNavigation />
      <TopNavigation {...this.props} />
      <main className="dashboard-layout-content affiliates_wrapper"></main><div className='schedule-control-section'>
        <div className='col-lg-12 control-section'>
        </div>
        <div className='col-lg-12 control-section'>
          <div className='control-wrapper'>
            <ScheduleComponent height='650px' ref={schedule => this.scheduleObj = schedule} selectedDate={new Date()} eventSettings={{ dataSource: scheduleData }}>
              <div className='col-lg-3' style={{'position': 'absolute', 'top': 15, 'margin-left': -11}}>
              <DatePickerComponent value={new Date()} showClearButton={false}  onChange={this.change.bind(this)}></DatePickerComponent>
              </div>
              <ViewsDirective>
                <ViewDirective option='Day' />
                <ViewDirective option='Week' />
              </ViewsDirective>
              <Inject services={[Day, Week]} />
            </ScheduleComponent>
          </div>
        </div>
      </div></div>);
  }
}