// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from 'immutable'
import { assign } from 'lodash'
// import Cookies from 'universal-cookie'
import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase'

import { fromPromise } from 'rxjs/observable/fromPromise'
import { of } from 'rxjs'
import { mergeMap, flatMap, catchError } from 'rxjs/operators'
import { ofType, combineEpics } from 'redux-observable'

import * as api from './api'

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

export const GET_ALL_REQUESTS = 'empire/affiliate/GET_ALL_AFFILIATES'

export const ADD_AFFILIATE = 'dbc/affiliate/ADD_AFFILIATE'
export const ADD_AFFILIATE_SUCCESS = 'dbc/affiliate/ADD_AFFILIATE_SUCCESS'
export const ADD_AFFILIATE_ERROR = 'dbc/affiliate/ADD_AFFILIATE_ERROR'

export const GET_AFFILIATE_DETAILS = 'empire/affiliate/GET_AFFILIATE_DETAILS'

export const UPDATE_AFFILIATE = 'dbc/affiliate/UPDATE_AFFILIATE'
export const UPDATE_AFFILIATE_SUCCESS = 'dbc/affiliate/UPDATE_AFFILIATE_SUCCESS'
export const UPDATE_AFFILIATE_ERROR = 'dbc/affiliate/UPDATE_AFFILIATE_ERROR'

export const DELETE_CUSTOMER = 'empire/users/DELETE_CUSTOMER'

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  token: null,
  phase: INIT,
  error: null,
  message: null,
  addAffiliatePhase: INIT,
  user: null
}

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = '' // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues))
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line complexity, max-statements
 
export default function (state = new InitialState(), action = {}) {
  switch (action.type) {

    case ADD_AFFILIATE: {
      return state
        .set('addAffiliatePhase', LOADING)
        .set('addAffiliateError', null)
    }

    case ADD_AFFILIATE_SUCCESS: {
      const { payload } = action
      return state
        .set('addAffiliatePhase', SUCCESS)
        .set('user', payload)
        .set('addAffiliateError', null)
    }

    case ADD_AFFILIATE_ERROR: {
      const { payload } = action
      return state
        .set('addAffiliateError', payload.error)
        .set('addAffiliatePhase', ERROR)
    }



    default: {
      return state
    }
  }
}


/***********************************
 * Action Creators
 ***********/

export const getAllUsers = payload => {
  return {
    type: GET_ALL_REQUESTS,
    payload: api.getAllUsers(payload)
  }
}

export const getRequestDetails = payload => {
  return {
    type: GET_ALL_REQUESTS,
    payload: api.getRequestDetails(payload)
  }
}



export const addAffiliate = credentials => {
  return {
    type: ADD_AFFILIATE,
    payload: api.addAffiliate(credentials)
  }
}

export const updateAffiliate = credentials => {
  return {
    type: UPDATE_AFFILIATE,
    payload: api.updateAffiliate(credentials)
  }
}

export const getAffiliateDetails = payload => {
  return {
    type: GET_AFFILIATE_DETAILS,
    payload: api.getAffiliateDetails(payload)
  }
}

export const deleteCustomer = payload => {
  return {
    type: DELETE_CUSTOMER,
    payload: api.deleteCustomer(payload)
  }
}


/***********************************
 * Epics
 ***********************************/

const addAffiliateEpic = action$ =>
  action$.pipe(
    ofType(ADD_AFFILIATE),
    mergeMap(action => {
      return fromPromise(api.addAffiliate(action.payload)).pipe(
        flatMap(payload => [
          {
            type: ADD_AFFILIATE_SUCCESS,
            payload
          }
        ]),
        catchError(error =>
          of({
            type: ADD_AFFILIATE_ERROR,
            payload: { error }
          })
        )
      )
    })
  )


export const affilateEpic = combineEpics(
  addAffiliateEpic
)