import { connect } from 'react-redux'
import EditContentPagesComponent from './component'
import { getMe, UpdateCmsContent, getCmsContent, getAllUnreadMessage } from '../../store/user/duck'

const EditContentPagesContainer = connect(
  // Map state to props
  state => ({
  }),
  {
    getMe,
    UpdateCmsContent,
    getCmsContent,
    getAllUnreadMessage
  }
  // Map actions to dispatch and props
)(EditContentPagesComponent)

export default EditContentPagesContainer
