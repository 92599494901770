import React, { PureComponent } from 'react'
import TopNavigation from './../TopNavigation/component'
import SidebarNavigation from './../SidebarNavigation/component'
import { Link } from 'react-router-dom'
import Pagination from "react-js-pagination";
import { Spin, Select, message, DatePicker, Button } from "antd";
import { EditOutlined, RedoOutlined } from '@ant-design/icons';
import './styles.scss'

const { Option } = Select;
const { RangePicker } = DatePicker;

export default class Requests extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      all_requests: [],
      completed_requests: 0,
      search_value: '',
      activePage: 1,
      itemsCountPerPage: 10,
      totalItemsCount: 0,
      pageRangeDisplayed: 5,
      link_path: 'https://dbcapi.webvilleedemo.xyz',
      alert: null,
      searchType: "",
      searchStatus: "",
      searchFromDate: "",
      searchToDate: "",
      loading: false
    }

    this.handlePageChange = this.handlePageChange.bind(this)
  }

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, () => {
      this.getAllRequests()
    });
  }

  async componentDidMount() {
    document.title = 'Requests | DBC'
    await this.getAllRequests();
  }

  searchReset= () => {
    // this.setState({ searchStatus: '', searchFromDate: '', searchToDate: '', searchType: '', activePage: 1 }, ()=>this.getAllRequests());
    window.location.reload(false)
  };

  searchSubmit = async () => {
    await this.getAllRequests();
  }

  getAllRequests = async () => {
    this.setState({ loading: true });
    const postData = {
      request_status: this.state.searchStatus,
      from_date: this.state.searchFromDate,
      to_date: this.state.searchToDate,
      service_type: this.state.searchType,
      page_no: this.state.activePage
    }

    const resData = await this.props.getAllRequests(postData)

    if (resData.value.status === 200) {
      //this.setState({all_requests:[]})
      this.setState({ all_requests: resData.value.data.service_request_data })
      //used for pagination
      //this.setState({totalItemsCount:40})
      this.setState({ totalItemsCount: resData.value.data.request_page_count })
      // this.setState({completed_requests:0});
      this.setState({ completed_requests: resData.value.data.request_complete_count });
      this.setState({ loading: false })

    } else if (resData.value.status === 401) {
      this.setState({ loading: false })
      localStorage.clear();
      this.props.history.push('/login');
    } else {
      //console.log("status=", resData.value.status)
      this.setState({ loading: false })
    }
  }

  handleChangedType = searchType => {
    this.setState({ searchType , activePage: 1 });
  };

  handleChangedStatus = searchStatus => {
    this.setState({ searchStatus, activePage: 1 });
  };

  toTimestamp = (strDate) => {
    return Date.parse(strDate);
  }



  datesGet = (dates, dateString) => {
    if (dateString) {
      // console.log(dateString, 'jkjkhjk')
      let datstringaa = dateString.toString();
      let arr = datstringaa.split(",");
      // console.log(arr[0] + ' 00:00:00', arr[1] + ' 23:59:59')
      this.setState({ searchFromDate: this.toTimestamp(arr[0] + ' 00:00:00'),  activePage: 1  },);
      this.setState({ searchToDate: this.toTimestamp(arr[1] + ' 23:59:59'), activePage: 1  });
    } else {
      this.setState({ searchFromDate: "" });
      this.setState({ searchToDate: "" });
    }
  }





  render() {
    const { all_requests, completed_requests, loading } = this.state
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation />
        <TopNavigation {...this.props} />
        <Spin spinning={loading}>
          <main className="dashboard-layout-content affiliates_wrapper">
            <div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="dashboard">Home</Link></li>
                  <li className="breadcrumb-item active">Requests</li>
                </ol>
              </nav>
              <div className="row">
                <div className="col-md-3">

                  <Select placeholder="Service Type" onChange={this.handleChangedType}>
                    <Option value="">Choose service type</Option>
                    <Option value="is_new">New</Option>
                    <Option value="is_renewal">Renew</Option>
                    <Option value="is_modify">Modify</Option>
                    <Option value="is_cancellation">Cancel</Option>
                  </Select>
                </div>

                <div className="col-md-3">

                  <Select placeholder="Request Status" onChange={this.handleChangedStatus}>
                    <Option value="">Choose Status</Option>
                    <Option value="str">Requested</Option>
                    <Option value="1">Pickup/drop-off</Option>
                    <Option value="2">Processing</Option>
                    <Option value="3">Delivered</Option>
                  </Select>
                </div>

                <div className="col-md-6">
                  <div className="daterangewithbutton">
                    <RangePicker renderExtraFooter={() => 'extra footer'} onChange={this.datesGet} />
                    <button type="button" className="ant-btn btnn ant-btn-primary" onClick={this.searchSubmit}>SEARCH</button>
                    <button style={{'margin-left':2}} type="button" className="ant-btn btnn ant-btn-primary" onClick={this.searchReset}> <span><RedoOutlined /></span></button>
                  </div>
                </div>

              </div>

            </div>

            <div className="clearBoth"></div>

            <div className="affMainData">
              <div className="content">
                <div className="page-header d-flex justify-content-between align-items-center">
                  <h6 className="heading-title">Requests</h6>
                  <span className="FloatRight">No.of completed requests : {completed_requests} </span>
                </div>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">APPLICANT NAME</th>
                        <th scope="col">EMAIL</th>
                        <th scope="col">DATE & TIME</th>
                        <th scope="col">SERVICE Name</th>
                        <th scope="col">SERVICE TYPE</th>
                        <th scope="col">PREMIUM</th>
                        <th scope="col">STATUS</th>
                        <th scope="col">View</th>
                      </tr>
                    </thead>
                    <tbody>
                      {all_requests.length > 0 && all_requests.map((item, index) =>

                        <tr key={index}>
                          <th scope="row">{item.user_name}</th>
                          <td>{item.user_email}</td>
                          <td>{(new Date(item.create_on)).toLocaleDateString()} {(new Date(item.create_on)).toLocaleTimeString()}</td>

                          <td>{item.service_name}</td>
                          <td>{(item.service_type === 'is_new') ? 'New' : (item.service_type === 'is_renewal') ? 'Renewal' : (item.service_type === 'is_modify') ? 'Modify' : (item.service_type === 'is_cancellation') ? 'Cancel' : ''}</td>
                          <td>{item.total_premium} AED</td>
                          <td><Button  className={item.request_status === 0 ? 'requested' : item.request_status === 1 ? 'documentpickup' : item.request_status === 2 ? 'processing' : 'delivered'}
                           size="small" ><Link to={`/tracking-details/${item._id}`} >{item.request_status === 0 ? 'Requested' : item.request_status === 1 ? 'Document Pickup' : item.request_status === 2 ? 'Processing' : 'Delivered'}</Link></Button></td>
                          <td><a href={`/request-details/${item._id}`} ><EditOutlined /></a></td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {this.state.totalItemsCount > 0 ?
              <div itemClass="page-item">
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.itemsCountPerPage}
                  totalItemsCount={this.state.totalItemsCount}
                  pageRangeDisplayed={this.state.pageRangeDisplayed}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div> : ''
            }
          </main>
        </Spin>
      </div>
    )
  }
}
