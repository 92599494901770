import React, { PureComponent } from 'react'
import TopNavigation from '../TopNavigation/component'
import SidebarNavigation from '../SidebarNavigation/component'
import { Link } from 'react-router-dom'
import Pagination from "react-js-pagination";
import {  message, Modal, Spin } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import './styles.scss'

export default class Users extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      all_users: [],
      search_value: '',
      activePage: 1,
      itemsCountPerPage: 10,
      totalItemsCount: 0,
      pageRangeDisplayed: 5,
      link_path: 'https://dbcapi.webvilleedemo.xyz',
      loading:false,
      visible: false,
      onCancel: false,
      user_id:''
     
    }
    this.search = this.search.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
  }

  handlePageChange=(pageNumber)=> {
    this.setState({activePage: pageNumber}, () => {
      this.getAllUsers()
    });
  }

  componentDidMount() {
    document.title = 'Customers | DBC'

    this.getAllUsers(); 

  }

  getAllUsers= async()=> {

    this.setState({loading:true});

    const postData = {
      search_value: this.state.search_value,
      page_no: this.state.activePage,
      role: 3
    }
    const resData = await this.props.getAllUsers(postData)

    if(resData.value.status === 200){
      this.setState({loading:false})
      
      //this.setState({all_users:[]})
      this.setState({all_users:resData.value.data.User_data})
      //used for pagination
      //this.setState({totalItemsCount:40})
      this.setState({totalItemsCount:resData.value.data.User_count})

    }else if (resData.value.status === 401) {
      this.setState({loading:false})
      localStorage.clear();
      this.props.history.push('/login');
    }else{
      this.setState({loading:false})
    }
  }


  search=(event) => {
    this.setState({search_value:event.target.value, activePage: 1}, () => {
      this.getAllUsers()
    });
  }

   showDeleteModal = (userId) => {
    this.setState({
      user_id: userId,
    });

    this.setState({
      visible: true,
    });
  };

  handlePopupCancel = () => {
    this.setState({
      user_id: '',
    });
    this.setState({ visible: false });
  };

  onDelete = async() => {
    const postData = {
      user_id: this.state.user_id,
    }

    const datasuccess = await this.props.deleteCustomer(postData);
    if (datasuccess.value.status === 200) {
      message.success(datasuccess.value.msg);
      setTimeout(() => {
          this.getAllUsers(); 
          this.setState({ loading: false, visible: false });
      }, 1000);
    }else if (datasuccess.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/login');
    } else {
      this.setState({ loading: false});
      message.error(datasuccess.value.msg);
    }
  }



  render() {
    const { all_users, loading, visible } = this.state
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation />
        <TopNavigation {...this.props} />
        <Spin spinning={loading}>
        <main className="dashboard-layout-content affiliates_wrapper">
          <div>
            <span><Link to="control_panel/dashboard">Home</Link>/Customers</span>
                  
            <span className="md-form active-cyan-2 mb-3 searchInput">
                  <input className="form-control" type="text" placeholder="Search" aria-label="Search" onKeyUp={this.search}/>
            </span>
          </div>

          <div className="clearBoth"></div>

          <div className="affMainData">
            <div className="content">
              <div className="page-header d-flex justify-content-between align-items-center">
                <h6 className="heading-title">Customers</h6>
                
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">CUSTOMER NAME</th>
                      <th scope="col">EMAIL</th>
                      <th scope="col"> REGISTER DATE</th>
                      <th scope="col">STATUS</th>
                      {/* <th scope="col">View</th> */}
                      <th scope="col">DELETE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {all_users.length>0 && all_users.map((item, index) =>
                      <tr key={ index }>
                        <th scope="row">{item.user_name}</th>
                        <td>{item.user_email}</td>
                    <td>{ (new Date(item.create_on)).toLocaleDateString() } { (new Date(item.create_on)).toLocaleTimeString()}</td>
                        <td>{item.status === 1 ? 'ACTIVE' : item.status === 2 ? 'DELETED' : 'PENDING'}</td>
                        {/* <td><Link to={{ pathname: 'request-details/'+item._id }}><button type="button" className="ant-btn ant-btn-primary">Edit</button></Link></td> */}
                        <td><DeleteOutlined onClick={() => this.showDeleteModal(item._id)} /></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {this.state.totalItemsCount > 0 ?
            <div itemClass="page-item">
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.itemsCountPerPage}
                totalItemsCount={this.state.totalItemsCount}
                pageRangeDisplayed={this.state.pageRangeDisplayed}
                onChange={this.handlePageChange.bind(this)}
              />
            </div> : ''
          }


          <Modal
          visible={visible}
          title="Delete Customer"
          okText="Delete"
          cancelText="Cancel"
          onCancel={this.handlePopupCancel}
          onOk={this.onDelete}
          >

          <div> <h5>Are you sure you want to delete this customer?</h5> </div>
      
         </Modal> 

        </main></Spin>
      </div>
    )
  }
}
