import React, { PureComponent } from 'react'
// import PropTypes from 'prop-types'
import Logo from '../../images/logo.png'
import { Link } from 'react-router-dom'
import { Menu, Dropdown } from 'antd'
import { DownCaretIcon } from '../../components/icons'
import './styles.scss'
import { 
  NotificationIcon
} from '../../components/icons';

import io from 'socket.io-client';
const ENDPOINT = "https://dbcapi.webvilleedemo.xyz";

const logout = () => {
  localStorage.clear();
};

const menu = (
  <Menu>
    <Menu.Item key="0">
      <Link to={'/updateprofile'}>Profile</Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to={'/settings'}>Settings</Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link onClick={logout} to={'/login'}>Logout</Link>
    </Menu.Item>
  </Menu>
);

export default class TopNavigationComponent extends PureComponent {

  static propTypes = {
    // PropTypes go here
  }

  constructor(props) {
    super(props)

    this.state = {
      user: null,
      show_unread_message: false,
      unread_message_count: 0,
      all_unread_messages: [],
      socket:null,
      Hostname: 'https://dbcapi.webvilleedemo.xyz/uploads/',
    }
  }

  componentDidMount() {
    this.props.getMe();
    const socket = io.connect(ENDPOINT);
    const admin_id = window.localStorage.getItem('user_id')
    if(admin_id){
      this.setState({ socket })
      socket.emit('join_chat', admin_id);
      socket.on("join_chat_ack",function(data){
      });
      setInterval(() => {
        socket.emit('unread_messages_count', admin_id);
      }, 1500);
      socket.on("unread_messages_count_ack",(data)=>{
        // console.log("unread_messages_count_ack",data.data.count)
        this.setState({unread_message_count: data.data.count})
      })

      this.getAllUnreadMessage();
    }
  }

  // updateUnreadMessageCount(socket, admin_id){
  //   socket.emit('unread_messages_count', admin_id);
  //   socket.on("unread_messages_count_ack",(data)=>{
  //     this.setState({unread_message_count: data.data.count})
  //   })
  // }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user !== prevState.user) {
      return {
        user: nextProps.user
      }
    } else {
      return null
    }
  }

  unreadMessagePopupToggle = () => {
    (this.state.show_unread_message === true)? this.setState({ show_unread_message: false}) : this.setState({ show_unread_message: true},()=>this.getAllUnreadMessage());
  }


  getAllUnreadMessage = async () => {

    const postData = {
      user_id: window.localStorage.getItem('user_id'),
    }

    const resData = await this.props.getAllUnreadMessage(postData)

    if (resData.value.status === 200) {
      // console.log(resData.value.data, 'kkk')
      this.setState({ all_unread_messages: resData.value.data })
    }else if (resData.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/login');
    } else {
      this.setState({ messageerr: resData.value.msg })
    }
  }



  readMessage=(requestId, recieverId)=>{
    const messageData = {
      recieverId: recieverId,
      requestId: requestId,
    }

    if(this.state.socket !== '' && this.state.socket !== null){
      this.state.socket.emit('read_messages_ack', messageData);
      this.state.socket.on("recieve_message_ack", (data) => {
      });
    }
  }

  render() {
    const { user } = this.props
    const { all_unread_messages, Hostname } = this.state
    // console.log(all_unread_messages, 'allunread')
    return (
      <header className="header topnavigation-layout-header d-flex align-items-center">
        {/* <div className="topnavigation-layout-search">
          <div className="topnavigation-layout-wrapper">
            <div className="search-wrapper-open-container">
              <ul className="search-wrapper-options-menu">
                <div className="options-menu-typeahead-header"><img className="options-menu-typeahead-icon" alt=""/> Buildings</div>
                  <li className="search-wrapper-options-menu-item">
                    <Link to={`/`} target="_blank">
                      <p className="options-menu-item-title">Title</p>
                      <p className="options-menu-item-subtitle">subtitle</p>
                    </Link>
                  </li>
              </ul>
            </div>
          </div>
        </div> */}

        <div className="sidebar-layout-sider__logo">
            <img className="img-fluid" src={Logo} alt="DBC"/>
        </div>

        

        <div className="topnavigation-layout__profile ml-auto">
          <div className="headerMessageCount">
            <div className="dropdown">
              <button onClick={this.unreadMessagePopupToggle} className="dropbtn"><NotificationIcon/><span>{this.state.unread_message_count}</span></button>
              <div id="myDropdown" className={this.state.show_unread_message === true ? "dropdown-content show" : "dropdown-content" } >
                {all_unread_messages.length>0 && all_unread_messages.map((item, index) =>
                  <span  key={ index }>
                  
                  <a onClick={()=>this.readMessage(item.requestId, item.recieverId)} href={`/request-details/${item.requestId}`} >
                  <i>{item.sender_name}</i> <br></br>
                  {(item.type === 1) ? item.message
                    : (item.type === 2) ? <img style={{ width: 50, height: 50 }} alt="example" src={Hostname + item.message} /> :
                      (item.type === 3) ? 'Pdf File': ''
                  }
                  <br></br><i>{item.service_name}</i>
                  </a>
                  </span>
                ).reverse()}
              </div>
            </div>
          </div>
          <Dropdown overlay={menu} trigger={['click']} overlayClassName="profile--dropdown--overlay">
            <a className="ant-dropdown-link d-flex align-items-center" href="/">
              {/* <div className="avatar">
                <p className="name_text">{user && user.user_name.substr(0,1)}</p>
              </div> */}
              <div className="avatar-info">
                <div className="avatar-name">Welcome Admin</div>
                <div  className="avatar-email">{user ? user.user_email : ''}</div>
              </div>
              <DownCaretIcon/>
            </a>
          </Dropdown>
        </div>
      </header>
    )
  }
}
