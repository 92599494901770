import { connect } from 'react-redux'
import updatePromoCodeComponent from './component'
import { getMe, getAllUnreadMessage } from '../../store/user/duck'
import {  updatePromoCode, getPromoCodeByid } from '../../store/promocode/duck'

const updatePromoCodeContainer = connect(
  // Map state to props
  state => ({
  }),
  {
    updatePromoCode,
    getPromoCodeByid,
    getMe,
    getAllUnreadMessage
  }
  // Map actions to dispatch and props
)(updatePromoCodeComponent)

export default updatePromoCodeContainer
