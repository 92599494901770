import React, { PureComponent } from 'react'
import TopNavigation from './../TopNavigation/component'
import SidebarNavigation from './../SidebarNavigation/component'
import { message } from 'antd'
import "./styles.scss";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class AddAffiliateComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      user_name: '',
      user_email: '',
      password: '',
      user_contact: '',
      premium_request: '',
      err: {},
      message: '',
      disable: true,
      messageerr: '',
      disabled: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.addAffiliate = this.addAffiliate.bind(this)

  }

  handleChange(e) {
    let disable = false
    const self = this
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (self.state.user_name === '' || self.state.user_name === undefined) {
        disable = true
      }
      if (self.state.user_email === '' || self.state.user_email === undefined) {
        disable = true
      }
      if (self.state.password === '' || self.state.password === undefined) {
        disable = true
      }
      if (self.state.user_contact === '' || self.state.user_contact === undefined) {
        disable = true
      }
      if (self.state.premium_request === '' || self.state.premium_request === undefined) {
        disable = true
      }
      self.setState({ disable })
    })
  }

  componentDidMount() {
    document.title = 'Add Affiliate | DBC'
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.addAffiliatePhase === 'success') {
      if (this.props.user) {
        if (this.props.user.status === 200) {

          this.props.history.push('/affiliatemanagment')
        } else if (this.props.user.status === 401) {
          localStorage.clear();
          this.props.history.push('/login');
        } else {
          this.setState({ messageerr: this.props.user.msg })
        }
      }
    }
  }


  addAffiliate = async () => {
    
    const { user_name, user_email, password, user_contact, premium_request } = this.state

    /* eslint-disable */
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const err = {}

    if (user_name === '' || user_name === null || user_name.trim() === '') {
      err.user_name = 'Name is required.'
    }


    if (user_email === '' || user_email === null || user_email.trim() === '') {
      err.user_email = 'Email is required.'
    }

    if (user_email && user_email !== '') {
      const testR = emailRegex.test(String(this.state.user_email).toLowerCase())
      if (!testR) {
        err.user_email = 'Email is invalid.'
      }
    }

    if (password === '' || password === null || password.trim() === '') {
      err.password = 'Password is required.'
    }

    if (user_contact === '' || user_contact === null || user_contact.trim() === '') {
      err.user_contact = 'Mobile number is required.'
    }

    if (user_contact.length < 10) {
      err.user_contact = 'Mobile number must be 10 number.'
    }

    if (user_contact && user_contact !== '') {
      const contactRegex = /^[0-9\b]+$/

      const testR1 = contactRegex.test(this.state.user_contact)
      if (!testR1) {
        err.user_contact = 'Mobile number must be integer.'
      }
    }

    if (premium_request === '' || premium_request === null || premium_request.trim() === '') {
      err.premium_request = 'Premium request is required.'
    }


    this.setState({ err })
    if (!Object.keys(err).length) {
      this.setState({ disabled: true });
      if (user_name && user_email && password && user_contact && premium_request) {
        const addAffiliateData = {
          user_name: this.state.user_name,
          user_email: this.state.user_email,
          password: this.state.password,
          user_contact: this.state.user_contact,
          premium_request: parseInt(this.state.premium_request)
        }


        const resData = await this.props.addAffiliate(addAffiliateData);

        if (resData.value.status === 200) {
          //added successfully

          toast.success("Added successfully", {
            position: toast.POSITION.TOP_CENTER
          });

          setTimeout(() => {
            this.props.history.push("/affiliatemanagment");
          }, 3000);


        } else if (resData.value.status === 401) {
          localStorage.clear();
          this.props.history.push('/login');
        } else if (resData.value.status === 201) {
          //email already exist 
          toast.error("Email already exist use another one", {
            position: toast.POSITION.TOP_CENTER
          });
          setTimeout(() => {
            this.setState({disabled: false});
          }, 3000);
        } else {
          // some error in server
          toast.error("Some error occured please try again", {
            position: toast.POSITION.TOP_CENTER
          });
          setTimeout(() => {
            this.setState({disabled: false});
          }, 3000);
        }

      } else {
        message.error('Please insert valid input')
      }
    }
  }




  render() {

    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content affiliates_wrapper">
          <div>
            <span><Link to="/affiliatemanagment">Affiliates</Link>/Add New Affiliate</span>
          </div>

          <div className="clearBoth"></div>

          {this.state.messageerr ?
            <span className="invalid-feedback">
              {this.state.messageerr}
            </span>
            : ''
          }

          <div className="affMainData">
            <div className="content">
              <div className="form-group">
                <input type="text" placeholder="Name" className="form-control" name="user_name"
                  value={this.state.user_name}
                  onChange={this.handleChange.bind(this)}
                  required />
                {this.state.err.user_name ?
                  <span className="invalid-feedback">
                    {this.state.err.user_name}
                  </span>
                  : ''
                }
              </div>
              <div className="form-group">
                <input type="email" placeholder="Email" className="form-control" name="user_email"
                  value={this.state.user_email}
                  onChange={this.handleChange.bind(this)}
                  required />
                {this.state.err.user_email ?
                  <span className="invalid-feedback">
                    {this.state.err.user_email}
                  </span>
                  : ''
                }
              </div>
              <div className="form-group">
                <input type="password" placeholder="Password" className="form-control" name="password"
                  value={this.state.password}
                  onChange={this.handleChange.bind(this)}
                  required />
                {this.state.err.password ?
                  <span className="invalid-feedback">
                    {this.state.err.password}
                  </span>
                  : ''
                }
              </div>
              <div className="form-group">
                <input type="text" placeholder="Mobile Number" className="form-control" name="user_contact"
                  value={this.state.user_contact}
                  onChange={this.handleChange.bind(this)}
                  required minLength="10" maxLength="10" />
                {this.state.err.user_contact ?
                  <span className="invalid-feedback">
                    {this.state.err.user_contact}
                  </span>
                  : ''
                }
              </div>
              <div className="form-group">
                <input type="number" min="0" placeholder="Add premium per request" className="form-control" name="premium_request"
                  value={this.state.premium_request}
                  onChange={this.handleChange.bind(this)}
                  required maxLength="5" />
                {this.state.err.premium_request ?
                  <span className="invalid-feedback">
                    {this.state.err.premium_request}
                  </span>
                  : ''
                }
              </div>
            </div>
          </div>

          <div>
            <button type="button" disabled={this.state.disabled} onClick={this.addAffiliate} onKeyDown={this.addAffiliate} className="marginleft ant-btn btnn ant-btn-primary floatLeft">{this.state.disabled ? 'Adding...' : 'Add'}</button>
          </div>
        </main>
        <ToastContainer />

      </div>
    );
  }
}

