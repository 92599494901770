import React, { PureComponent } from 'react'
import TopNavigation from '../TopNavigation/component'
import SidebarNavigation from '../SidebarNavigation/component'
import { Modal, Popover, Button, Select, Upload, message, Card, Row, Col, Spin, DatePicker, Input } from 'antd';
import "./styles.scss";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EditOutlined, EyeOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import {
  AttachFile
} from '../../components/icons';

import {
  // ScaleIcon, 
  CloseIcon,
  SendMessageIcon

} from '../../components/icons';
import moment from 'moment';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';


const { RangePicker } = DatePicker;

//import io from 'socket.io-client';
//const ENDPOINT = "https://dbcapi.webvilleedemo.xyz";

//import io from 'socket.io-client';

const { Option } = Select;
const { Meta } = Card;
const { Dragger } = Upload;

//const socket = io('https://dbcapi.webvilleedemo.xyz');

export default class requestDetailsComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      service_req_id: "",
      request_detail_data: {},
      dynamic_field: [],
      documents: [],
      err: {},
      message: '',
      disable: true,
      messageerr: '',
      visible: false,
      imageModalvisible: false,
      uploadModalvisible: false,
      statusType: '',
      doc_type: '',
      loop: '',
      docData: {
        files: []
      },
      Hostname: 'https://dbcapi.webvilleedemo.xyz/',
      loading: false,
      chat_popup_display_block: false,
      current_message: '',
      chat_history_data: [],
      per_page: 10,
      dateTime: '',
      addressauto: 'Exec Pro Business Management',
      latLng: { lat: 25.2577261, lng: 55.3326906 },
      trackingUrl: '',
      visiblemodal: false,
      visiblePick: false,
      loadingdrop: false,
      loadingpick: false,
      loadingsubmit: false,
      disabled : false,
      alltrackDetails: {}
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleMessageChange = this.handleMessageChange.bind(this)

  }
  handleChange(e) {
    let disable = false
    const self = this
    this.setState({ [e.target.name]: e.target.value }, () => {
      self.setState({ disable })
    })
  }

  componentDidMount() {
    document.title = 'Request Details | DBC'
    console.log(this.props.match.path)

    const servicereq_id = this.props.match.params.id;

    this.getRequestDetails(servicereq_id);

    this.props.socket.on("send_message_ack", (data) => {
      if (data.status === 200) {
        // console.log('send_message_ack=>', data.data)
        if (this.state.chat_history_data.map(({ _id }) => _id).indexOf(data.data._id) === -1) {
          this.setState({ chat_history_data: [...this.state.chat_history_data, data.data] })
        }
        this.setState({ current_message: '' });
        this.scrollToBottom()
      }
    });

    
    if(this.props.match.path ==="/request-details/:id"){
      this.props.socket.on("recieve_message", (data) => {
        if (data.status === 200) {
          this.setState({ chat_history_data: [...this.state.chat_history_data, data.data] })
          console.log('recieve_message=>', data.data)
          const messageData = {
            recieverId: data.data.recieverId,
            requestId: data.data.requestId,
          }

          if (this.state.socket !== '' && this.state.socket !== null) {
            this.props.socket.emit('read_messages_ack', messageData);
          }
          this.scrollToBottom()
        }
      })
    }
    
    this.getAllChatHistory();
  }

  componentWillUnmount(){
    this.props.socket.off('recieve_message')
  }

  openChatPopup = () => {

    this.getAllChatHistory();
    this.scrollToBottom();
    this.setState({
      chat_popup_display_block: true,
    });
  };

  scrollToBottom = () => {
    const chat = document.getElementById("chatList");
    // chat.scrollTop = chat.scrollHeight;
    chat && chat.scrollTo(0, chat.scrollHeight);
  };

  handleScrollToElement = () => {
    var elmnt = document.getElementById("chatList");
    var y = elmnt.scrollTop;
    var i;
    if (y === 0) {
      i = this.state.per_page + 10;
      this.setState({ per_page: i }, () => {
        this.getAllChatHistory()
      })

    }
  }

  getRequestDetails = async (id) => {
    this.setState({ loading: true });
    const postData = {
      service_req_id: id,
    }

    const resData = await this.props.getRequestDetails(postData)
    if (resData.value.status === 200) {
      this.setState({ request_detail_data: resData.value.data, dynamic_field: resData.value.data.dynamic_field, loading: false })
      this.setState({ documents: JSON.parse(resData.value.data.documents) })
    } else if (resData.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/login');
    } else {
      this.setState({ messageerr: resData.value.msg, loading: false })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.updateAffiliatePhase === 'success') {
      if (this.props.user) {
        if (this.props.user.status === 200) {

          this.props.history.push('/requests')
        } else if (this.props.user.status === 401) {
          localStorage.clear();
          this.props.history.push('/login');
        } else {
          this.setState({ messageerr: this.props.user.msg })
        }
      }
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  showImageModal = (doc_type, loop) => {
    const docData = this.findObjectByKey(this.state.documents, '_id', doc_type);
    this.setState({
      docData: docData,
      doc_type: doc_type,
      loop: loop,
      imageModalvisible: true,
    });
  };
  showUploadModal = (doc_type, loop) => {
    this.setState({
      doc_type: doc_type,
      loop: loop,
      uploadModalvisible: true,
    });
  };

  handleOk = e => {
    this.setState({
      visible: false,
    });
  };
  handleImageOk = e => {
    this.setState({
      imageModalvisible: false,
    });
  };

  handleUploadOk = async (e) => {
    this.setState({ loading: true });
    const updateDocData = {
      service_req_id: this.props.match.params.id,
      documents: JSON.stringify(this.state.documents)
    }
    const resData = await this.props.UpdateDocuments(updateDocData);
    if (resData.value.status === 200) {
      // message.success(resData.value.msg);
      toast.success(resData.value.msg, {
        position: toast.POSITION.TOP_CENTER
      });
      setTimeout(() => {
        this.setState({
          loading: false, uploadModalvisible: false, doc_type: '',
          loop: '',
        });
      }, 3000);
    } else if (resData.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/login');
      this.setState({ loading: false });
    } else {
      // message.error(resData.value.msg);
      toast.error(resData.value.msg, {
        position: toast.POSITION.TOP_CENTER
      });
      this.setState({ uploadModalvisible: false, loading: false })
    }
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };
  handleImageCancel = e => {
    this.setState({
      imageModalvisible: false,
    });
  };
  handleUploadCancel = e => {
    this.setState({
      uploadModalvisible: false,
    });
  };

  handleChangedStatus = statusType => {
    this.setState({ statusType });
  };

  findObjectByKey(array, key, value) {
    for (var i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return array[i];
      }
    }
    return null;
  }

  async onChangeModal(file) {
    const { loop } = this.state
    const documents = [...this.state.documents];
    if (file.file.status === "done") {
      this.setState({ loading: true });
      // const input = { files: [file.file.originFileObj] };
      if (file.file.response.status === 200) {
        // console.log(file.file.response.data.filepath, 'helll')
        documents[loop].files.push({ pic_type: "other", value: file.file.response.data.filepath })
        this.setState({ documents, loading: false })
        toast.success(`${file.file.name} file uploaded successfully`, {
          position: toast.POSITION.TOP_CENTER
        });
      } else if (file.file.response.status === 401) {
        localStorage.clear();
        this.props.history.push('/signin');
      } else {
        toast.error(`Please try again`, {
          position: toast.POSITION.TOP_CENTER
        });
        this.setState({ loading: false });
      }
      return;
    } else if (file.file.status === 'error') {
      toast.error(`${file.file.name} file upload failed.`, {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }



  closeChatPopup = () => {
    this.setState({
      chat_popup_display_block: false,
    });
  };

  handleMessageChange(e) {
    let disable = false
    const self = this
    this.setState({ [e.target.name]: e.target.value }, () => {
      self.setState({ disable })
    })
  }

  sendMessage = () => {
    if (!this.state.current_message) {
      return;
    }
    const customerId = this.state.request_detail_data.user_id;

    if (this.state.current_message !== '' && window.localStorage.getItem('user_id') !== '' && customerId !== '' && customerId !== undefined && this.props.match.params.id !== '') {

      const messageData = {
        recieverId: customerId,
        senderId: window.localStorage.getItem('user_id'),
        message: this.state.current_message,
        requestId: this.props.match.params.id,
        service_name: this.state.request_detail_data.service_name
      }

      const { socket } = this.props;

      socket.emit('send_msg', messageData);

    }
  };


  getAllChatHistory = async () => {

    const postReqData = {
      requestId: this.props.match.params.id,
      user_id: window.localStorage.getItem('user_id'),
      page_no: 0,
      perPage: this.state.per_page
    }

    const resData = await this.props.getAllChatHistory(postReqData)
    if (resData.value.status === 200) {
      this.setState({ chat_history_data: resData.value.data })

    } else if (resData.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/login');
    } else {
      this.setState({ messageerr: resData.value.msg })
    }
    if (this.state.per_page === 10) {
      this.scrollToBottom()
    }
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.sendMessage(this.state.request_detail_data.user_id);
    }
  }


  onSendFile = (file) => {
    this.setState({ loading: true });
    const customerId = this.state.request_detail_data.user_id;
    if (file.file.status === "done") {
      // console.log(file);
      var extension = file.file.name.substring(file.file.name.lastIndexOf('.') + 1);
      let fileType;
      (extension === "pdf") ? fileType = 3 : fileType = 2;
      if (file.file.response.status === 200) {
        // console.log(file.file.response.data.filepath, 'helll')
        const messageData = {
          recieverId: customerId,
          senderId: window.localStorage.getItem('user_id'),
          requestId: this.props.match.params.id,
          service_name: this.state.request_detail_data.service_name,
          file_data: file.file.response.data.filepath,
          file_type: fileType, // 2 image 3 pdf
          // ext: extension,
        }
        // console.log(messageData, 'yyyyyyyyy');
        this.props.socket.emit('send_msg_file', messageData);
        this.setState({ loading: false });
      } else if (file.file.response.status === 401) {
        localStorage.clear();
        this.props.history.push('/signin');
      } else {
        // message.error("Please try again")
        toast.error(`Please try again`, {
          position: toast.POSITION.TOP_CENTER
        });
        this.setState({ loading: false });
      }
      return;
    } else if (file.file.status === 'error') {
      // message.error(`${file.file.name} file upload failed.`);
      toast.error(`${file.file.name} file upload failed.`, {
        position: toast.POSITION.TOP_CENTER
      });
      this.setState({ loading: false });
    }
  }

  async DownloadZip(id) {
    const data = {
      service_req_id: id
    }
    // console.log(data);
    const resData = await this.props.DocumentsZip(data)
    if (resData.value.status === 200) {
      // console.log(resData.value.data);
      var url = 'https://dbcapi.webvilleedemo.xyz' + `${resData.value.data}`;
      //  window.open(url, 'Download');  
      window.location.href = url;
    } else if (resData.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/login');
    } else {
      this.setState({ messageerr: resData.value.msg, loading: false })
    }
  }
  onChangeDate = (date, dateString) => {
    // console.log(date, dateString);
    const datet= new Date(dateString); 
    const dt = datet.toISOString();
    this.setState({ dateTime: dt })
    // console.log(dt, 'lll', datet);
  }

  handlesubmit = () => {
    const { dateTime, request_detail_data, addressauto, latLng } = this.state
    // console.log(dateTime, addressauto, latLng);

    if (dateTime && addressauto) {
      this.setState({disabled: true});
      fetch("https://api.staging.quiqup.com/oauth/token", {
        "method": "POST",
        "headers": {
          "Content-Type": "application/json",
          "accept": "application/json"
        },
        "body": JSON.stringify({
          "grant_type": "client_credentials",
          "client_id": "7d267325aee321c82db3dd3fbbe3fd5949ef22975c8f22b8147bcff4d93ab581",
          "client_secret": "695233d31d995e21d9f4838162dffeba63990648c0001d7a5ecc3f157a81c6e8"
        })
      })
        .then(response => response.json())
        .then(response => {
          this.setState({ loadingsubmit: true });
          // console.log(response, 'response')
          if (response.access_token) {
            // console.log(response);
            fetch("https://api.staging.quiqup.com/partner/jobs", {
              "method": "POST",
              "headers": {
                "Authorization": `Bearer ${response.access_token}`,
                "Content-Type": "application/json",
                "accept": "application/json"
              },
              "body": JSON.stringify(
                {
                  "kind": "partner_same_day",
                  "scheduled_for": dateTime,
                  "pickups": [
                    {
                      "contact_name": "Dubai Al Makateb",
                      "contact_phone": "+971507558090",
                      "partner_order_id": request_detail_data._id,
                      "location": {
                        "address1": addressauto,
                        "coords": [
                          latLng.lng,
                          latLng.lat
                        ],
                        "country": "UAE",
                        "town": "Dubai",
                        "notes": "Go directly to the address"
                      },
                      "items": [
                        {
                          "name": `Document pickup for ${request_detail_data.service_name} service and AWB number is ${request_detail_data._id} `,
                          "quantity": 1,
                        }
                      ]
                    }
                  ],
                  "dropoffs": [
                    {
                      "contact_name": request_detail_data.user_name,
                      "contact_phone": "+971525557717",
                      "share_tracking": true,
                      "payment_amount": 0,
                      "location": {
                        "address1": request_detail_data.address,
                        "coords": [
                          request_detail_data.lng,
                          request_detail_data.lat
                        ],
                        "country": "UAE",
                        "town": "Dubai"
                      }
                    }
                  ]

                }
              )
            })
              .then(res => res.json())
              .then(async (res) => {
                // console.log(res, '0000')
                if (res.id) {
                  fetch(`https://api.staging.quiqup.com/partner/jobs/${res.id}/submissions`, {
                    "method": "POST",
                    "headers": {
                      "Authorization": `Bearer ${response.access_token}`,
                      "Content-Type": "application/json",
                      "accept": "application/json"
                    },
                    "body": JSON.stringify(
                      {
                        "state": "pending_assignment",
                        "earliest_collection_at": dateTime
                      }
                    )
                  })
                    .then(resp => resp.json())
                    .then(async (resp) => {
                      // console.log(resp, '77887')
                      if (resp.state) {
                        const updateDocData = {
                          service_req_id: this.props.match.params.id,
                          trackingIdDropoff: res.id
                        }
                        const resData = await this.props.UpdatetrackingId(updateDocData);
                        if (resData.value.status === 200) {
                          const servicereq_id = this.props.match.params.id;
                          this.getRequestDetails(servicereq_id);
                          toast.success("successfully scheduled", {
                            position: toast.POSITION.TOP_CENTER
                          });
                          this.setState({ loadingsubmit: false });
                        } else if (resData.value.status === 401) {
                          localStorage.clear();
                          this.props.history.push('/login');
                          this.setState({ loadingsubmit: false });
                        } else {
                          toast.error(resData.value.msg, {
                            position: toast.POSITION.TOP_CENTER
                          });
                          this.setState({ loadingsubmit: false });
                        }

                      } else {
                        // console.log(resp, 'ttt76876rtr')
                        // message.error(res.response_code + ' please retry');
                        // message.error("Invalid card details please check");
                        this.setState({ loadingsubmit: false });
                        toast.error(resp.error, {
                          position: toast.POSITION.TOP_CENTER
                        });
                        setTimeout(() => {
                          this.setState({disabled: false});
                        }, 3000);
                      }
                    })
                    .catch(error => {
                      message.error(error);
                      this.setState({ loadingsubmit: false });
                    });
                } else {
                  // console.log(res, 'tttrtr')
                  // message.error(res.response_code + ' please retry');
                  // message.error("Invalid card details please check");
                  this.setState({ loadingsubmit: false });
                  toast.error(res.error, {
                    position: toast.POSITION.TOP_CENTER
                  });
                }
              })
              .catch(error => {
                message.error(error);
                this.setState({ loadingsubmit: false });
              });
          } else {
            // message.error(response.error + ' please recheck');
            toast.error(response.error, {
              position: toast.POSITION.TOP_CENTER
            });
            this.setState({ loadingsubmit: false });
          }

        })
        .catch(err => {
          message.error(err);
          this.setState({ loadingsubmit: false });
        });
    } else {
      toast.error("please select date and time and address", {
        position: toast.POSITION.TOP_CENTER
      });
      this.setState({ loadingsubmit: false });
    }
  }


  handleSelectauto = addressauto => {
    geocodeByAddress(addressauto)
      .then(results => getLatLng(results[0]))
      .then(latLng => this.setState({ addressauto, latLng: latLng }))
      .catch(error => console.error('Error', error));
  };

  trackURL = (trackID, type) => {
    // console.log(trackID, 'lll');
    if (type === "dropoff") {
      this.setState({ loadingdrop: true, });
    } else {
      this.setState({ loadingpick: true });
    }
    fetch("https://api.staging.quiqup.com/oauth/token", {
      "method": "POST",
      "headers": {
        "Content-Type": "application/json",
        "accept": "application/json"
      },
      "body": JSON.stringify({
        "grant_type": "client_credentials",
        "client_id": "7d267325aee321c82db3dd3fbbe3fd5949ef22975c8f22b8147bcff4d93ab581",
        "client_secret": "695233d31d995e21d9f4838162dffeba63990648c0001d7a5ecc3f157a81c6e8"
      })
    })
      .then(response => response.json())
      .then(response => {
        // console.log(response, 'response')
        if (response.access_token) {
          // console.log(response, scheduled_date);
          fetch(`https://api.staging.quiqup.com/partner/jobs/${trackID}`, {
            "method": "GET",
            "headers": {
              "Authorization": `Bearer ${response.access_token}`,
              "Content-Type": "application/json",
              "accept": "application/json"
            },
          })
            .then(res => res.json())
            .then(async (res) => {
              // console.log(res.orders[0], 'ljhj');

              this.setState({ alltrackDetails: res, trackingUrl: res.orders[0], loadingdrop: false, loadingpick: false, visiblemodal: true, })
            })
            .catch(error => {
              toast.error(error, {
                position: toast.POSITION.TOP_CENTER
              });
              this.setState({ loadingpick: false, loadingdrop: false, });
            });
        } else {
          // message.error(response.error + ' please recheck');
          toast.error(response.error, {
            position: toast.POSITION.TOP_CENTER
          });
          this.setState({ loadingpick: false, loadingdrop: false, });
        }
      })
      .catch(err => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER
        });
        this.setState({ loadingpick: false, loadingdrop: false, });
      });
  }
  disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  }

  range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

//  disabledDateTime() {
//     return {
//       disabledHours: () => range(0, 24).splice(4, 20),
//       disabledMinutes: () => range(30, 60),
//       disabledSeconds: () => [55, 56],
//     };
//   }

  render() {
    const admin_chat_id = window.localStorage.getItem('user_id');
    const { trackingUrl, alltrackDetails, request_detail_data, dynamic_field, documents, Hostname, docData, chat_popup_display_block, chat_history_data, loading } = this.state
    const token = window.localStorage.getItem('Authorization')
    var trackurlpick = (trackingUrl.pickup) ? trackingUrl.pickup.tracking_url : "#"
    var trackurldrop = (trackingUrl.dropoff) ? trackingUrl.dropoff.tracking_url : "#"
    // console.log(request_detail_data, trackurlpick);
    // console.log(docData)
    // console.log(this.state.docIndex)
    const props = {
      name: 'file',
      multiple: false,
      showUploadList: false,
      accept: "application/pdf, image/*",
      headers: { token: `${token}` },
      action: "https://dbcapi.webvilleedemo.xyz/web/document_upload",
      onChange: (info) => this.onChangeModal(info)
    }

    const moreDetailsData = []

    for (const [index, item] of dynamic_field.entries()) {
      if (item.form_type === 'dropdown' || item.form_type === 'checkbox') {
        for (const [index1, item1] of item.options.entries()) {

          if (item1.value === item.value) {
            moreDetailsData.push(
              <tr key={index}>
                <th>{item.label}</th>
                <td>{item1.label}</td>
              </tr>
            )
          }
        }
      } else {
        moreDetailsData.push(
          <tr key={index}>
            <th>{item.label}</th>
            <td>{item.value}</td>
          </tr>)
      }
    }

    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content affiliates_wrapper">
          <div className="breadcrumbwithButton">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/requests">Requests</Link></li>
                <li className="breadcrumb-item"><a href="#">Request Details</a></li>
                <li className="breadcrumb-item active" aria-current="page">{this.props.match.params.id}</li>
              </ol>
            </nav>
            <div className="breadcrumbRight">
              <Link to={{ pathname: '/tracking-details/' + this.props.match.params.id }}><Button type="primary">Tracking Details</Button></Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="card mdu-card">
                <div className="card-header">
                  <h4>Applicant Details</h4>
                </div>
                <div className="card-body">
                  <table className="table">
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <td>{request_detail_data.user_name}</td>
                      </tr>
                      <tr>
                        <th>Email</th>
                        <td>{request_detail_data.user_email}</td>
                      </tr>
                      <tr>
                        <th>Phone</th>
                        <td>{request_detail_data.user_contact}</td>
                      </tr>
                      <tr>
                        <th>Address</th>
                        <td>{request_detail_data.address}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card mdu-card">
                <div className="card-header">
                  <h4>Service Details</h4>
                </div>
                <div className="card-body">
                  <table className="table">
                    <tbody>
                      <tr>
                        <th>Subcategory</th>
                        <td>{request_detail_data.service_type === 'is_new' ? 'New' : request_detail_data.service_type === 'is_renewal' ? 'Renewal' : request_detail_data.service_type === 'is_modify' ? 'Modify' : 'Cancellation'} </td>
                      </tr>
                      <tr>
                        <th>Service Name</th>
                        <td>{request_detail_data.service_name}</td>
                      </tr>
                      <tr>
                        <th>ServiceID</th>
                        <td>{request_detail_data.service_id}</td>
                      </tr>
                      <tr>
                        <th>Status  {request_detail_data.request_status !== 3 ? <Link to={{ pathname: '/tracking-details/' + this.props.match.params.id }}><EditOutlined /></Link> : ''}</th>
                        <td>{request_detail_data.request_status === 0 ? 'Requested' : request_detail_data.request_status === 1 ? 'Document Pickup' : request_detail_data.request_status === 2 ? 'Processing' : 'Delivered'} </td>
                      </tr>
                      <tr>
                        <th>Payment Method</th>
                        <td>{request_detail_data.payment_method}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card mdu-card">
                <div className="card-header">
                  <h4>More Details</h4>
                </div>
                <div className="card-body">
                  <table className="table">
                    <tbody>

                      {moreDetailsData}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card mdu-card">
                <div className="card-header">
                  <h4>Documents Details</h4>
                  <div className="cardHaederRight" onClick={() => this.DownloadZip(request_detail_data._id)}>
                    <DownloadOutlined className="mr-2" />
                    Download
                  </div>
                </div>
                <div className="card-body">
                  <table className="table">
                    <tbody>
                      {documents.length > 0 && documents.map((item, index) =>
                        <tr key={index}>
                          <th>{item.doc_type}</th>
                          <td>
                            <div className="documentDetailTD">
                              <EyeOutlined onClick={() => this.setState({ docData: item, imageModalvisible: true })} /> <span onClick={() => this.showUploadModal(item.doc_type, index)} className="uploadButton"><UploadOutlined /> Upload</span>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card mdu-card">
                <div className="card-header">
                  <h4>Schedule Documents Delivery</h4>
                </div>
                <div className="card-body">
                  {/* <DatePicker onChange={this.onChangeDate} /> */}
                  <div className="row" style={{'margin-bottom': 10}}>
                    <div className="col-md-4">
                      <h6>Date and Time</h6>
                    </div>
                    <div className="col-md-8">
                      <DatePicker
                        format="YYYY-MM-DD HH:mm:ss"
                        // disabledDate={disabledDate}
                        // disabledTime={disabledDateTime}
                        // value={this.state.dateTime}
                        disabledHours={() => [0,1,2,3,4,5,6,7,8, 20,21,22,23,24]}
                        onChange={this.onChangeDate}
                        showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                      /></div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">

                      <h6>Select Pickup address</h6>
                    </div>
                    <div className="col-md-8">
                      <PlacesAutocomplete
                        value={this.state.addressauto}
                        onChange={addressauto => this.setState({ addressauto })}
                        onSelect={this.handleSelectauto}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div>
                            <Input
                              {...getInputProps({
                                placeholder: 'Search Places ...',
                                className: 'location-search-input',
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map(suggestion => {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? { backgroundColor: '#fafafa' }
                                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete></div>
                    <div className="col-md-8">
                      <Button key="submit" type="primary" disabled={this.state.disabled} loading={this.state.loadingsubmit} onClick={this.handlesubmit}>
                        Submit
            </Button></div></div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card mdu-card">
                <div className="card-header">
                  <h4>Track Courier</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      {(!request_detail_data.trackingIdPickup) && (!request_detail_data.trackingIdDropoff) ? 'Data Not Found' : ''}
                      {(request_detail_data.trackingIdPickup) ? <Button onClick={() => this.trackURL(request_detail_data.trackingIdPickup, 'pickup')} loading={this.state.loadingpick}>Click Me For consumer Documents Delivery Track</Button> : ''}
                      <br></br>
                      {(request_detail_data.trackingIdDropoff) ? <Button onClick={() => this.trackURL(request_detail_data.trackingIdDropoff, 'dropoff')} loading={this.state.loadingdrop}>Click Me For Documents Delivery Track</Button> : ''}

                      <Modal
                        title="Tracking Info"
                        visible={this.state.visiblemodal}
                        onOk={() => this.setState({ visiblemodal: false })}
                        onCancel={() => this.setState({ visiblemodal: false })}
                      > 
                          {(alltrackDetails.state === "cancelled") ? " TRACKING ORDER IS CANCELLED " :
                          <div className="row">
                              <div className="col-md-6">
                                <p>ORDER TRACKING PICKUP </p>
                                <a href={trackurlpick} target="_blank">PICKUP URL</a>
                              </div>
                              <div className="col-md-6">
                                <p>ORDER TRACKING DROP OFF </p>
                                <a href={trackurldrop} target="_blank">DROP OFF URL</a>
                              </div></div>
                          }
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="requestChatBox">
            {/* {chat_popup_display_block === true( */}
            <button className={chat_popup_display_block === true ? "open-button hideChatButton" : "open-button"} onClick={() => this.openChatPopup()}>Chat</button>

            <div className={chat_popup_display_block === true ? "chat-popup displayBlock" : "chat-popup"} id="myForm">
              <div className="form-container">
                <div className="chatBoxHeader">
                  <h4>Chat with user</h4>
                  <div className="closeChatBox">
                    <button type="button" className="btn cancel" onClick={() => this.closeChatPopup()}>
                      <CloseIcon />
                    </button>
                  </div>
                </div>

                <Spin spinning={loading}>

                  <div className="showMessageContainer" id="chatList" onScroll={this.handleScrollToElement}>

                    {chat_history_data.length > 0 && chat_history_data.map((item, index) =>
                      <div className={admin_chat_id === item.senderId ? "container" : "container darker"} key={index}>
                        <p>{item.sender_name}</p>
                        {(item.type === 1) ? <div className="message"> {item.message}</div>
                          : (item.type === 2) ? <div className="message"><a href={Hostname + item.message} target='_blank'><img style={{ width: 50, height: 50 }} alt="example" src={Hostname + item.message} /></a> </div> :
                            (item.type === 3) ? <div className="message"> <a href={Hostname + item.message} target='_blank'>Pdf File - Download</a></div> : ''
                        }
                        <span className={admin_chat_id === item.senderId ? "time-right" : "time-left"}>{new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(item.sendOn)}</span>
                      </div>
                    )}

                  </div>

                  <div className="sendMessageField">
                    {/* <input className="form-control" placeholder="Type message.." name="current_message" value={this.state.current_message} */}
                    {/* onChange={this.handleMessageChange.bind(this)}  onKeyDown={this._handleKeyDown} /> */}
                    <div className="sendMessagewithupload clearfix">
                      <textarea className="form-control" placeholder="Type message.." name="current_message" value={this.state.current_message}
                        onChange={this.handleMessageChange.bind(this)} onKeyDown={this._handleKeyDown}></textarea>
                      <div className="customUpload">
                        <Upload accept="application/pdf, image/*"
                          name="file"
                          showUploadList={false}
                          headers={{ token: `${token}` }}
                          action="https://dbcapi.webvilleedemo.xyz/web/document_upload"
                          // onChange={this.handleChange}
                          onChange={(file) => this.onSendFile(file)}
                        ><div className="customUploadContent"><AttachFile /></div>
                        </Upload>

                      </div>
                    </div>
                    <button type="button" className="btn" onClick={this.sendMessage}><SendMessageIcon /></button>
                  </div>



                </Spin>

              </div>
            </div>
          </div>

          <Modal
            title="Image Preview"
            visible={this.state.imageModalvisible}
            onOk={this.handleImageOk}
            onCancel={this.handleImageCancel}>
            <div style={{ overflow: 'hidden' }}>
              <Row gutter={16}>
                {docData.files.map((it, i2) => {
                  return (
                    <Col  key={i2}  span={8}>
                      <Card key={i2} hoverable
                        style={{ "margin-bottom": 10 }}
                        cover={<img style={{ width: 140, height: 140 }} alt="example" src={Hostname + it.value} />}>
                        <Meta title={(it.pic_type) ? it.pic_type : ''} />
                      </Card></Col>
                  );
                })}
              </Row>
            </div>
          </Modal>

          <Modal
            visible={this.state.uploadModalvisible}
            title="Upload your document"
            onOk={this.handleUploadOk}
            onCancel={this.handleUploadCancel}
            footer={[
              <Button key="back" onClick={this.handleUploadCancel}>
                Return
            </Button>,
              <Button key="submit" type="primary" loading={this.state.loading} onClick={this.handleUploadOk}>
                Submit
            </Button>,]}
          >
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <UploadOutlined />
              </p>
              <p className="ant-upload-text">Drag your image or upload manually</p>

            </Dragger>
          </Modal>
        </main>
        <ToastContainer />
      </div>
    );
  }
}


