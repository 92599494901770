import { connect } from 'react-redux'
import CentersComponent from './component'
import { getMe, getAllUnreadMessage } from '../../store/user/duck'
import { fetchAllCenters, createCenter, deleteCenter, updateCenter } from '../../store/center/duck'
const CentersContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase,
  }),
  {
    fetchAllCenters,
    createCenter,
    deleteCenter,
    updateCenter,
    getMe,
    getAllUnreadMessage
  }
  // Map actions to dispatch and props
)(CentersComponent)

export default CentersContainer
