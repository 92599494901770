// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from 'immutable'
import { assign } from 'lodash'
// import Cookies from 'universal-cookie'
import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase'

import { fromPromise } from 'rxjs/observable/fromPromise'
import { of } from 'rxjs'
import { mergeMap, flatMap, catchError } from 'rxjs/operators'
import { ofType, combineEpics } from 'redux-observable'

import * as api from './api'

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

export const GET_ALL_PROMOCODE = 'empire/promocode/GET_ALL_PROMOCODE'

export const ADD_PROMOCODE = 'dbc/promocode/ADD_PROMOCODE'

export const UPDATE_PROMOCODE = 'dbc/promocode/UPDATE_PROMOCODE'

export const GET_PROMOCODE_BYID = 'empire/promocode/GET_PROMOCODE_BYID'

export const DELETE_PROMOCODE = 'dbc/promocode/DELETE_PROMOCODE'


/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  token: null,
  phase: INIT,
  error: null,
  message: null,
  user: null
}

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = '' // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues))
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line complexity, max-statements
 
export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    default: {
      return state
    }
  }
}


/***********************************
 * Action Creators
 ***********/

export const getAllPromoCode = payload => {
  return {
    type: GET_ALL_PROMOCODE,
    payload: api.getAllPromoCode(payload)
  }
}

export const addPromoCode = credentials => {
  return {
    type: ADD_PROMOCODE,
    payload: api.addPromoCode(credentials)
  }
}


export const updatePromoCode = credentials => {
  return {
    type: UPDATE_PROMOCODE,
    payload: api.updatePromoCode(credentials)
  }
}

export const getPromoCodeByid = credentials => {
  return {
    type: GET_PROMOCODE_BYID,
    payload: api.getPromoCodeByid(credentials)
  }
}

export const deletePromocode = credentials => {
  return {
    type: DELETE_PROMOCODE,
    payload: api.deletePromocode(credentials)
  }
}

/***********************************
 * Epics
 ***********************************/


export const promocodeEpic = combineEpics()